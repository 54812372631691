import _ from 'underscore';
import {NEW_PHONE, NULL_PHONE, Phone} from "./phone";

export class EmergencyContact {
    static create(emergencyContact) {
        return new EmergencyContact(emergencyContact);
    }

    constructor(emergencyContact) {
        this.id = emergencyContact.id;
        this.name = emergencyContact.name;
        this.relationship = emergencyContact.relationship;
        //Enforce Phone - create as new if missing to allow for user input
        this.dayPhone = _.isObject(emergencyContact.dayPhone) ?
            Phone.create(emergencyContact.dayPhone) : NEW_PHONE.clone();
        this.nightPhone = _.isObject(emergencyContact.nightPhone) ?
            Phone.create(emergencyContact.nightPhone) : NEW_PHONE.clone();
    }

    cloneTemplate() {
        return {
            id: this.id,
            name: this.name,
            relationship: this.relationship,
            dayPhone: this.dayPhone,
            nightPhone: this.nightPhone
        };
    }

    clone() {
        return EmergencyContact.create(this.cloneTemplate());
    }

    get disableDayPhone() {
        //Day phone is read-only when retrieved from CITF
        return this.dayPhone.id > 0;
    }

    get disableNightPhone() {
        //Night phone is read-only when retrieved from CITF
        return this.nightPhone.id > 0;
    }

    refreshData(updatedEmergencyContact) {
        //Wholesale update for new emergency contact database entry
        if (this.id === 0 && updatedEmergencyContact.id > 0) {
            // console.log('Updating all emergency contact fields...');
            this.id = updatedEmergencyContact.id;
            this.name = updatedEmergencyContact.name;
            this.relationship = updatedEmergencyContact.relationship;
            this.dayPhone = updatedEmergencyContact.dayPhone;
            this.nightPhone = updatedEmergencyContact.nightPhone;
            return true;
        }
        let changesMade = false;
        //Phone updates for new database entries
        if (this.dayPhone.id === 0 && updatedEmergencyContact.dayPhone.id > 0) {
            // console.log('Updating emergency contact day phone...');
            this.dayPhone = updatedEmergencyContact.dayPhone;
            changesMade = true;
        }
        if (this.nightPhone.id === 0 && updatedEmergencyContact.nightPhone.id > 0) {
            // console.log('Updating emergency contact night phone...');
            this.nightPhone = updatedEmergencyContact.nightPhone;
            changesMade = true;
        }
        return changesMade;
    }

    equals(other) {
        if (null === other || !(other instanceof EmergencyContact)) {
            return false;
        }

        if (_.isNaN(this.id) && _.isNaN(other.id)) {
            return true;
        }

        return this.id === other.id;
    }
}

export const NULL_EMERGENCY_CONTACT = EmergencyContact.create({
    id: NaN,
    dayPhone: NULL_PHONE,
    nightPhone: NULL_PHONE
});

export const NEW_EMERGENCY_CONTACT = EmergencyContact.create({
    id: 0,
    dayPhone: NEW_PHONE,
    nightPhone: NEW_PHONE
});