import {trimToNull} from '@/util/formatters';

export function validateRegex(regex, value) {
    return RegExp(regex).test(value);
}

export function validEmailAddress(value) {
    const possible = trimToNull(value);
    return null === possible ? false : validateRegex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, possible);
}

export function validPhoneNumber(value) {
    const possible = trimToNull(value);
    return null === possible? false : validateRegex(/^(\d{3})(\d{3})(\d{4})(\d{0,5})$/, possible);
}
