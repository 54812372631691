import memberDao from "../dao/member_dao";

export const MemberStore = {
    namespaced: true,

    state: {
        classifications: []
    },

    getters: {
        classifications(state) {
            return state.classifications;
        }
    },

    mutations: {
        setClassifications: (state, classifications) => {
            state.classifications = classifications;
        }
    },

    actions: {
        //TODO - deprecate, use common store instead?
        loadClassifications: async (context) => {
            if (context.state.classifications.length < 1) {
                const classifications = await memberDao.getClassifications();
                context.commit('setClassifications', classifications);
            }
        }
    }
};

export default MemberStore;
