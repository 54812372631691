
import {mkDate, date} from '@/util/formatters';
import _ from 'underscore';


export class Comment {

    static create(comment) {
        return new Comment(comment);
    }

    id = null;
    recordId = null;
    comment = null;
    updatedBy = null;
    lastUpdated = null;

    constructor(comment) {
        this.copyFrom(comment);
    }

    get fields() {
        return [
            'id',
            'recordId',
            'comment',
            'updatedBy',
            'lastUpdated'
        ];
    }

    cloneTemplate() {
        return {
            id: this.id,
            recordId: this.recordId,
            comment: this.comment,
            lastUpdated: null === this.lastUpdated ? null : date(this.lastUpdated, 'yyyy-MM-dd HH:mm:ss')
        };
    }

    clone() {
        return Comment.create(this.cloneTemplate());
    }

    copyFrom(comment) {
        _.each(this.fields, (field) => {
            switch(field) {
                case 'lastUpdated':
                    this[field] = _.has(comment, field) ? mkDate(comment[field]) : null;
                    break;

                default:
                    this[field] = comment[field];
            }
        });
    }
}

export const NULL_COMMENT = Comment.create({id: NaN});
export default Comment;