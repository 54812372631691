import store from '@/store/store';
import _ from 'underscore';
import activityDao from '@/dao/activity_dao';
import {CustomActivityType} from "../model/activity";

export const ActivityStore = {
    namespaced: true,

    state: {
        currentActivityGeneration: 2,
        activityGenerations: [{
            value: 1,
            text: 'Legacy'
        }, {
            value: 2,
            text: 'Current'
        }],
        ojts: [],
        customOjts: [],
        ojtCategories: [],
        ojtCategoryTable: {},
        mas: [],
        customMas: [],
        //Object "map" to cache trainee scheduled activities, complicated by the fact that JS map keys must be strings
        //TODO - this is deprecated from the API call, deprecate from structure
        //Level 1 - key = String(trainee.userProfileId), value = String(trainee.mentorId)
        //Level 2 - key = String(trainee.mentorId), value = Activity[]
        traineeActivitySchedule: {}
    },

    getters: {
        currentActivityGeneration(state) {
            return state.currentActivityGeneration;
        },

        activityGenerations(state) {
            return state.activityGenerations;
        },

        getOJTCategories(state) {
            return state.ojtCategories;
        },

        getOJTs(state) {
            return state.ojts;
        },

        getCustomOJTs(state) {
            return state.customOjts;
        },

        getOJTsForCategory: (state) => (category) => {
            if (category.id === -1) {
                //Accept category ID -1 as "all," filter by generation
                return _.filter(state.ojts, ojt => {
                    return _.isEqual(ojt.generation, category.generation);
                });
            }
            else {
                return _.filter(state.ojts, (ojt) => {
                    //Filter by ID only (unique across generations)
                    return _.isEqual(ojt.categoryId, category.id);
                });
            }
        },

        getOJTCategoryById: (state) => (id) => {
            return state.ojtCategoryTable[id];
        },

        getMAs(state) {
            return state.mas;
        },

        getCustomMAs(state) {
            return state.customMas;
        },

        traineeActivitySchedule: (state) => (trainee) => {
            const traineeId = String(trainee.id);
            const mentorId = String(trainee.mentorId);
            if (_.has(state.traineeActivitySchedule, traineeId) &&
                _.has(state.traineeActivitySchedule[traineeId], mentorId)) {
                return (state.traineeActivitySchedule[traineeId])[mentorId];
            }
            else {
                return [];
            }
        }
    },

    mutations: {
        setOJTCategories: (state, categories) => {
            state.ojtCategories = categories;
            state.ojtCategoryTable = {};
            categories.forEach((c) => {
                if (null !== c.id) {
                    state.ojtCategoryTable[c.id] = c;
                }
            });
        },

        setOJTs: (state, ojts) => {
            ojts.forEach((o) => {
                if (null !== o.categoryId) {
                    o.category = state.ojtCategoryTable[o.categoryId];
                }
            });
            state.ojts = ojts;
        },

        setCustomOJTs: (state, customOjts) => {
            state.customOjts = customOjts;
        },

        addOJT: (state, ojt) => {
            state.ojts.push(ojt);
        },

        setMAs: (state, mas) => {
            state.mas = mas;
        },

        setCustomMAs: (state, customMas) => {
            state.customMas = customMas;
        },

        addMA: (state, ma) => {
            state.mas.push(ma);
        },

        setTraineeActivitySchedule: (state, result) => {
            const traineeId = result.trainee;
            const mentorId = result.mentor;
            const activitySchedule = result.schedule;
            if (_.has(state.traineeActivitySchedule, traineeId)) {
                //Has trainee
                const traineeSchedule = state.traineeActivitySchedule[traineeId];
                if (_.has(state.traineeSchedule, mentorId)) {
                    //Has mentor - delete
                    delete state.traineeSchedule[mentorId];
                }
                //Add entry for mentor
                traineeSchedule[mentorId] = activitySchedule;
            }
            else {
                //Doesn't have trainee
                const mentorSchedule = {[mentorId]: activitySchedule};
                state.traineeActivitySchedule[traineeId] = mentorSchedule;
            }
        },

        setCustomActivity: (state, activity) => {
            //Clear stale data
            if (_.isEqual(activity.type, CustomActivityType.OJT)) {
                state.customOjts = [];
            }
            else if (_.isEqual(activity.type, CustomActivityType.MA)) {
                state.customMas = [];
            }
            else {
                //Catch all
                state.customOjts = [];
                state.customMas = [];
            }
        },

        setTraineeActivity: (state, traineeActivity) => {
            const traineeId = String(traineeActivity.traineeId);
            const mentorId = String(traineeActivity.mentorId);
            //Clear stale data from store
            if (_.has(state.traineeActivitySchedule, traineeId)) {
                //Has trainee
                const traineeSchedule = state.traineeActivitySchedule[traineeId];
                if (_.has(state.traineeSchedule, mentorId)) {
                    //Has mentor - delete
                    delete state.traineeSchedule[mentorId];
                }
            }
        }
    },

    actions: {
        loadOJTCategories: async (context) => {
            if (context.state.ojtCategories.length < 1) {
                const categories = await activityDao.getOJTCategories();
                context.commit('setOJTCategories', categories);
            }
        },

        loadOJTs: async ({commit}) => {
            const ojts = await activityDao.getOJTs();
            commit('setOJTs', ojts);
        },

        loadMAs: async ({commit}) => {
            const mas = await activityDao.getMAs();
            commit('setMAs', mas);
        },

        loadCustomOJTs: async ({commit}) => {
            const customOjts = await activityDao.getCustomOJTs();
            commit('setCustomOJTs', customOjts);
        },

        loadCustomMAs: async ({commit}) => {
            const customMas = await activityDao.getCustomMAs();
            commit('setCustomMAs', customMas);
        },

        loadTraineeActivitySchedule: async ({commit}, trainee) => {
            const traineeId = trainee.id;
            const mentorId = trainee.mentorId;
            const result = await activityDao.getTraineeScheduledActivities(traineeId);
            commit('setTraineeActivitySchedule', {
                trainee: String(traineeId),
                mentor: String(mentorId),
                schedule: result
            });
        },

        processCustomActivity: async ({commit}, activity) => {
            const result = await activityDao.processCustomActivity(activity);
            commit('setCustomActivity', result);
        },

        processTraineeActivity: async ({commit}, traineeActivity) => {
            const result = await activityDao.processTraineeActivity(traineeActivity);
            commit('setTraineeActivity', result);
        },

        copyTraineeSchedule: async ({commit}, request) => {
            const success = await activityDao.copyTraineeSchedule(request.fromTraineeId, request.toTraineeId,
                request.includeDates);
            // console.log(success);
        }
    }
};

export default ActivityStore;