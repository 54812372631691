import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import {NULL_USER, UserFactory} from '@/model/user';
import {UserStatus} from '@/model/security_level';
import _ from 'underscore';
import {USERNAME_REGEX} from "@/model/member";

class UserDao extends Dao {
    //Determine whether username is valid (i.e. not already in use in SCT.user_profile)
    async validUsername(username) {
        if (!username || !USERNAME_REGEX.test(username) || username.length > 255) {
            return false;
        }
        try {
            const response = await this.api.get(sprintf('username/%s?rev=%s', username, this.getRev()));
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error validating username: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Determine whether user is an enabled trainee with an active mentoring relationship
    async activeTrainee(userProfileId) {
        try {
            const response = await this.api.get(sprintf('trainee/active/%s?rev=%s', userProfileId, this.getRev()));
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading user status: %s', err.data.message);
            throw (new Error(message));
        }
    }

    // TODO: Make this in one call
    async getUsersByIds(ids) {
        const users = [];
        for (const id of ids) {
            //Call alerts, returns NULL_USER on error
            const u = await this.getUserById(id);
            if (!_.isEqual(u, NULL_USER)) {
                users.push(u);
            }
        }
        return users;
    }

    async getUserById(id) {
        if (!id) {
            return NULL_USER;
        }
        try {
            const url = sprintf('users/%d?rev=%d', id, this.getRev());
            const response = await this.api.get(url);
            return UserFactory.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading user: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getPastTraineeDetailsForMentor(id) {
        if (!id) {
            return null;
        }
        try {
            const url = sprintf('trainee/former/%d?rev=%d', id, this.getRev());
            const response = await this.api.get(url);
            const trainee = _.isNull(response.data) ? null : UserFactory.create(response.data);
            return trainee;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading details: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async search(criteria) {
        try {
            const response = await this.api.post('users/search', criteria);
            return {
                limited: response.data.limited,
                users: _.map(response.data.users, UserFactory.create)
            };
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading search results: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async save(user) {
        try {
            user.serialize();
            const response = await this.api.post('users', user);
            return UserFactory.create(response.data);
        }
        catch (err) {
            console.error(err);
            throw new Error(err.data.message);
        }
    }

    async getTraineesForMentor(mentorId) {
        try {
            const response = await this.api.get(sprintf('/trainees/%d?rev=%s', mentorId, this.getRev()));
            return _.map(response.data, UserFactory.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading trainees for mentor: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getAvailableTrainees() {
        try {
            const url = sprintf('/user/trainee/available?rev=%d', this.getRev());
            const response = await this.api.get(url);
            return _.map(response.data, UserFactory.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading available trainees: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getUserStatuses() {
        try {
            const response = await this.api.get(sprintf('/user/statuses?rev=%d', this.getRev()));
            return _.map(response.data, UserStatus.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading user statuses: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getTraineeStatuses() {
        try {
            const response = await this.api.get(sprintf('/trainee/statuses?rev=%d', this.getRev()));
            return _.map(response.data, UserStatus.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading trainee statuses: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getMentors() {
        try {
            const response = await this.api.get(sprintf('/user/mentors?rev=%d', this.getRev()));
            return _.map(response.data, UserFactory.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading mentors: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getAdmins() {
        try {
            const response = await this.api.get(sprintf('/user/admins?rev=%d', this.getRev()));
            return _.map(response.data, UserFactory.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading admins: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async savePhoto(userId, photo) {
        try {
            const url = sprintf('/user/photo/%d', userId);
            await this.api.post(url, photo);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading processing photo: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getTraineeId(userProfileId) {
        try {
            const url = sprintf('user/trainee/id/%d?rev=%d', userProfileId, this.getRev());
            const response = await this.api.get(url);
            if (!response.data) {
                throw new Error('No trainee ID found for user profile ID ' + userProfileId);
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading trainee ID: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getMentorId(userProfileId) {
        try {
            const url = sprintf('user/mentor/id/%d?rev=%d', userProfileId, this.getRev());
            const response = await this.api.get(url);
            if (!response.data) {
                throw new Error('No mentor ID found for user profile ID ' + userProfileId);
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading mentor ID: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async isTraineeBehind(traineeId) {
        //TODO - this has been deprecated per user request, but leaving the plumming just in case
        return false;
        // try {
        //     const url = sprintf('activity/trainee/behind/%d?rev=%d', traineeId, this.getRev());
        //     const response = await this.api.get(url);
        //     return response.data;
        // }
        // catch (err) {
        //     console.error(err);
        //     const message = sprintf('Error loading trainee activity status: %s', err.data.message);
        //     throw (new Error(message));
        // }
    }
}

const userDao = new UserDao();
export default userDao;