<template>
    <b-collapse is-nav id="nav_collapse">
        <PrivateMenu v-if="isLoggedIn"/>
        <PublicMenu v-else/>
        <LoginMenu :isLoggedIn="isLoggedIn"/>
    </b-collapse>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import PublicMenu from '@/views/menu/PublicMenu.vue';
import PrivateMenu from '@/views/menu/PrivateMenu.vue';
import LoginMenu from '@/views/menu/LoginMenu.vue';
@Component({
    components: {
        PublicMenu,
        PrivateMenu,
        LoginMenu
    }
})
export default class Menu extends Vue {

    get isLoggedIn() {
        return this.$store.getters['userSession/isLoggedIn'];
    }
}
</script>
<style scoped>

</style>
