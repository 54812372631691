import {trimToNull} from '@/util/formatters';
import _ from 'underscore';
import {NEW_PHONE, NULL_PHONE, Phone} from "./phone";
import {EmergencyContact, NEW_EMERGENCY_CONTACT, NULL_EMERGENCY_CONTACT} from "./emergency_contact";
import {SecurityLevel, UserStatus, ENABLED, DISABLED, NULL_USER_STATUS} from "./security_level";
import {sprintf} from "sprintf-js";
import formatters from "../util/formatters";

export const USERNAME_REGEX = /^[a-z0-9]+$/i;
export const GOOD_STANDING_STATUSES = ['ARREARS', 'ARREARS ASSESS', 'ARREARS WORKING DUES', 'GOOD STANDING'];

export class Member {
    get fields() {
        return [
            //SCT info
            'userProfileId', 'userRole', 'userStatus', 'username',
            //CITF info
            'personId', 'title', 'email', 'phone', 'emergencyContact', 'contractorName',
            //Train info
            'ubcId', 'local', 'classification', 'unionStatus', 'firstName', 'middleName', 'lastName', 'suffix',
            'address1', 'address2', 'city', 'state', 'zip', 'country', 'tvcHash', 'oshaClassScheduleId',
            //Flag fields and add-ons
            'oshaPrerequisiteMet', 'newEmail', 'newTitle', 'newEntry', 'roleStatus', 'ssnOnFile', 'birthdateOnFile',
            'vaccineVerified', 'vaccineVerifiedDate', 'vaccineVerifiedByUsername', 'vaccinePrerequisiteMet',
            //Transient fields (not included in Java model)
            'validUsername', 'activeTrainee'
        ];
    }

    static create(member) {
        return new Member(member);
    }

    constructor(member) {
        this.copyFrom(member);
    }

    copyFrom(member) {
        //Copy fields
        _.each(this.fields, (key) => {
            switch (key) {
                case 'userRole':
                    this[key] = SecurityLevel.from(member[key]);
                    break;
                case 'userStatus':
                    this[key] = _.isObject(member[key]) ?
                        UserStatus.create(member[key]) : NULL_USER_STATUS.clone();
                    break;
                case 'phone':
                    this[key] = _.isObject(member[key]) ?
                        Phone.create(member[key]) : NULL_PHONE.clone();
                    break;
                case 'emergencyContact':
                    this[key] = _.isObject(member[key]) ?
                        EmergencyContact.create(member[key]) : NULL_EMERGENCY_CONTACT.clone();
                    break;
                default:
                    this[key] = member[key];
                    break;
            }
        });
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            if (_.isString(template[key])) {
                template[key] = trimToNull(template[key]);
            }
            return template;
        }, {});
    }

    clone() {
        return Member.create(this.cloneTemplate());
    }

    serialize() {
        this.ubcId = formatters.serializeAsUbcId(this.ubcId);
    }

    get canBeVerified() {
        return this.ssnOnFile || this.birthdateOnFile;
    }

    //Check full name when looking for member equality
    get fullNameForEquality() {
        //Excluding suffix since we don't ask for mentor suffix
        const fullName = sprintf('%s %s %s',
            (_.isEmpty(this.firstName) ? '' : this.firstName),
            (_.isEmpty(this.middleName) ? '' : this.middleName),
            (_.isEmpty(this.lastName) ? '' : this.lastName))
            .replace(/  +/g, ' ').trim();
        return fullName;
    }

    //Format and return member's full name
    get fullName() {
        const fullName = sprintf('%s %s %s',
            (_.isEmpty(this.firstName) ? '' : this.firstName),
            (_.isEmpty(this.middleName) ? '' : this.middleName),
            (_.isEmpty(this.lastName) ? '' :
                (_.isEmpty(this.suffix) ? this.lastName : sprintf('%s %s', this.lastName, this.suffix))))
            .replace(/  +/g, ' ')
            .trim();
        return fullName;
    }

    //Format and return mentor name and UBC ID if available
    get mentorLabel() {
        const fullName = sprintf('%s, %s %s %s %s',
            (_.isEmpty(this.lastName) ? '' :
                (_.isEmpty(this.suffix) ? this.lastName : sprintf('%s %s', this.lastName, this.suffix))),
            (_.isEmpty(this.firstName) ? '' : this.firstName),
            (_.isEmpty(this.middleName) ? '' : this.middleName),
            (_.isEmpty(this.ubcId) ? '(Not a UBC member)' : sprintf('(UBC ID: %s)', this.formattedUbcId)),
            (sprintf('(Role: %s at %s)',
            (_.isEmpty(this.title) ? 'Unknown' : this.title),
            (_.isEmpty(this.contractorName) ? 'Unknown' : this.contractorName))))
            .replace(/  +/g, ' ').trim();
        return fullName;
    }

    get formattedUbcId() {
        if (_.isEmpty(this.ubcId)) {
            return 'Invalid UBC ID';
        }
        return sprintf('U-%s-%s', this.ubcId.substring(1,5), this.ubcId.substring(5,9));
    }

    //Determine whether a member is in good standing
    get inGoodStanding() {
        if (_.isNull(this.unionStatus)) {
            return false;
        }
        return GOOD_STANDING_STATUSES.includes(this.unionStatus.toUpperCase());
    }

    get isATrainee() {
        return _.isEqual(this.userRole, SecurityLevel.TRAINEE);
    }

    get isAMentor() {
        return _.isEqual(this.userRole, SecurityLevel.MENTOR);
    }

    get isEnabled() {
        return _.isEqual(this.userStatus, ENABLED);
    }

    get isDisabled() {
        return _.isEqual(this.userStatus, DISABLED);
    }

    //Determine whether a member is new to SCT
    get isNew() {
        return !(this.userProfileId > 0);
    }

    //Determine whether a member is new (as a mentor) to SCT
    get isNewMentor() {
        //Use case: mentor not yet selected OR looked up and has a user profile ID, but isn't a mentor in SCT
        if (_.isNaN(this.userProfileId) || (!this.isNew && this.isAMentor)) {
            return false;
        }
        else {
            return true;
        }
    }

    //Mentor fields are read-only when mentor is retrieved from SCT/CITF
    get disableMentorFields() {
        return !this.newEntry;
    }

    //Username is read-only for existing SCT users
    get disableUsername() {
        return !this.isNew;
    }

    //Title is read-only when retrieved from CITF
    get disableTitle() {
        return !this.newTitle;
    }

    //Email is read-only when retrieved from CITF
    get disableEmail() {
        return !this.newEmail;
    }

    //Phone is read-only when retrieved from CITF
    get disablePhone() {
        return this.phone.id > 0;
    }

    //Emergency contact is read-only when retrieved from CITF (see implementation for phone disabling)
    get disableEmergencyContact() {
        return this.emergencyContact.id > 0;
    }

    //Use case: look for a match between Members
    isSamePerson(otherPerson) {
        //Check UBC ID for match
        if (!_.isNull(this.ubcId) && _.isEqual(this.ubcId, otherPerson.ubcId)) {
            return true;
        }
        // Check full name for match
        if (!_.isNull(this.fullNameForEquality) &&
            _.isEqual(this.fullNameForEquality.toLowerCase(), otherPerson.fullNameForEquality.toLowerCase())) {
            return true;
        }
        //No match
        return false;
    }

    //Use case: ensure mentor (Member) doesn't match contractor contact (free text only)
    matchesContact(contractor) {
        if (!contractor || !contractor.contacts || !contractor.contacts[0]) {
            return false;
        }
        const contact = contractor.contacts[0];
        return _.isEqual(this.firstName, contact.firstName) && _.isEqual(this.lastName, contact.lastName);
    }

    //Use case: member data may change between application and enrollment - check and update fields as necessary
    refreshData(updatedMember) {
        if (_.isEqual(updatedMember, NULL_MEMBER)) {
            console.log('Something went wrong, no updated member data retrieved');
            return false;
        }
        let changesMade = false;
        //Apply updates to non-user input data
        const updateFields = ['userProfileId', 'personId', 'userRole', 'userStatus', 'username', 'local',
            'classification', 'unionStatus', 'firstName', 'middleName', 'lastName', 'suffix', 'address1', 'address2',
            'city', 'state', 'zip', 'country', 'tvcHash', 'oshaClassScheduleId', 'contractorName', 'vaccineVerified',
            'vaccineVerifiedDate', 'vaccineVerifiedByUsername'];
        for (let field of updateFields) {
            if (!_.isEqual(this[field], updatedMember[field]) && !!updatedMember[field]) {
                // console.log(sprintf('Updating Member.%s...', field));
                this[field] = updatedMember[field];
                changesMade = true;
            }
        }
        //Apply updated title if available
        if (this.newTitle && !!updatedMember.title) {
            // console.log('Updating Member.title...');
            this.title = updatedMember.title;
            this.newTitle = false;
            changesMade = true;
        }
        //Apply updated email if available
        if (this.newEmail && !!updatedMember.email) {
            // console.log('Updating Member.email...');
            this.email = updatedMember.email;
            this.newEmail = false;
            changesMade = true;
        }
        //Apply updated phone if available
        if (this.phone.id === 0 && updatedMember.phone.id > 0) {
            // console.log('Updating Member.phone...');
            this.phone = updatedMember.phone;
            changesMade = true;
        }
        //Let emergency contact apply its own updates
        if (this.emergencyContact.refreshData(updatedMember.emergencyContact)) {
            changesMade = true;
        }
        //Reevaluate flags as necessary
        this.oshaPrerequisiteMet = this.oshaPrerequisiteMet || !!this.oshaClassScheduleId;
        this.vaccinePrerequisiteMet = this.vaccinePrerequisiteMet || this.vaccineVerified;
        if (!this.isNew && !_.isEmpty(this.username)) {
            this.validUsername = true;
        }
        else {
            this.proposeUsername();
            this.validUsername = false;
        }
        return changesMade;
    }

    //Use case: same as above, but only update select fields for username lookup (no UBC ID)
    refreshPartialData(updatedMember) {
        if (_.isEqual(updatedMember, NULL_MEMBER)) {
            console.log('Something went wrong, no updated member data retrieved');
            return false;
        }
        let changesMade = false;
        //Apply updates to non-user input data
        const updateFields = ['userProfileId', 'personId', 'userRole', 'userStatus', 'username'];
        for (let field of updateFields) {
            if (!_.isEqual(this[field], updatedMember[field]) && !!updatedMember[field]) {
                console.log(sprintf('Updating Member.%s...', field));
                this[field] = updatedMember[field];
                changesMade = true;
            }
        }
        //Apply updated email if available
        if (this.newEmail && !!updatedMember.email) {
            console.log('Updating Member.email...');
            this.email = updatedMember.email;
            this.newEmail = false;
            changesMade = true;
        }
        //Apply updated phone if available
        if (this.phone.id === 0 && updatedMember.phone.id > 0) {
            console.log('Updating Member.phone...');
            this.phone = updatedMember.phone;
            changesMade = true;
        }
        //Let emergency contact apply its own updates
        if (this.emergencyContact.refreshData(updatedMember.emergencyContact)) {
            changesMade = true;
        }
        if (!this.isNew && !_.isEmpty(this.username)) {
            this.validUsername = true;
        }
        else {
            this.proposeUsername();
            this.validUsername = false;
        }
        return changesMade;
    }

    //Use case: propose a nickname
    proposeUsername() {
        if (_.isEmpty(this.username) || _.isNull(this.username)) {
            this.validUsername = false;
            this.username = sprintf('%s%s', this.firstName.substring(0,1), this.lastName)
                .replace(/[^a-zA-Z0-9]*/g, "")
                .toLowerCase();
        }
    }

    //Use case: embed fields as needed for backend-SP enrollment integration
    enroll() {
        // console.log('Preparing member for enrollment...');
        this.newEntry = this.isNew;
        this.userStatus = ENABLED;
        // console.log(this);
    }

    equals(other) {
        return other instanceof Member && _.isEqual(other.ubcId, this.ubcId);
    }
}

export const NULL_MEMBER = Member.create({
    userProfileId: NaN,
    personId: NaN,
    userRole: SecurityLevel.INVALID,
    roleStatus: null,
    phone: NULL_PHONE.clone(),
    emergencyContact: NULL_EMERGENCY_CONTACT.clone(),
    oshaPrerequisiteMet: false,
    vaccinePrerequisiteMet: false,
    newEmail: true,
    newTitle: true,
    newEntry: true
});

export const NEW_MENTOR = Member.create({
    userProfileId: 0,
    personId: 0,
    userRole: SecurityLevel.MENTOR,
    roleStatus: null,
    phone: NEW_PHONE.clone(),
    emergencyContact: NEW_EMERGENCY_CONTACT.clone(),
    oshaPrerequisiteMet: false,
    vaccinePrerequisiteMet: false,
    newEmail: true,
    newTitle: true,
    newEntry: true
});