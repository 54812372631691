
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBook,
    faUsers,
    faEnvelope,
    faUser,
    faSearch,
    faHammer,
    faWalking,
    faArrowLeft,
    faArrowRight,
    faPlane,
    faChartLine,
    faHardHat,
    faTimes,
    faClipboardList,
    faChalkboardTeacher,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faCogs,
    faPeopleCarry,
    faFileDownload,
    faSlidersH,
    faInfoCircle,
    faExclamationCircle,
    faBell
} from '@fortawesome/free-solid-svg-icons';


import {
    faCalendarAlt,
    faCalendar,
    faQuestionCircle,
    faAddressCard,
    faCalendarCheck,
    faEye,
    faEyeSlash,
    faPlayCircle,
    faCommentDots
} from '@fortawesome/free-regular-svg-icons';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const icons = [
    faUsers,
    faEnvelope,
    faSearch,
    faBook,
    faUser,
    faCalendar,
    faCalendarAlt,
    faHammer,
    faWalking,
    faQuestionCircle,
    faArrowLeft,
    faArrowRight,
    faPlane,
    faChartLine,
    faCalendarCheck,
    faAddressCard,
    faHardHat,
    faTimes,
    faClipboardList,
    faEye,
    faEyeSlash,
    faChalkboardTeacher,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faCogs,
    faPeopleCarry,
    faFileDownload,
    faSlidersH,
    faPlayCircle,
    faInfoCircle,
    faCommentDots,
    faExclamationCircle,
    faBell
];

library.add.apply(library, icons);

export default FontAwesomeIcon;
