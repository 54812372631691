import applicationDao from "@/dao/application_dao";
import _ from "underscore";
import store from "./store";
import {NULL_SESSION} from "@/model/session";
import {NULL_ORGANIZATION} from "../model/organization";

export const ApplicationStore = {
    namespaced: true,

    state: {
        applicationStatuses: [],
        applications: []
    },

    getters: {
        getApplicationStatuses(state) {
            return state.applicationStatuses;
        },
        getApplications(state) {
            return state.applications;
        },
        maxId(state) {
            return _.max(state.applications, (a) => a.id);
        },
        getEnrolledStatus(state) {
            return _.find(state.applicationStatuses, (status) => {
                return status.description.toLowerCase() == 'approved'
            });
        },
        getAppliedCompleteStatus(state) {
            return _.find(state.applicationStatuses, (status) => {
                return status.description.toLowerCase() == 'complete'
            });
        },
        getAppliedIncompleteStatus(state) {
            return _.find(state.applicationStatuses, (status) => {
                return status.description.toLowerCase() == 'incomplete'
            });
        },
        getDeclinedLocalStatus(state) {
            return _.find(state.applicationStatuses, (status) => {
                return status.description.toLowerCase().includes('not in good standing')
            });
        },
        getDeclinedOtherStatus(state) {
            return _.find(state.applicationStatuses, (status) => {
                return status.description.toLowerCase() == 'other'
            });
        },
    },

    mutations: {
        setApplicationStatuses: (state, applicationStatuses) => {
            state.applicationStatuses = applicationStatuses;
        },
        setApplications: (state, applications) => {
            state.applications = applications;
        },
        setApplication: (state, application) => {
            //If there's not valid ID, go nuclear (SP doesn't return ID)
            if (_.isNull(application.id) || _.isNaN(application.id) || 0 === application.id) {
                state.applications = [];
                store.dispatch('applications/loadApplications');
            }
            //Find index
            const index = _.findIndex(state.applications, (app) => {
                return application.id === app.id;
            });
            //Remove if session already exists
            if (index >= 0) {
                state.applications.splice(index, 1);
            }
            state.applications.push(application);
        }
    },

    actions: {
        loadApplicationStatuses: async (context) => {
            if (context.state.applicationStatuses.length < 1) {
                const applicationStatuses = await applicationDao.getApplicationStatuses();
                context.commit('setApplicationStatuses', applicationStatuses);
            }
        },

        loadApplications: async (context) => {
            //Prerequisite - load sessions for matching with applications
            await context.dispatch('tracks/loadTracks', null, {root: true});
            const sessions = context.rootGetters['tracks/getProgram1sNoYear'];

            //Prerequisite - load contractors for matching with applications
            await context.dispatch('organizations/loadContractors', null, {root: true});
            const contractors = context.rootGetters['organizations/getContractors'];

            //Load applications and match with corresponding sessions
            const applications = await applicationDao.getApplications();

            //TODO - splicing in all session details for label display, maybe use a getter for Vue instead
            const applicationsWithSessions = _.chain(applications)
                .map(application => {
                    //Find session or default to null placeholder (i.e. session no longer valid)
                    const applicationSession = _.find(sessions, session => {
                        return session.sessionId === application.session.sessionId;
                    }) || NULL_SESSION;
                    //Assign to application and workspace as applicable
                    application.session = applicationSession;
                    if (!!application.workspace) {
                        application.workspace.session = applicationSession;
                    }
                    //Check for contractor ID
                    //First, reverse compatibility with contractor cleanup
                    if (_.isEmpty(application.contractor)) {
                        application.contractor = NULL_ORGANIZATION;
                    }
                    if (application.contractor.id > 0) {
                        //Find contractor
                        const applicationContractor = _.find(contractors, contractor => {
                            return contractor.id === application.contractor.id;
                        });
                        //Clear old data if not found, assign if found
                        if (_.isUndefined(applicationContractor)) {
                            application.resetContractor();
                        }
                        else {
                            application.contractor = applicationContractor;
                            if (!!application.workspace) {
                                application.workspace.contractor = applicationContractor;
                            }
                        }
                    }
                    else {
                        application.resetContractor();
                    }
                    return application;
                })
                .value();
            context.commit('setApplications', applicationsWithSessions);
        },

        //Step 1: Submit original application
        submitApplication: async ({commit}, application) => {
            application.updateStatusDate();
            application.serialize();
            // console.log(application);
            const savedApplication = await applicationDao.submitApplication(application);
            // console.log(savedApplication);
        },

        //Step 1a. Optional - edit only non-enrolled, non-declined application
        saveApplication: async ({commit}, application) => {
            //Pre-save data massaging
            application.interimSave();
            application.serialize();
            // console.log(application);
            const savedApplication = await applicationDao.saveApplication(application.workspace);
            // console.log(savedApplication);
            application.commit();
            commit('setApplication', savedApplication);
        },

        //Step 2. Enroll/decline reviewed application
        updateApplication: async ({commit}, application) => {
            //Pre-enrollment data massaging
            if (application.workspace.status.isEnrolled) {
                application.workspace.enroll();
            }
            application.updateStatusDate();
            application.serialize();
            // console.log(application);
            //Save and commit application workspace to capture reviewer changes
            await applicationDao.updateApplication(application.workspace);
            //Requery for application to retrieve and display details per db
            if (!!application.id) {
                const savedApplication = await applicationDao.getApplication(application.id);
                application.workspace = savedApplication;
                application.commit();
                commit('setApplication', application);
            }
        }
    }
};

export default ApplicationStore;