import Dao from '@/dao/dao';
import {Track, Program} from '@/model/track';
import {Session} from '@/model/session';
import {KeyValue} from '@/model/common';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';

class TrackDao extends Dao {
    async getTracks() {
        try {
            const url = sprintf('track?rev=%d', this.getRev());
            const response = await this.api.get(url);
            const tracks = _.map(response.data, track => {
                return Track.create(track);
            });
            return tracks;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading tracks: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getTrackById(id) {
        try {
            const url = sprintf('track/%d?ref=%d', id, this.getRev());
            const response = await this.api.get(url);
            return Track.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading track: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async saveTrack(track) {
        try {
            //Serialize times to prevent implicit local-to-UTC conversion OTW to BE
            track.serialize();
            const response = await this.api.post('track', track);
            return Track.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing track: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async deleteTrack(track) {
        try {
            const response = await this.api.post(sprintf('track/delete/%d', track.id));
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error deleting track: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async cancelTrack(track) {
        try {
            const response = await this.api.post(sprintf('track/cancel/%d', track.id));
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error cancelling track: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getPrograms() {
        try {
            const url = sprintf('programs?rev=%d', this.getRev());
            const response = await this.api.get(url);
            return _.map(response.data, Program.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading programs: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getSessionTemplate(idx, mentorSession) {
        try {
            const url = sprintf('session/template/%s/%s?rev=%d', idx, mentorSession, this.getRev());
            const response = await this.api.get(url);
            const template = Session.create(response.data);
            return template;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading session template: %s', err.data.message);
            throw (new Error(message));
        }
    }

    /**
     * This may end up a tad wonky but hopefully these docs are clear! :)
     *
     * @param desiredId  :  'session'   ->  sct.program_session.session_id
     *                      'workshop'  ->  citfreg.workshop_instance.workshop_instance_id
     * @param showSeats  :  'true' or 'false'
     * @param after      :  YYYY-MM-DD -> returns sessions with a start date later than this date
     * @param sort       : 'asc' or 'desc'
     * @returns
     *   showSeats=false -> [{"id": 123, "value": "SCT Program 1 (01/01/2020 - 01/03/2020)" }, { ... }]
     *   showSeats=true  -> [{"id": 123, "value": "SCT Program 1 (01/01/2020 - 01/03/2020) (Available seats: 42)" }, { ... }]
     *
     */
    async getProgramOnes(desiredId, showSeats, after, sort) {
        try {
            const response = await this.api.get(sprintf(
                'track/session/level-one?desired-id=%s&show-seats=%s&after=%s&sort=%s&rev=%d',
                desiredId, showSeats, after, sort, this.getRev()));
            return _.map(response.data, KeyValue.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading program 1 sessions: %s', err.data.message);
            throw (new Error(message));
        }
    }
}

const trackDao = new TrackDao();
export default trackDao;
