<template>
<div>
    <b-row>
        <b-col>
            <b-navbar class="header-nav" toggleable="md">
                <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                <b-navbar-brand to="/">
                    <img class="logo" alt="Carpenters Logo" src="@/assets/ubc_logo2.png"/>
                    <img class="logo" alt="CITF Logo" src="@/assets/citf_logo.png"/>
                </b-navbar-brand>
                <Menu/>
            </b-navbar>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="title-text">
                Superintendent Career Training
                <b-badge :variant="envVariant"
                         v-b-tooltip.hover.right
                         :title="'Database: ' + appInfo.database"
                         v-if="showEnvBanner">
                    {{environment}}
                </b-badge>
            </div>
        </b-col>
    </b-row>
</div>
</template>
<script>

import {Component, Vue} from 'vue-property-decorator';
import Menu from '@/views/menu/Menu.vue';
import _ from 'underscore';

@Component({
    components: {
        Menu
    }
})
export default class Header extends Vue {

    get appInfo() {
        return this.$store.getters.appInfo;
    }

    get showEnvBanner() {
        return _.contains(['test', 'trn'], this.appInfo.environment);
    }

    get envVariant() {
        switch (this.appInfo.environment) {
            case 'test':
            return 'danger';

            case 'trn':
                return 'warning';

            default:
                return 'primary';
        }
    }

    get environment() {
        switch (this.appInfo.environment) {
            case 'test':
                return 'TEST';

            case 'trn':
                return 'TRAINING';

            default:
                return '';
        }
    }


}


</script>
<style scoped>

    nav.header-nav {
        padding: 0;
    }

    img.logo {
        max-height: 75px;
        margin: 0 10px 0 0;
    }

    .title-text {
        font-size: 36px;
        font-weight: bold;
    }

</style>
