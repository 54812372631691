
import _ from 'underscore';

export class Role  {
    role = null;
    ord = null;

    constructor(role, ord) {
        this.role = role;
        this.ord = ord;
    }

    toString() {
        return this.role;
    }

    equals(o) {
        return o instanceof Role && this.role === o.role;
    }

    isValid() {
        return true;
    }
}

export class InvalidRole extends Role {
    constructor() {
        super('Invalid', 0);
    }

    isValid() {
        return false;
    }
}

const SecurityLevels = {
    ADMINISTRATOR: new Role('Administrator', 1),
    INSTRUCTOR: new Role('Instructor', 2),
    MENTOR: new Role('Mentor', 3),
    TRAINEE: new Role('Trainee', 4),
    INVALID: new InvalidRole()
};

function extractLevel(k) {
    const key = k.toUpperCase();
    return _.has(SecurityLevels, key) ? SecurityLevels[key] : SecurityLevels.INVALID;
}

export const SecurityLevel = {
    ADMINISTRATOR: SecurityLevels.ADMINISTRATOR,
    INSTRUCTOR: SecurityLevels.INSTRUCTOR,
    MENTOR: SecurityLevels.MENTOR,
    TRAINEE: SecurityLevels.TRAINEE,
    INVALID: SecurityLevels.INVALID,

    from(role) {
        if (role instanceof Role) {
            return role;
        }
        switch (true) {
            case _.isObject(role) && _.has(role, 'role'):
                return extractLevel(role.role);
            case _.isString(role):
                return extractLevel(role);
            default:
                return SecurityLevels.INVALID;
        }
    },

    getLevels() {
        return _.chain(SecurityLevels)
            .values()
            .filter((role) => role.isValid())
            .sortBy((role) => role.ord)
            .value();
    },

    isEligibleToRegister() {
        switch (this) {
            case SecurityLevel.ADMINISTRATOR:
            case SecurityLevel.MENTOR:
                return true;
            case SecurityLevel.INSTRUCTOR:
            case SecurityLevel.TRAINEE:
            case SecurityLevel.INVALID:
            default:
                return false;
        }
    }
};

export const RoleOptions = _.map(SecurityLevel.getLevels(), (role) => {
    return { value: role, text: role.toString() };
});



export class UserStatus {
    static create(status) {
        return new UserStatus(status);
    }

    id = null;
    status = null;

    constructor(status) {
        this.id = status.id;
        this.status = status.status;
    }

    cloneTemplate() {
        return {
            id: this.id,
            status: this.status
        };
    }

    clone(userStatus) {
        return UserStatus.create(this.cloneTemplate());
    }
}

export const ENABLED = new UserStatus({
    id: 1,
    status: 'Enabled'
});

export const DISABLED = new UserStatus({
    id: 2,
    status: 'Disabled'
});

export const NULL_USER_STATUS = new UserStatus({
    id: NaN
});
