
import axios from 'axios';
import { sprintf } from 'sprintf-js';

export const API_VERSION = 1;

let rootStore = null;


const Api = axios.create({
    baseURL: sprintf('/api/v%d', API_VERSION),
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

Api.interceptors.request.use(
    (config) => {
        rootStore.dispatch('addLoadingRequest');
        return config;
    },

    (error) => {
        rootStore.dispatch('removeLoadingRequest');
        return Promise.reject(error);
    }

);

Api.interceptors.response.use(
    (res) => {
        rootStore.dispatch('removeLoadingRequest');
        return res;
    },

    (error) => {
        if (error && error.response && error.response.data) {
            if (error.response.data.errorCode === 'NOT_LOGGED_IN') {

                if (Api.logoutListener) {
                    Api.logoutListener();
                }
            }
        }

        rootStore.dispatch('removeLoadingRequest');
        return Promise.reject(error.response);
    }
);


Api.registerLogoutListener = (listener) => {
    Api.logoutListener = listener;
};

export function setApiStore(store) {
    rootStore = store;
}

export const api = () => Api;
export default () => Api;
