import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {SHA256} from 'crypto-js';
import {NULL_ORGANIZATION, Organization} from '@/model/organization';
import {SessionRegistration, InstructorSessionRegistration, Status} from '@/model/registration';
import {OJT, MA} from '@/model/activity';
import {Phone, NULL_PHONE} from '@/model/phone';
import {EmergencyContact, NULL_EMERGENCY_CONTACT} from '@/model/emergency_contact';
import {Role} from '@/model/security_level';
import {validEmailAddress} from '@/util/validation';
import {UBCID_REGEX} from '@/components/shared/UbcId';
import {SecurityLevel} from "@/model/security_level";
import {date} from '@/util/formatters';
import formatters, {mkDate} from "../util/formatters";


export class UserStatus {
    id = null;
    status = null;

    static create(userStatus) {
        return new UserStatus(userStatus);
    }

    constructor(userStatus) {
        this.id = userStatus.id;
        this.status = userStatus.status;
    }
}

export class User {
    securityLevel = null;
    id = null;
    personId = null;
    attendeeId = null;
    ubcId = null;
    local = null;
    username = null;
    firstName = null;
    middleName = null;
    lastName = null;
    suffix = null;
    organization = NULL_ORGANIZATION;
    emergencyContact = NULL_EMERGENCY_CONTACT.clone();
    title = null;
    email = null;
    phone = null;
    workspace = null;
    editMode = false;
    password = null;
    salt = null;
    mustChangePassword = false;
    statusId = null;
    status = null;
    vaccineVerified = false;
    vaccineVerifiedByUserId = null;
    vaccineVerifiedByUsername = null;
    vaccineVerifiedDate = null;

    constructor(user, asWorkspace) {
        this.securityLevel = SecurityLevel.from(user.securityLevel);

        this.id = user.id;
        this.personId = user.personId;
        this.attendeeId = user.attendeeId;
        this.ubcId = user.ubcId;
        this.local = user.local;
        this.username = user.username;
        this.firstName = user.firstName;
        this.middleName = user.middleName;
        this.lastName = user.lastName;
        this.suffix = user.suffix;

        if (_.has(user, 'organization')) {
            this.organization = Organization.create(user.organization);
        }

        if (_.has(user, 'emergencyContact') && _.isObject(user.emergencyContact)) {
            this.emergencyContact = EmergencyContact.create(user.emergencyContact);
        }

        this.title = user.title;
        this.email = user.email;

        if (!!user.phone) {
            this.phone = Phone.create(user.phone);
        } else {
            this.phone = NULL_PHONE;
        }

        this.password = user.password;
        this.salt = user.salt;
        this.mustChangePassword = true === user.mustChangePassword;
        this.statusId = user.statusId;
        this.status = user.status;
        this.vaccineVerified = user.vaccineVerified;
        this.vaccineVerifiedByUserId = user.vaccineVerifiedByUserId;
        this.vaccineVerifiedByUsername = user.vaccineVerifiedByUsername;
        this.vaccineVerifiedDate = mkDate(user.vaccineVerifiedDate);
        this.workspace = asWorkspace ? NULL_USER : new User(user, true);
    }

    get fields() {
        return [
            'securityLevel',
            'id',
            'personId',
            'attendeeId',
            'ubcId',
            'local',
            'username',
            'firstName',
            'middleName',
            'lastName',
            'suffix',
            'organization',
            'emergencyContact',
            'title',
            'email',
            'phone',
            'statusId',
            'status',
            'vaccineVerified',
            'vaccineVerifiedByUserId',
            'vaccineVerifiedByUsername',
            'vaccineVerifiedDate'
        ];
    }

    static isValidUser(user) {
        const valid = !_.isUndefined(user) && !_.isNull(user) && !_.isEqual(user, NULL_USER) &&
            !_.isUndefined(user.id) && !_.isNull(user.id) && !_.isNaN(user.id) && 0 !== user.id;
        if (valid && user.isAMentor()) {
            return !_.isUndefined(user.traineeIds);
        }
        if (valid && user.isATrainee()) {
            return !_.isUndefined(user.trackId);
        }
        return valid;
    }

    //Parse times so FE to BE communication doesn't cast and auto-convert timezone from local to UTC
    serialize() {
        if (_.isDate(this.vaccineVerifiedDate)) {
            this.vaccineVerifiedDate = this.vaccineVerifiedDate.toString();
        }
    }

    get errors() {
        const errs = {};
        errs.securityLevel = !(this.securityLevel instanceof Role) || !this.securityLevel.isValid();
        errs.firstName = _.isEmpty(this.firstName);
        errs.lastName = _.isEmpty(this.lastName);
        errs.title = _.isEmpty(this.title);
        errs.email = !validEmailAddress(this.email);
        errs.phone = !(this.phone instanceof Phone) || !this.phone.isValid;
        errs.emergencyContactName = !(this.emergencyContact instanceof EmergencyContact) || _.isEmpty(this.emergencyContact.name);
        errs.emergencyContactRelationship = !(this.emergencyContact instanceof EmergencyContact) || _.isEmpty(this.emergencyContact.relationship);
        errs.emergencyContactDayPhone = !(this.emergencyContact instanceof EmergencyContact)
            || !(this.emergencyContact.dayPhone instanceof Phone)
            || !this.emergencyContact.dayPhone.isValid;
        errs.emergencyContactNightPhone = !(this.emergencyContact instanceof EmergencyContact)
            || !(this.emergencyContact.nightPhone instanceof Phone)
            || !this.emergencyContact.nightPhone.isValid;

        errs.organization = null === this.organization || NULL_ORGANIZATION.equals(this.organization);
        // TODO: Ensure that a trainee/mentor has only a participating contractor
        errs.ubcId = this.isATrainee() ? !UBCID_REGEX.test(this.ubcId) : undefined;
        errs.mentor = this.isATrainee() && (0 === (parseInt(this.mentorId, 10) || 0));
        errs.track = this.isATrainee() && (!_.isNumber(this.trackId) || 0 === this.trackId);
        errs.traineeStatus = this.isATrainee() && (!_.isObject(this.traineeStatus) || !_.isNumber(this.traineeStatus.id));
        return errs;
    }

    get hasErrors() {
        const errs = this.errors;
        return _.chain(errs)
            .keys()
            .any((k) => errs[k])
            .value();
    }

    get fullname() {
        return !this.securityLevel.isValid() ? '' : sprintf('%s, %s', this.lastName, this.firstName);
    }

    get userLabel() {
        return !this.securityLevel.isValid() ? '' : sprintf('%s %s %s', this.firstName, this.lastName,
            _.isEmpty(this.ubcId) ? '(Not a UBC member)' : sprintf('(UBC ID: %s)', this.ubcId));
    }

    get isNew() {
        return 0 === (parseInt(this.id, 10) || 0);
    }

    //Roster User model includes implicit globals for registered and scheduled (see RegistrationDao)
    get rosterStatus() {
        if (!User.isValidUser(this) || _.isUndefined(this.scheduled) || _.isUndefined(this.registered)) {
            return Status.INVALID;
        }
        if (!!this.registered && !!this.scheduled) {
            return Status.REGISTERED;
        }
        if (!this.registered && !!this.scheduled) {
            return Status.SCHEDULED;
        }
        return Status.INVALID;
    }

    commit() {
        this.id = this.workspace.id;
        this.personId = this.workspace.personId;
        this.ubcId = this.workspace.ubcId;
        this.username = this.workspace.username;
        this.firstName = this.workspace.firstName;
        this.middleName = this.workspace.middleName;
        this.lastName = this.workspace.lastName;
        this.suffix = this.workspace.suffix;

        this.organization = this.workspace.organization;
        this.title = this.workspace.title;
        this.email = this.workspace.email;
        this.phone = this.workspace.phone.clone();

        this.statusId = this.workspace.statusId;
        this.status = this.workspace.status;
        this.vaccineVerified = this.workspace.vaccineVerified;
        this.vaccineVerifiedByUserId = this.workspace.vaccineVerifiedByUserId;
        this.vaccineVerifiedByUsername = this.workspace.vaccineVerifiedByUsername;
        this.vaccineVerifiedDate = this.workspace.vaccineVerifiedDate;
        this.securityLevel = this.workspace.securityLevel;
        this.emergencyContact = this.workspace.emergencyContact;

        this.editMode = false;
    }

    rollback() {
        this.workspace.id = this.id;
        this.workspace.personId = this.personId;
        this.workspace.ubcId = this.ubcId;
        this.workspace.username = this.username;
        this.workspace.firstName = this.firstName;
        this.workspace.middleName = this.middleName;
        this.workspace.lastName = this.lastName;
        this.workspace.suffix = this.suffix;

        this.workspace.organization = this.organization;
        this.workspace.title = this.title;
        this.workspace.email = this.email;
        this.workspace.phone = this.phone.clone();

        this.workspace.statusId = this.statusId;
        this.workspace.status = this.status;
        this.workspace.vaccineVerified = this.vaccineVerified;
        this.workspace.vaccineVerifiedByUserId = this.vaccineVerifiedByUserId;
        this.workspace.vaccineVerifiedByUsername = this.vaccineVerifiedByUsername;
        this.workspace.vaccineVerifiedDate = this.vaccineVerifiedDate;
        this.workspace.securityLevel = this.securityLevel;
        this.workspace.emergencyContact = this.emergencyContact;

        this.editMode = false;
    }

    isNewUser() {
        return null === this.id || _.isNaN(this.id) || 0 >= this.id;
    }

    isA(role) {
        return role.equals(this.securityLevel);
    }

    isATrainee() {
        return this.isA(SecurityLevel.TRAINEE);
    }

    isAMentor() {
        return this.isA(SecurityLevel.MENTOR);
    }

    isAnInstructor() {
        return this.isA(SecurityLevel.INSTRUCTOR);
    }

    isAnAdministrator() {
        return this.isA(SecurityLevel.ADMINISTRATOR);
    }

    equals(o) {
        return o instanceof User && (_.isNaN(this.id) ? _.isNaN(o.id) : this.id === o.id);
    }

    clone() {
        return UserFactory.create(this.cloneTemplate());
    }

    cloneTemplate() {
        return {
            id: this.id,
            attendeeId: this.attendeeId,
            ubcId: this.ubcId,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            securityLevel: this.securityLevel,
            middleName: this.middleName,
            organization: this.organization.cloneTemplate(),
            title: this.title,
            email: this.email,
            phone: this.phone,
            password: this.password,
            salt: this.salt,
            statusId: this.statusId,
            status: this.status,
            vaccineVerified: this.vaccineVerified,
            vaccineVerifiedByUserId: this.vaccineVerifiedByUserId,
            vaccineVerifiedByUsername: this.vaccineVerifiedByUsername,
            vaccineVerifiedDate: this.vaccineVerifiedDate
        };
    }

    get isEnabled() {
        return this.status.toUpperCase() === 'ENABLED';
    }

    get isDropped() {
        return this.isATrainee() && !!this.traineeStatus && !! this.traineeStatus.status &&
           this.traineeStatus.status.toUpperCase() === 'DROPPED';
    }

    get isEnrolled() {
        return this.isATrainee() && !!this.traineeStatus && !! this.traineeStatus.status &&
            this.traineeStatus.status.toUpperCase() === 'ENROLLED';
    }
}

export default User;

export const NULL_USER = new User({
    id: NaN,
    securityLevel: SecurityLevel.INVALID,
    salt: 'NULL_USER',
    statusId: 2,
    status: 'Disabled'
});




export class Administrator extends User {
    constructor(user) {
        super(user);
    }
}

export class Instructor extends User {
    registrations = [];

    constructor(user) {
        super(user);
        this.registrations = _.map(user.registrations || [], (reg) => null === reg? null: InstructorSessionRegistration.create(reg));
    }

    //Get "unfiltered" registration corresponding to session argument
    getRegistrationForSession(instructorSession) {
        if (_.isEmpty(this.registrations) || _.isEmpty(instructorSession)) {
            return null;
        }
        return _.find(this.registrations, registration => {
            return !_.isNull(registration) && registration.workshopInstanceId === instructorSession.workshopInstanceId;
        })
    }
}

export class Mentor extends User {

    traineeIds = [];
    registrations = [];

    active = false;

    constructor(user) {
        super(user);
        this.traineeIds = user.traineeIds;
        this.registrations = _.map(user.registrations || [], (reg) => null === reg ? null : SessionRegistration.create(reg, true));
        this.active = user.active;

        this.workspace.traineeIds = user.traineeIds;
        this.workspace.active = user.active;
    }

    //Get "unfiltered" registration corresponding to session argument
    getRegistrationForSession(session) {
        if (_.isEmpty(this.registrations) || _.isEmpty(session)) {
            return null;
        }
        return _.find(this.registrations, registration => {
            return !_.isNull(registration) && registration.sessionId === session.sessionId;
        })
    }

    canRegisterFor(user) {
        return _.isEqual(this.id, user.id) || this.traineeIds.contains(user.id);
    }
}

export class Trainee extends User {
    mentorId = null;
    graduated = null;
    dropped = null;
    trackId = null;

    classification = null;
    unionStatus = null;

    registrations = [];
    ojts = [];
    mas = [];

    traineeStatus = null;

    traineeStatusChangeReason = null;

    constructor(user) {
        super(user);
        this.mentorId = user.mentorId || null;
        this.graduated = user.graduated;
        this.dropped = user.dropped;
        this.trackId = user.trackId || null;

        this.classification = user.classification;
        this.unionStatus = user.unionStatus;

        this.registrations = _.map(user.registrations || [], (reg) => null === reg ? null : SessionRegistration.create(reg));

        this.ojts = _.map(user.ojts || [], OJT.create);
        this.mas = _.map(user.mas || [], MA.create);

        this.traineeStatus = user.traineeStatus || null;
        this.traineeStatusChangeReason = user.traineeStatusChangeReason || null;

        this.workspace.mentorId = this.mentorId;
        this.workspace.traineeStatus = this.traineeStatus;
        this.workspace.traineeStatusChangeReason = this.traineeStatusChangeReason;
        this.workspace.trackId = this.trackId;
    }

    //Get "unfiltered" registration corresponding to session argument
    getRegistrationForSession(session) {
        if (_.isEmpty(this.registrations) || _.isEmpty(session)) {
            return null;
        }
        return _.find(this.registrations, registration => {
            return !_.isNull(registration) && registration.sessionId === session.sessionId;
        })
    }

    // NOT USED?!
    // get isBehind() {
    //     // TODO: Determine based on the completeness of OJT/MAs in which program
    //     return (this.firstName || '').startsWith('S');
    // }

    getRegistrationByIndex(idx) {
        if (0 > idx || 4 < idx) {
            return null;
        }
        return this.registrations[idx];
    }

    rollback() {
        super.rollback();
        this.workspace.mentorId = this.mentorId;
        this.workspace.traineeStatus = this.traineeStatus;
        this.workspace.trackId = this.trackId;
    }

    commit() {
        super.commit();
        this.mentorId = this.workspace.mentorId;
        this.traineeStatus = this.workspace.traineeStatus;
        this.trackId = this.workspace.trackId;
    }

    cloneTemplate() {
        const clone = super.cloneTemplate();
        clone.mentorId = parseInt(this.mentorId, 10) || null;
        return clone;
    }
}

export const NULL_TRAINEE = new Trainee({
    id: NaN,
    securityLevel: SecurityLevel.TRAINEE,
    salt: 'NULL_USER',
    statusId: 1,
    status: 'Enabled'
});

export class UserFactory {
    static create(base) {
        if (_.isNull(base) || _.isUndefined(base)) {
            return NULL_USER;
        }
        const u = new User(base);
        switch (true) {
            case u.isAnAdministrator():
                return new Administrator(base);
            case u.isAnInstructor():
                return new Instructor(base);
            case u.isAMentor():
                return new Mentor(base);
            case u.isATrainee():
                return new Trainee(base);
            default:
                return NULL_USER;
        }
    }
}

export function authenticationCredentials(username, password, salt, includeSalt) {
    const creds = {
        username,
        password: SHA256(salt + password).toString()
    };

    if (true === includeSalt) {
        creds.salt = salt;
    }

    return creds;
}


export class SearchCriteria {
    role;
    username;
    lastname;
    ubcId;
    district;
    council;
    contractorType;
    contractor;
    organization;
    state;
    graduationDate;
    programs;
    session;
    sessionRange;
    traineeStatus;
    mentorStatus;
    userStatus;

    constructor() {
        this.clear();
    }

    get fields() {
        return [
            'role',
            'username',
            'lastname',
            'ubcId',
            'district',
            'council',
            'contractorType',
            'contractor',
            'organization',
            'state',
            'graduationDate',
            'programs',
            'session',
            'sessionRange',
            'traineeStatus',
            'mentorStatus',
            'userStatus'
        ]
    }

    clear() {
        _.each(this.fields, (f) => {
            this[f] = null;
        });
        //Manually correct programs to be empty array
        this.programs = [];
    }

    //Reverse-compatibility to enforce single program in array, because life is bewildering and strange
    get program() {
        return !!this.programs ? (this.programs[0] || null) : null;
    }

    set program(newProgram) {
        this.programs = [newProgram];
    }

    serialize() {
        return _.reduce(this.fields, (json, f) => {
            switch (f) {
                case 'graduationDate':
                    if (_.isArray(this.graduationDate) && 2 === this.graduationDate.length) {
                        json[f] = [
                            date(this.graduationDate[0], 'yyyy-MM-dd'),
                            date(this.graduationDate[1], 'yyyy-MM-dd')
                        ];
                    } else {
                        json[f] = null;
                    }
                    break;
                case 'session':
                    json[f] = _.isDate(this[f]) ? date(this[f], 'yyyy-MM-01') : null;
                    break;
                case 'sessionRange':
                    if (_.isArray(this.sessionRange) && 2 === this.sessionRange.length) {
                        json[f] = [
                            date(this.sessionRange[0], 'yyyy-MM-dd'),
                            date(this.sessionRange[1], 'yyyy-MM-dd')
                        ];
                    } else {
                        json[f] = null;
                    }
                    break;
                case 'ubcId':
                    json[f] = formatters.serializeAsUbcId(this.ubcId);
                    break;
                default:
                    json[f] = this[f];
                    break;
            }

            return json;

        }, {});
    }
}

