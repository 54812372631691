<template>
    <b-navbar-nav class="ml-auto top-menu">
        <b-nav-item v-if="hasNotifications"
                    :to="{name:'userNotifications'}">
            <div class="notification">View User Notifications</div>
        </b-nav-item>
        <b-nav-item to="/admin">Home</b-nav-item>
        <b-nav-item to="/admin/help">Help</b-nav-item>
    </b-navbar-nav>
</template>


<script>
import {Component, Vue} from 'vue-property-decorator';
import {UserNotification} from "@/model/notification";
import _ from 'underscore';
import {errorToastOptions} from "@/util/formatters";

@Component
export default class PrivateMenu extends Vue {
    get isLoggedInUserAnAdmin() {
        const loggedInUser = this.$store.getters['userSession/getUser'];
        return loggedInUser.isAnAdministrator();
    }

    get hasNotifications() {
        const notifications = this.$store.getters['notification/userNotifications'];
        //Even if user is an admin, don't display unless there is an active notification past publish date
        const filteredNotifications = _.filter(notifications, n => {
            return n.isPublished();
        });
        return filteredNotifications.length > 0;
    }

    async mounted() {
        try {
            await this.$store.dispatch('notification/loadUserNotifications');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>


<style scoped>
    div.notification {
        color: red !important;
        font-weight: bold !important;
    }
</style>