
import _ from 'underscore';

export class State {
    static create(state) {
        return new State(state);
    }

    constructor(state) {
        this.id = state.id;
        this.state = state.state;
        this.stateAbbreviation = state.stateAbbreviation;
        this.country = state.country;
        this.countryAbbreviation = state.countryAbbreviation;
    }
}

export class CityState {
    city = null;
    state = null;

    constructor(city, state) {
        this.city = city;
        this.state = State.create(state);
    }
}

export const NULL_STATE = State.create({});

export class District {

    static create(district) {
        return new District(district);
    }

    id;
    name;

    constructor(district) {
        this.copyFrom(district);
    }

    get fields() {
        return ['id', 'name'];
    }

    copyFrom(district) {
        _.each(this.fields, (f) => {
            this[f] = district[f];
        });
    }
}

export class Council {

    static create(council) {
        return new Council(council);
    }

    id;
    displayId;
    name;
    districtId;
    districtName;

    constructor(council) {
        this.copyFrom(council);
    }

    get fields() {
        return ['id', 'displayId', 'name', 'districtId', 'districtName'];
    }

    copyFrom(council) {
        _.each(this.fields, (f) => {
            this[f] = council[f];
        });
    }
}


export class Local {

    static create(local) {
        return new Local(local);
    }

    id;
    displayId;
    name;
    city;
    state;
    councilId;
    councilDisplayId;
    councilName;
    districtId;
    districtName;

    constructor(local) {
        this.copyFrom(local);
    }

    get fields() {
        return ['id', 'displayId', 'name', 'city', 'state', 'councilId',
                'councilDisplayId', 'councilName', 'districtId', 'districtName'];
    }

    copyFrom(local) {
        _.each(this.fields, (f) => {
            this[f] = local[f];
        });
    }
}

export class KeyValue {
    static create(kv) {
        return new KeyValue(kv);
    }

    id = null;
    value = null;

    constructor(kv) {
        this.copyFrom(kv);
    }

    get fields() {
        return ['id', 'value'];
    }

    copyFrom(kv) {
        _.each(this.fields, (f) => {
            this[f] = kv[f];
        });
    }

    cloneTemplate() {
        return _.reduce(this.fields, (t, f) => {
            t[f] = this[f];
            return t;
        }, {});
    }

    clone() {
        return KeyValue.create(this.cloneTemplate());
    }
}

export class UnionStatus {

    static create(unionStatus) {
        return new UnionStatus(unionStatus);
    }

    id = null;
    code = null;
    description = null;
    active = null;

    constructor(unionStatus) {
        this.copyFrom(unionStatus);
    }

    get fields() {
        return ['id', 'code', 'description', 'active'];
    }

    copyFrom(unionStatus) {
        _.each(this.fields, (f) => {
            this[f] = unionStatus[f];
        });
    }
}

export class ProgramWorkshop {

    static create(programWorkshop) {
        return new ProgramWorkshop(programWorkshop);
    }

    id = null;
    isMentorSession = null;
    workshopId = null;
    workshopName = null;

    constructor(programWorkshop) {
        this.copyFrom(programWorkshop);
    }

    get fields() {
        return ['id', 'isMentorSession', 'workshopId', 'workshopName'];
    }

    copyFrom(programWorkshop) {
        _.each(this.fields, (f) => {
            this[f] = programWorkshop[f];
        });
    }
}

export class ProgramSession {

    static create(programSession) {
        return new ProgramSession(programSession);
    }

    programSessionId = null;
    workshopInstanceId = null;
    name = null;
    startDate = null;
    endDate = null;

    constructor(programSession) {
        this.copyFrom(programSession);
    }

    get fields() {
        return ['programSessionId', 'workshopInstanceId', 'name','startDate','endDate'];
    }

    copyFrom(programSession) {
        _.each(this.fields, (f) => {
            this[f] = programSession[f];
        });
    }
}