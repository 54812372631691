import download from '@/util/downloader';
import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {API_VERSION} from './api.js';
import {MA, OJT, OJTCategory, CustomActivity, ActivityFactory} from '@/model/activity';
import Comment from '@/model/comment';
import {UserFactory} from '@/model/user';

class ActivityDao extends Dao {
    async getOJTCategories() {
        const url = sprintf('activity/ojt_categories?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            return _.map(response.data, OJTCategory.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading OJT categories: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getOJTs() {
        const url = sprintf('activity/ojt?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            return _.map(response.data, OJT.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading OJTs: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getCustomOJTs() {
        const url = sprintf('activity/custom/ojt?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            const customOJTs = _.map(response.data, CustomActivity.create);
            return customOJTs;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading custom OJTs: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async saveOJT(ojt) {
        try {
            const response = await this.api.post('activity/ojt', ojt);
            return OJT.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing OJT: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getMAs() {
        const url = sprintf('activity/ma?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            return _.map(response.data, MA.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading MAs: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getCustomMAs() {
        const url = sprintf('activity/custom/ma?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            const customMAs = _.map(response.data, CustomActivity.create);
            return customMAs;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading custom MAs: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async saveMA(ma) {
        try {
            const response = await this.api.post('activity/ma', ma);
            return MA.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing MA: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async saveCustomActivity(ca) {
        try {
            const response = await this.api.post('activity/custom', ca.cloneTemplate());
            return CustomActivity.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing custom activity: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getTraineeScheduledActivities(traineeId) {
        const url = sprintf('activity/schedule/%s?rev=%d', traineeId, this.getRev());
        try {
            const response = await this.api.get(url);
            return _.map(response.data, ActivityFactory.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading activity schedule: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async processCustomActivity(activity) {
        const url = 'activity/process/custom';
        try {
            const response = await this.api.post(url, activity);
            return ActivityFactory.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing custom activity: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async processTraineeActivity(traineeActivity) {
        const url = sprintf('activity/schedule/process/%s', traineeActivity.isCustomActivity() ? 'custom' :
            traineeActivity.isOJT() ? 'ojt' : 'ma');
        try {
            const response = await this.api.post(url, traineeActivity);
            return ActivityFactory.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing trainee activity: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async copyTraineeSchedule(fromTraineeId, toTraineeId, includeDates) {
        const url = sprintf('activity/trainee/%d/%d/%s', fromTraineeId, toTraineeId, includeDates);
        try {
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error("Unknown error");
            }
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error copying activity schedule: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getDocument(tag, activityId) {
        const url = sprintf('/api/v%d/activity/document/%s/%d?rev=%d', API_VERSION, tag, activityId, this.getRev());
        try {
            download('GET', url);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading document: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async uploadDocument(tag, activityId, document) {
        const url = sprintf('activity/upload/%s/%d?rev=%d', tag, activityId, this.getRev());
        try {
            const response = await this.api.post(url, document);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error uploading document: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getCustomActivityComments(activity) {
        const url = sprintf('activity/comments/%s/%d?rev=%d', activity.type, activity.id, this.getRev());
        try {
            const response = await this.api.get(url);
            return _.map(response.data, Comment.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading comment: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async searchEligibleTrainees(id, criteria) {
        const url = sprintf('activity/custom/trainee/%d?rev=%d', id, this.getRev());
        try {
            const response = await this.api.post(url, criteria);
            return _.map(response.data, UserFactory.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error finding trainees: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async promote(activity) {
        //Encode attachments as needed
        if (!!activity.suggestions) {
            activity.suggestions = await this.encodeFile(activity.suggestions);
        }
        if (!!activity.reference) {
            activity.reference = await this.encodeFile(activity.reference);
        }
        // console.log(activity);
        try {
            const response = await this.api.post('activity/custom/promote', activity);
            return parseInt(response.data, 10) || 0;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error finding trainees: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async assignTraineesToCustomActivity(type, id, traineeIds, scheduledDate) {
        const url = sprintf('activity/custom/assign/%s/%d/%s', type, id, scheduledDate);
        try {
            const response = await this.api.post(url, traineeIds);
            console.log(response);
            return true;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error assigning trainees: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const activityDao = new ActivityDao();
export default activityDao;
