import _ from 'underscore';
import {mkDate, date} from '@/util/formatters';
import {isAfter, endOfMonth} from 'date-fns';
import {UserFactory, NULL_USER} from '@/model/user';
import {sprintf} from 'sprintf-js';
import {Comment} from '@/model/comment';

//Evaluate activity properties to create as subtype
export class ActivityFactory {
    static create(activity, asWorkspace = false) {
        if (_.isEmpty(activity)) {
            console.log('ERROR - could not type empty activity!');
            console.log(activity);
            return null;
        }
        else if (_.has(activity, 'type')) {
            return new CustomActivity(activity, asWorkspace);
        }
        else if (activity.displayId.charAt(0).toUpperCase() === 'M') {
            return new MA(activity, asWorkspace);
        }
        else if (activity.displayId.charAt(0).toUpperCase() === 'O') {
            return new OJT(activity, asWorkspace);
        }
        else {
            console.log('ERROR - could not type activity!');
            console.log(activity);
            return null;
        }
    }
}


export /* abstract */ class Activity {
    //Activity properties
    id = null;
    activityId = null;
    customActivityId = null;
    displayId = null;
    title = null;
    description = null;
    ordinal = null;
    generation = null;
    recommendedTime = null;
    url = null;
    //Activity assignment properties
    traineeActivityId = null;
    traineeId = null;
    mentorProfileId = null;
    scheduled = null;
    completed = null;
    incomplete = null;
    traineeActivityCancelled = null;  //timestamp
    //Transient properties
    editMode = false;
    workspace = null;

    get fields() {
        return [
            'id', 'activityId', 'customActivityId', 'displayId', 'title', 'description', 'ordinal', 'generation',
            'recommendedTime', 'url', 'traineeActivityId', 'traineeId', 'mentorProfileId', 'scheduled', 'completed',
            'incomplete', 'traineeActivityCancelled'
        ];
    }

    copyFrom(activity) {
        _.each(this.fields, (f) => {
            switch (f) {
                case 'scheduled':
                case 'completed':
                case 'incomplete':
                case 'traineeActivityCancelled':
                    this[f] = !!activity[f] ? mkDate(activity[f]) : null;
                    break;
                default:
                    this[f] = activity[f];
                    break;
            }
        });
    }

    clone() {
        return ActivityFactory.create(this.cloneTemplate());
    }

    cloneTemplate() {
        return _.reduce(this.fields, (t, f) => {
            switch (f) {
                case 'scheduled':
                case 'completed':
                case 'incomplete':
                case 'traineeActivityCancelled':
                    t[f] = _.isDate(this[f]) ? date(this[f]) : null;
                    break;
                default:
                    t[f] = this[f];
            }
            return t;
        }, {});
    }

    rollback() {
        if (this.workspace instanceof Activity) {
            this.workspace.copyFrom(this);
        }
        this.editMode = false;
    }

    commit() {
        if (this.workspace instanceof Activity) {
            this.copyFrom(this.workspace);
        }
        this.editMode = false;
    }

    isMA() {
        return this instanceof MA;
    }

    isOJT() {
        return this instanceof OJT;
    }

    isCustomActivity() {
        return this instanceof CustomActivity;
    }

    get formattedUrl() {
        if (_.isEmpty(this.url)) {
            return this.url;
        }
        else {
            const formattedUrl = this.url.toLowerCase().startsWith('http') ? this.url : sprintf('http://%s', this.url);
            return formattedUrl;
        }
    }

    //For sorting
    get displayIdOrdinal() {
        if (this.isCustomActivity()) {
            const ord = +this.customActivityId;
            return ord;
        }
        else {
            const parts = this.displayId.split('-');
            if (!parts || !parts[1]) {
                return 0;
            }
            else {
                const ord = +parts[1];
                return ord;
            }
        }
    }

    //For sorting
    get displayCategory() {
        const category = this.isCustomActivity() && this.isCustomMA() ? 'zMA' :
            this.isCustomActivity() && this.isCustomOJT() ? 'zOJT' :
                this.isMA() ? 'MA' :
                    this.isOJT() ? 'OJT' : 'zz';
        return category;
    }

    get isCompleted() {
        return _.isDate(this.completed);
    }

    get isLate() {
        return (_.isNull(this.completed) || _.isUndefined(this.completed)) && _.isDate(this.scheduled) && isAfter(new Date(), endOfMonth(this.scheduled));
    }

    get isNew() {
        return (parseInt(this.id, 10) || 0) === 0;
    }

    get isTraineeActivityCancelled() {
        return !_.isNull(this.traineeActivityCancelled) && !_.isUndefined(this.traineeActivityCancelled);
    }

    get label() {
        return sprintf('%s - %s', this.displayId, this.title);
    }
}


export class OJTCategory {
    static create(category) {
        return new OJTCategory(category);
    }

    id = null;
    name = null;
    generation = null;
    ordinal = null;

    constructor(category) {
        this.copyFrom(category);
    }

    get fields() {
        return ['id', 'name', 'generation', 'ordinal'];
    }

    copyFrom(category) {
        _.each(this.fields, (f) => this[f] = category[f]);
    }

    equals(o) {
        return o instanceof OJTCategory && o.id === this.id;
    }

    clone() {
        return OJTCategory.create(this.cloneTemplate());
    }

    cloneTemplate() {
        return _.reduce(this.fields, (t, f) => {
            t[f] = this[f];
            return t;
        }, {});
    }
}


export class OJT extends Activity {
    static create(ojt, asWorkspace) {
        return new OJT(ojt, asWorkspace);
    }

    categoryId = null;
    category = null;

    constructor(ojt, asWorkspace) {
        super();
        this.copyFrom(ojt);
        this.workspace = true === asWorkspace ? null : OJT.create(ojt, true);
    }

    get fields() {
        return [...super.fields, 'categoryId', 'category'];
    }

    copyFrom(ojt) {
        super.copyFrom(ojt);
        if (_.isObject(ojt.category)) {
            this.category = new OJTCategory(ojt.category);
        }
    }

    rollback() {
        super.rollback();
        this.workspace.category = this.category instanceof OJTCategory ? this.category.clone() : null;
    }

    commit() {
        super.commit();
        this.category = this.workspace.category instanceof OJTCategory ? this.workspace.category.clone() : null;
    }

    clone() {
        return OJT.create(this.cloneTemplate());
    }

    cloneTemplate() {
        const template = super.cloneTemplate();
        template.category = this.category instanceof OJTCategory ? this.category.cloneTemplate() : null;
        return template;
    }

    get requiredFields() {
        return ['category', 'displayId', 'description'];
    }

    get ojtErrors() {
        return {
            //Selecting category sets category ID and title
            category: !_.isEmpty(this.title) && !_.isNull(this.categoryId) && !_.isUndefined(this.categoryId),
            //Max 10 characters including 'OJT-' prefix
            displayId: !_.isEmpty(this.displayId) && this.displayId.length > 4 && this.displayId.length <= 10,
            description: !_.isEmpty(this.description)
        };
    }
}


export class MA extends Activity  {
    static create(ma, asWorkspace) {
        return new MA(ma, asWorkspace);
    }

    displayIdPrefix = null;
    suggestions = null;
    reference = null;

    constructor(ma, asWorkspace) {
        super();
        this.copyFrom(ma);
        this.workspace = true === asWorkspace ? null : MA.create(ma, true);
    }

    get fields() {
        return [...super.fields, 'displayIdPrefix', 'suggestions', 'reference'];
    }

    copyFrom(ma) {
        super.copyFrom(ma);
        this.displayIdPrefix = !!ma.displayIdPrefix ? ma.displayIdPrefix : 'M';
    }

    clone() {
        return MA.create(this.cloneTemplate());
    }

    //Determine whether MA needs to be saved, or just documents
    get hasChanges() {
        if (_.isEmpty(this.workspace)) {
            return false;
        }
        if (this.isNew) {
            return true;
        }
        return !_.isEqual(this.displayId, this.workspace.displayId) ||
            !_.isEqual(this.title, this.workspace.title) ||
            !_.isEqual(this.description, this.workspace.description) ||
            !_.isEqual(this.recommendedTime, this.workspace.recommendedTime);
    }

    get requiredFields() {
        return ['displayId', 'title', 'description'];
    }

    get maErrors() {
        return {
            //Max 10 characters including 'M-' prefix
            displayId: !_.isEmpty(this.displayId) && this.displayId.length > 2 && this.displayId.length <= 10,
            title: !_.isEmpty(this.title),
            description: !_.isEmpty(this.description)
        };
    }
}


export const CustomActivityStatus = {
    INVALID: 'INVALID',
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED',
    PENDING_REVISION: 'PENDING_REVISION',
    DECLINED: 'DECLINED'
};


export const CustomActivityType = {
    OJT: 'OJT',
    MA: 'MA'
};


export class CustomActivity extends Activity {
    static create(activity, asWorkspace) {
        return new CustomActivity(activity, asWorkspace);
    }

    type = null;
    organizationId = null;
    active = true;
    status = null;
    created = null;
    creatorId = null;
    creator = null;
    approved = null;
    approverId = null;
    revision = null;
    reviserId = null;
    promoted = null;
    promotedId = null;
    cancelled = false;

    assignedTrainees = 0;

    comment = null;
    comments = [];

    // For reactivity, we need to also list the fields unique to OJTs and MAs (when promoting to standard)
    categoryId = null;
    suggestions = null;
    reference = null;


    constructor(activity, asWorkspace) {
        super();
        this.copyFrom(activity);
        this.workspace = true === asWorkspace ? null : CustomActivity.create(activity, true);
    }

    get fields() {
        return [
            ...super.fields, 'type', 'organizationId', 'active', 'status', 'created', 'creatorId', 'creator',
            'approved', 'approverId', 'revision', 'reviserId', 'promoted', 'promotedId', 'cancelled', 'assignedTrainees',
            'comment'
        ];
    }

    copyFrom(activity) {
        super.copyFrom(activity);
        this.creator = _.isObject(activity.creator) ? UserFactory.create(activity.creator) : NULL_USER;
        if (!!activity.created) {
            this.created = mkDate(activity.created);
        }
        if (!!activity.approved) {
            this.approved = mkDate(activity.approved);
        }
        if (!! activity.promoted) {
            this.promoted = mkDate(activity.promoted);
        }
        if (!!activity.revision) {
            this.revision = mkDate(activity.revision);
        }

        if (!!activity.comment) {
            this.comment = Comment.create(activity.comment);
        }
    }

    cloneTemplate() {
        return _.reduce(['created', 'approved', 'promoted', 'revision'], (t, f) => {
            t[f] = _.isDate(this[f]) ? date(this[f], 'yyyy-MM-dd HH:mm:ss') : null;
            return t;
        }, super.cloneTemplate());
    }

    clone() {
        return CustomActivity.create(this.cloneTemplate());
    }

    isCustomOJT() {
        return _.isEqual(this.type, CustomActivityType.OJT);
    }

    isCustomMA() {
        return _.isEqual(this.type, CustomActivityType.MA);
    }

    get isApproved() {
        return _.isEqual(this.status, CustomActivityStatus.APPROVED);
    }

    get isDeclined() {
        return _.isEqual(this.status, CustomActivityStatus.DECLINED);
    }

    get isInvalid() {
        return _.isEqual(this.status, CustomActivityStatus.INVALID);
    }

    get customDisplayId() {
        return sprintf('%s-CUST-%d', this.type, this.customActivityId);
    }

    //Used for validating
    get errors() {
        return {
            type: _.isEmpty(this.type) || (!_.isEqual(this.type, CustomActivityType.MA) && !_.isEqual(this.type, CustomActivityType.OJT)),
            title: _.isEmpty(this.title),
            description: _.isEmpty(this.description),
            // recommendedTime: _.isEmpty(this.recommendedTime),  //use for standard activities only
            scheduled: _.isDate(this.scheduled) && isAfter(new Date(), endOfMonth(this.scheduled))
        };
    }
}


export const NULL_MA = MA.create({
    id: 0,
    ordinal: 0,
    generation: 0,
    description: '',
    url: null,
    displayIdPrefix: 'M',
    suggestions: false,
    reference: false
});


export const NULL_OJT_CATEGORY = OJTCategory.create({
    id: 0,
    ordinal: 0,
    generation: 0
});


export const ALL_OJT_CATEGORY = OJTCategory.create({
    id: -1,
    name: 'ALL',
    ordinal: -1,
    generation: 0
});


export const NULL_OJT = OJT.create({
    id: 0,
    ordinal: 0,
    generation: 0,
    description: '',
    url: null
});


export const NEW_CUSTOM_ACTIVITY = CustomActivity.create({
    id: 0,
    ordinal: 0,
    generation: 0,
    traineeActivityId: 0,
    type: null,
    status: CustomActivityStatus.SUBMITTED,
    cancelled: false
});
