import _ from 'underscore';
import travelDao from '@/dao/travel_dao';

export const TravelStore = {
    namespaced: true,

    state: {
        policy: '',
        pendingTravelers: [],
        airports: []
    },

    getters: {

        policy: (state) => {
            return state.policy;
        },

        pendingTravelers(state) {
            return state.pendingTravelers;
        },

        airports(state) {
            return state.airports;
        },

        getAirportById: (state) => (id) => _.find(state.airports, (a) => a.id === id)
    },

    mutations: {

        setPolicy: (state, policy) => {
            state.policy = policy;
        },

        setPendingTravelers(state, travelers) {
            state.pendingTravelers = travelers;
        },

        setAirports: (state, airports) => {
            state.airports = airports;
        }
    },

    actions: {
        loadPolicy: async ({commit}) => {
            const policy = await travelDao.getTravelPolicy();
            commit('setPolicy', policy);
        },

        savePolicy: async ({getters}) => {
            await travelDao.saveTravelPolicy(getters.policy);
        },

        loadPendingTravelers: async ({commit}) => {
            const travelers = await travelDao.getPendingTravelers();
            commit('setPendingTravelers', _.chain(travelers)
                .unique((t) => t.id)
                .sortBy((t) => t.firstName)
                .sortBy((t) => t.lastName)
                .value());
        },

        loadAirports: async ({commit}) => {
            const airports = await travelDao.getAirports();
            commit('setAirports', airports);
        }
    }
};

export default TravelStore;