
import _ from 'underscore';
import {Phone, NULL_PHONE, NEW_PHONE} from './phone';
import {validEmailAddress} from '@/util/validation';
import {sprintf} from 'sprintf-js';
import {mkDate, date} from '@/util/formatters';
import {validPhoneNumber} from "../util/validation";
import {trimToEmpty} from "../util/formatters";


export class OrganizationContact {
    id = null;
    firstName = null;
    lastName = null;
    title = null;
    phone = null;
    email = null;
    editMode = false;

    static isValid() {
        return !_.isEqual(this, NULL_CONTACT) &&
            !_.isUndefined(this) && !_.isNull(this) &&
            !_.isUndefined(this.id) && !_.isNull(this.id) && !_.isNaN(this.id);
    }

    get fields() {
        return ['id', 'firstName', 'lastName', 'title', 'phone', 'email'];
    }

    static create(contact) {
        return new OrganizationContact(contact);
    }

    constructor(contact) {
        this.copyFrom(contact);
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            return template;
        }, {});
    }

    clone() {
        return OrganizationContact.create(this.cloneTemplate());
    }

    copyFrom(contact) {
        _.each(this.fields, (key) => {
            if (_.isEqual(key, 'phone')) {
                this.phone = _.isEmpty(contact.phone) ? NULL_PHONE.clone() : Phone.create(contact.phone);
            }
            else {
                this[key] = contact[key];
            }
        });
    }

    get errors() {
        return {
            firstName: _.isEmpty(this.firstName),
            lastName: _.isEmpty(this.lastName),
            title: _.isEmpty(this.title),
            email: !validEmailAddress(this.email),
            phone: !this.phone.isValid
        };
    }

    get isNew() {
        return !(this.id > 0);
    }
}


export class ContractorType {
    static create(type) {
        return new ContractorType(type);
    }

    constructor(type) {
        this.id = type.id;
        this.type = type.type;
    }

    cloneTemplate() {
        return {
            id: this.id,
            type: this.type
        };
    }

    clone() {
        return ContractorType.create(this.cloneTemplate());
    }
}

export class Organization {
    static create(organization, asWorkspace) {
        return new Organization(organization, true === asWorkspace);
    }

    id = null;
    name = null;
    type = null;
    addressId = null;
    address1 = null;
    address2 = null;
    city = null;
    state = null;
    zip = null;
    country = null;
    phone = null;
    contacts = [];
    participating = null;
    editMode = false;

    // We want to be able to click on the row (not just the 'Show Details' link)
    // to expand the contractor details.  To make that work, we need to make
    // the _showDetails property reactive (to Vue.)  Therefore, we need to
    // declare it here.  This does get serialized on a POST to save the org,
    // but is ignored by gson.
    _showDetails = false;

    constructor(organization, asWorkspace) {
        this.copyFrom(organization);
        this.workspace = asWorkspace ? null : Organization.create(organization, true);
    }

    get fields() {
        return [
            'id',
            'name',
            'type',
            'addressId',
            'address1',
            'address2',
            'city',
            'state',
            'zip',
            'country',
            'phone',
            'contacts',
            'participating'
        ]
    }

    get errors() {
        return {
            name: _.isEmpty(this.name),
            type: _.isEmpty(this.type),
            address1: _.isEmpty(this.address1),
            city: _.isEmpty(this.city),
            state: _.isEmpty(this.state),
            zip: _.isEmpty(this.zip),
            phone: !this.phone.isValid
        };
    }

    rollback() {
        this.workspace.copyFrom(this);
        this.editMode = false;
    }

    commit() {
        this.copyFrom(this.workspace);
        this.editMode = false;
    }

    cloneTemplate() {
        const template = _.reduce(this.fields, (template, key) => {
            if (this[key] instanceof ContractorType) {
                template[key] = this[key].cloneTemplate();
            } else {
                template[key] = this[key];
            }
            return template;
        }, {});
        return template;
    }

    clone() {
        return Organization.create(this.cloneTemplate());
    }

    copyFrom(organization) {
        if (_.isNull(organization)) {
            return NULL_ORGANIZATION;
        }
        _.each(this.fields, (key) => {
            switch (key) {
                case 'type':
                    this.type = _.isObject(organization.type) ? ContractorType.create(organization.type) : null;
                    break;
                case 'phone':
                    this[key] = _.isObject(organization[key]) ? Phone.create(organization[key]) : NULL_PHONE.clone();
                    break;
                case 'contacts':
                    if (_.isEmpty(organization.contacts)) {
                        this.contacts = [];
                    }
                    else {
                        const contacts = _.map(organization.contacts, item => {
                            return OrganizationContact.create(item);
                        });
                        this.contacts = contacts;
                    }
                    break;
                default:
                    this[key] = organization[key];
            }
        });
    }

    validate() {
        const keysToValidate = _.keys(this.errors);

        return !_.any(keysToValidate, (key) => this.errors[key]);
    }

    equals(other) {
        switch (true) {
            case !(other instanceof Organization): return false;
            case _.isNaN(this.id): return null === other || null === other.id || _.isNaN(other.id);
            default: return this.id === other.id;
        }
    }

    hasContactWithName(name1, name2 = '') {
        if (_.isEmpty(name1) || _.isEmpty(this.contacts)) {
            return false;
        }
        else {
            const nameRE1 = new RegExp(name1, 'i');
            const nameRE2 = _.isEmpty(name2) ? null : new RegExp(name2, 'i');
            const check = _.isNull(nameRE2) ?
                _.any(this.contacts, c => {
                    return nameRE1.test(c.firstName) || nameRE1.test(c.lastName);
                }) :
                _.any(this.contacts, c => {
                    return nameRE1.test(c.firstName) && nameRE2.test(c.lastName);
                });
            return check;
        }
    }

    get isNew() {
        return !(this.id > 0);
    }
}

export const NULL_CONTACT = OrganizationContact.create({id: 0, phone: NEW_PHONE});
export const NULL_ORGANIZATION = Organization.create({id: NaN, contacts: []});
export const NEW_ORGANIZATION = Organization.create({
    id: NaN,
    contacts: [
        NULL_CONTACT.clone()
    ]
});
export default Organization;
