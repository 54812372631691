
import _ from 'underscore';
import {NULL_USER} from '@/model/user.js';
import {SecurityLevel} from '@/model/security_level';
import userSessionDao from '@/dao/user_session_dao';

export const UserSessionStore = {
    namespaced: true,

    state: {
        user: NULL_USER
    },

    getters: {
        isLoggedIn(state) {
            return state.user.securityLevel !== SecurityLevel.INVALID;
        },

        getUser(state) {
            return state.user;
        },

        isAnAdmin(state) {
            return _.isEqual(state.user.securityLevel, SecurityLevel.ADMINISTRATOR);
        }
    },

    mutations: {
        setUser: (state, user) => {
            state.user = user;
        }
    },

    actions: {
        loadProfile: async ({commit}) => {
            const profile = await userSessionDao.getProfile();
            commit('setUser', profile);
            return profile;
        }
    }
};

export default UserSessionStore;
