import commonDao from '@/dao/common_dao';
import _ from 'underscore';

export const CommonStore = {
    namespaced: true,

    state: {
        states: [],
        districts: [],
        councils: [],
        locals: [],
        trustFunds: [],
        trainingCenters: [],
        workshops: [],
        unionStatuses: [],
        programWorkshops: [],
        program1Sessions: [],
        program4Sessions: [],
        instructorSessions: [],
        //Original implementation of workshopSessions mapped session labels and workshop instance IDs, but at least one
        //backend methods need session ID instead. Adding another array to the state for migration.
        workshopSessionsWorkshopInstanceID: [],
        workshopSessionsSessionID: []
    },

    getters: {
        states(state) {
            return state.states;
        },

        getStateById: (state) => (stateId) => _.find(state.states, (s) => s.id === stateId),

        getStateByAbbreviation: (state) => (abbreviation) => {
            return _.find(state.states, (s) => {
                return (s.stateAbbreviation || '').toLowerCase() === (abbreviation || '').toLowerCase();
            });
        },

        districts: (state) => {
            return state.districts;
        },

        councils: (state) => {
            return state.councils;
        },

        locals: (state) => {
            return state.locals;
        },

        trustFunds: (state) => {
            return state.trustFunds;
        },

        trainingCenters: (state) => {
            return state.trainingCenters;
        },

        workshops: (state) => {
            return state.workshops;
        },

        unionStatuses: (state) => {
            return state.unionStatuses;
        },

        programWorkshops: (state) => {
            return state.programWorkshops;
        },

        program1Sessions: (state) => {
            return state.program1Sessions;
        },

        program4Sessions: (state) => {
            return state.program4Sessions;
        },

        instructorSessions: (state) => {
            return state.instructorSessions;
        },

        workshopSessionsWorkshopInstanceID: (state) => {
            return state.workshopSessionsWorkshopInstanceID;
        },

        workshopSessionsSessionID: (state) => {
            return state.workshopSessionsSessionID;
        }
    },

    mutations: {
        setStates: (state, states) => {
            state.states = states;
        },

        setDistricts: (state, districts) => {
            state.districts = districts;
        },

        setCouncils: (state, councils) => {
            state.councils = councils;
        },

        setLocals: (state, locals) => {
            state.locals = locals;
        },

        setTrustFunds: (state, trustFunds) => {
            state.trustFunds = trustFunds;
        },

        setTrainingCenters: (state, trainingCenters) => {
            state.trainingCenters = trainingCenters;
        },

        setWorkshops: (state, workshops) => {
            state.workshops = workshops;
        },

        setUnionStatuses: (state, unionStatuses) => {
            state.unionStatuses = unionStatuses;
        },

        setProgramWorkshops: (state, programWorkshops) => {
            state.programWorkshops = programWorkshops;
        },

        setProgram1Sessions: (state, sessions) => {
            state.program1Sessions = sessions;
        },

        setProgram4Sessions: (state, sessions) => {
            state.program4Sessions = sessions;
        },

        setInstructorSessions: (state, sessions) => {
            state.instructorSessions = sessions;
        },

        setWorkshopSessionsWorkshopInstanceID: (state, sessions) => {
            state.workshopSessionsWorkshopInstanceID = sessions;
        },

        setWorkshopSessionsSessionID: (state, sessions) => {
            state.workshopSessionsSessionID = sessions;
        }
    },

    actions: {
        loadStates: async (context) => {
            if (context.state.states.length < 1) {
                const states = await commonDao.getStates();
                context.commit('setStates', states);
            }
        },

        loadDistricts: async (context) => {
            if (context.state.districts.length < 1) {
                const districts = await commonDao.getDistricts();
                context.commit('setDistricts', districts);
            }
        },

        loadCouncils: async (context) => {
            if (context.state.councils.length < 1) {
                const councils = await commonDao.getCouncils();
                context.commit('setCouncils', councils);
            }
        },

        loadLocals: async (context) => {
            if (context.state.locals.length < 1) {
                const locals = await commonDao.getLocals();
                context.commit('setLocals', locals);
            }
        },

        loadTrustFunds: async(context) => {
            if (context.state.trustFunds.length < 1) {
                const trustFunds = await commonDao.getTrustFunds();
                context.commit('setTrustFunds', trustFunds);
            }
        },

        loadTrainingCenters: async(context) => {
            if (context.state.trainingCenters.length < 1) {
                const trainingCenters = await commonDao.getTrainingCenters();
                context.commit('setTrainingCenters', trainingCenters);
            }
        },

        loadWorkshops: async (context) => {
            if (context.state.workshops.length < 1) {
                const workshops = await commonDao.getWorkshops();
                context.commit('setWorkshops', workshops);
            }
        },

        loadUnionStatuses: async(context) => {
            if (context.state.unionStatuses.length < 1) {
                const unionStatuses = await commonDao.getUnionStatuses();
                context.commit('setUnionStatuses', unionStatuses);
            }
        },

        loadProgramWorkshops: async (context) => {
            if (context.state.programWorkshops.length < 1) {
                const programWorkshops = await commonDao.getProgramWorkshops();
                context.commit('setProgramWorkshops', programWorkshops);
            }
        },

        loadProgram1Sessions: async ({commit}) => {
            //Loads program 1 sessions as KeyValue for reporting selection
            //Note: do not cache these values in case changes after made after prelim loading
            //OR consider caching with a force-clear from track/instructor store
            const sessions = await commonDao.getProgramSessions(1, false);
            commit('setProgram1Sessions', sessions);
        },

        loadProgram4Sessions: async ({commit}) => {
            //Loads program 4 sessions as KeyValue for reporting selection
            //Note: do not cache these values in case changes after made after prelim loading
            //OR consider caching with a force-clear from track/instructor store
            const sessions = await commonDao.getProgramSessions(4, false);
            commit('setProgram4Sessions', sessions);
        },

        loadInstructorSessions: async ({commit}) => {
            //Loads instructor sessions as KeyValue for reporting selection
            //Note: do not cache these values in case changes after made after prelim loading
            //OR consider caching with a force-clear from track/instructor store
            const sessions = await commonDao.getInstructorSessions();
            commit('setInstructorSessions', sessions);
        },

        loadWorkshopSessionsWorkshopInstanceID: async ({commit}, workshopIds) => {
            //Loads sessions based on workshop ID(s) as KeyValue for reporting selection
            //Note: this is a "transient" value to be replaced by subsequent requests, no caching or ID lookup
            if (_.isEmpty(workshopIds)) {
                commit('setWorkshopSessionsWorkshopInstanceID', []);
                return;
            }
            const sessions = await commonDao.getWorkshopSessionsWorkshopInstanceID(workshopIds);
            commit('setWorkshopSessionsWorkshopInstanceID', sessions);
        },

        loadWorkshopSessionsSessionID: async ({commit}, workshopIds) => {
            //Loads sessions based on workshop ID(s) as KeyValue for reporting selection
            //Note: this is a "transient" value to be replaced by subsequent requests, no caching or ID lookup
            if (_.isEmpty(workshopIds)) {
                commit('setWorkshopSessionsSessionID', []);
                return;
            }
            const sessions = await commonDao.getWorkshopSessionsSessionID(workshopIds);
            commit('setWorkshopSessionsSessionID', sessions);
        }
    }
};

export default CommonStore;