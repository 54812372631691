import {NEW_ORGANIZATION, NULL_CONTACT, NULL_ORGANIZATION, Organization} from './organization';
import {NULL_SESSION, NULL_SESSION_P1, Session} from './session';
import _ from 'underscore';
import {validEmailAddress} from '@/util/validation';
import {NULL_MEMBER, Member} from "./member";
import {DISABLED} from "./security_level";
import {UBCID_REGEX} from '@/components/shared/UbcId';
import {sprintf} from "sprintf-js";
import formatters from "../util/formatters";

export class ApplicationStatus {
    static create(status) {
        return new ApplicationStatus(status);
    }

    constructor(status) {
        this.id = status.id;
        this.type = status.type;
        this.description = status.description;
    }

    cloneTemplate() {
        return {
            id: this.id,
            type: this.type,
            description: this.description
        };
    }

    clone() {
        return ApplicationStatus.create(this.cloneTemplate());
    }

    get label() {
        if (_.isEmpty(this) || _.isEmpty(this.type) || _.isEmpty(this.description)) {
            return 'Undefined';
        }
        else {
            return sprintf('%s - %s', this.type, this.description);
        }
    }

    get statusId() {
        return this.id;
    }

    get isApplied() {
        return _.isEqual(this.type, 'Applied');
    }

    get isDeclined() {
        return _.isEqual(this.type, 'Declined');
    }

    get isEnrolled() {
        return _.isEqual(this.type, 'Enrolled');
    }
    
    get requiresReason() {
        return _.isEqual(this.description, 'Other');
    }
}

export const NULL_STATUS = ApplicationStatus.create({
    id: NaN
});

export class Application {
    //Fields
    id = null;
    status = NULL_STATUS;
    appliedDate = new Date();
    statusDate = new Date();
    reviewer = null;
    session = NULL_SESSION;
    member = NULL_MEMBER;
    mentor = NULL_MEMBER;
    contractor = NULL_ORGANIZATION;
    editMode = false;

    get fields() {
        return [
            'id',
            'status',
            'appliedDate',
            'statusDate',
            'reviewer',
            'session',
            'member',
            'mentor',
            'contractor',
            'editMode'
        ]
    }

    static create(application, asWorkspace) {
        return new Application(application, true === asWorkspace);
    }

    constructor(application, asWorkspace) {
        this.copyFrom(application);
        this.workspace = asWorkspace ? null : Application.create(application, true);
        return;
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) =>
        {
            if (this[key] instanceof ApplicationStatus) {
                template[key] = this[key].cloneTemplate();
            }
            else if (this[key] instanceof Session) {
                template[key] = this[key].cloneTemplate();
            }
            else if (this[key] instanceof Member) {
                template[key] = this[key].cloneTemplate();
            }
            else if (this[key] instanceof Organization) {
                template[key] = this[key].cloneTemplate();
            }
            else {
                template[key] = this[key];
            }
            return template;
        }, {});

        return;
    }

    clone() {
        return Application.create(this.cloneTemplate());
    }

    copyFrom(application) {
        if (_.isNull(application)) {
            return NULL_APPLICATION;
        }
        _.each(this.fields, (key) => {
            switch (key) {
                case 'status':
                    this.status = _.isObject(application.status) ? ApplicationStatus.create(application.status) : NULL_STATUS.clone();
                case 'session':
                    this.session = _.isObject(application.session) ? Session.create(application.session) : NULL_SESSION.clone();
                    break;
                case 'member':
                case 'mentor':
                    this[key] = _.isObject(application[key]) ? Member.create(application[key]) : NULL_MEMBER.clone();
                    break;
                case 'contractor':
                    this.contractor = _.isObject(application.contractor) ? Organization.create(application.contractor) : NULL_ORGANIZATION.clone();
                default:
                    this[key] = application[key];
            }
        });
    }

    rollback() {
        this.workspace.copyFrom(this);
        this.editMode = false;
    }

    commit() {
        this.copyFrom(this.workspace);
        this.editMode = false;
    }

    serialize() {
        this.member.serialize();
        this.mentor.serialize();
        // console.log(this.member.ubcId);
        // console.log(this.mentor.ubcId);
    }

    get isNew() {
        return _.isNaN(this.id) || this.id === 0;
    }

    get isApplied() {
        return !_.isEmpty(this.status) && this.status.isApplied;
    }

    get isDeclined() {
        return !_.isEmpty(this.status) && this.status.isDeclined;
    }

    get isEnrolled() {
        return !_.isEmpty(this.status) && this.status.isEnrolled;
    }

    //Clear contractor to allow for manual review entry from validated list (call on base)
    resetContractor() {
        this.workspace.contractor = NULL_ORGANIZATION.clone();
    }

    //Make JS(ON) edits for integration (call on base)
    updateStatusDate() {
        if (this.isNew && this.isDeclined) {
            //For new applications, only change status date if status = (auto) declined
            //New applications submit base
            this.statusDate = new Date().toString();
        }
        else {
            //For existing applications, only change status date if it has changed (i.e. to enrolled/declined)
            //Existing applications submit workspace
            this.workspace.statusDate = new Date().toString();
        }
    }

    //Make JS(ON) edits for integration (call on base)
    interimSave() {
        //If a new contractor isn't set, pull original entry
        if (_.isEqual(this.workspace.contractor, NULL_ORGANIZATION) || _.isNull(this.workspace.contractor)) {
            this.workspace.contractor = this.contractor;
        }
    }

    //Make JS(ON) edits for integration (call on workspace)
    enroll() {
        this.member.enroll();
        this.mentor.enroll();
    }

    //Use case: disable trainee (if active SCT user exists) when declining a previously enrolled application
    get disableTraineeRoleStatus() {
        if (this.status.isEnrolled && this.workspace.status.isDeclined) {
            return this.workspace.member.isNew || this.workspace.member.isDisabled;
        }
        else {
            return true;
        }
    }

    //Contractor contact serialization fix for PRELIM application
    get applicationFirstContact() {
        if (_.isEmpty(this.contractor.contacts)) {
            return NULL_CONTACT.clone();
        }
        else {
            return this.contractor.contacts[0];
        }
    }

    //Application PRELIM validation - use with EnrollmentForm, return true for error state
    get applicationErrors() {
        return {
            //SESSION
            session: _.isNaN(this.session.sessionId) || _.isEqual(this.session, NULL_SESSION),
            //MEMBER
            memberUbcId: _.isEmpty(this.member.ubcId) || !UBCID_REGEX.test(this.member.ubcId),
            memberTitle: !this.member.disableTitle && _.isEmpty(this.member.title),
            memberEmail: !this.member.disableEmail && !validEmailAddress(this.member.email),
            memberPhone: !this.member.disablePhone && !this.member.phone.isValid,
            memberEmergencyContactName: !this.member.disableEmergencyContact && _.isEmpty(this.member.emergencyContact.name),
            memberEmergencyContactRelationship: !this.member.disableEmergencyContact && _.isEmpty(this.member.emergencyContact.relationship),
            memberEmergencyContactDayPhone: !this.member.emergencyContact.disableDayPhone && !this.member.emergencyContact.dayPhone.isValid,
            memberEmergencyContactNightPhone: !this.member.emergencyContact.disableNightPhone && !this.member.emergencyContact.nightPhone.isValid,
            //Mentor
            mentorAssignment: _.isNaN(this.mentor.personId) || this.mentor.isSamePerson(this.member) || this.mentor.matchesContact(this.contractor),
            mentorFirstName: !this.mentor.disableMentorFields && _.isEmpty(this.mentor.firstName),
            mentorLastName: !this.mentor.disableMentorFields && _.isEmpty(this.mentor.lastName),
            mentorTitle: !this.mentor.disableMentorFields && _.isEmpty(this.mentor.title),
            mentorAddress1: !this.mentor.disableMentorFields && _.isEmpty(this.mentor.address1),
            mentorCity: !this.mentor.disableMentorFields && _.isEmpty(this.mentor.city),
            mentorState: !this.mentor.disableMentorFields && _.isEmpty(this.mentor.state),
            mentorZip: !this.mentor.disableMentorFields && (_.isEmpty(this.mentor.zip) || this.mentor.zip.length < 5),
            mentorEmail: !this.mentor.disableMentorFields && !this.mentor.disableEmail && !validEmailAddress(this.mentor.email),
            mentorPhone: !this.mentor.disableMentorFields && !this.mentor.disablePhone && !this.mentor.phone.isValid,
            mentorEmergencyContact: !this.mentor.disableMentorFields && !this.mentor.disableEmergencyContact &&  _.isEmpty(this.mentor.emergencyContact.name),
            mentorEmergencyContactRelationship: !this.mentor.disableMentorFields && !this.mentor.disableEmergencyContact && _.isEmpty(this.mentor.emergencyContact.relationship),
            mentorEmergencyContactDayPhone: !this.mentor.disableMentorFields && !this.mentor.emergencyContact.disableDayPhone && !this.mentor.emergencyContact.dayPhone.isValid,
            mentorEmergencyContactNightPhone: !this.mentor.disableMentorFields && !this.mentor.emergencyContact.disableNightPhone && !this.mentor.emergencyContact.nightPhone.isValid,
            //Contractor
            contractorName: _.isEmpty(this.contractor.name),
            contractorType: _.isEmpty(this.contractor.type),
            contractorAddress1: _.isEmpty(this.contractor.address1),
            contractorCity: _.isEmpty(this.contractor.city),
            contractorState: _.isEmpty(this.contractor.state),
            contractorZip: _.isEmpty(this.contractor.zip) || this.contractor.zip.length < 5,
            contractorContactFirstName: _.isNull(this.applicationFirstContact) || _.isEmpty(this.applicationFirstContact.firstName),
            contractorContactLastName: _.isNull(this.applicationFirstContact) || _.isEmpty(this.applicationFirstContact.lastName),
            contractorContactTitle: _.isNull(this.applicationFirstContact) || _.isEmpty(this.applicationFirstContact.title),
            contractorContactEmail: _.isNull(this.applicationFirstContact) || !validEmailAddress(this.applicationFirstContact.email),
            contractorContactPhone: _.isNull(this.applicationFirstContact) || !this.applicationFirstContact.phone.isValid
        };
    }

    //Application FINAL validation - use with EnrollmentForm/main enrollment
    get enrollmentErrors() {
        return {
            //Session
            session: _.isNaN(this.workspace.session.sessionId),
            //Status
            status: _.isEmpty(this.workspace.status) || _.isEqual(this.workspace.status, NULL_STATUS),
            //Member
            memberUbcId: _.isEmpty(this.workspace.member.ubcId) ||
                (this.workspace.member.isEnrolled && this.workspace.member.isAMentor) ||
                this.workspace.member.activeTrainee === true,
            memberTitle: !this.workspace.member.disableTitle && _.isEmpty(this.workspace.member.title),
            memberEmail: !this.workspace.member.disableEmail && !validEmailAddress(this.workspace.member.email),
            memberPhone: !this.workspace.member.disablePhone && !this.workspace.member.phone.isValid,
            memberEmergencyContactName: !this.workspace.member.disableEmergencyContact && _.isEmpty(this.workspace.member.emergencyContact.name),
            memberEmergencyContactRelationship: !this.workspace.member.disableEmergencyContact && _.isEmpty(this.workspace.member.emergencyContact.relationship),
            memberEmergencyContactDayPhone: !this.workspace.member.emergencyContact.disableDayPhone && !this.workspace.member.emergencyContact.dayPhone.isValid,
            memberEmergencyContactNightPhone: !this.workspace.member.emergencyContact.disableNightPhone && !this.workspace.member.emergencyContact.nightPhone.isValid,
            memberUsername: !this.workspace.member.disableUsername &&
                (_.isEmpty(this.workspace.member.username) || !this.workspace.member.validUsername),
            memberUnionStatus: !this.workspace.member.inGoodStanding,
            memberOshaStatus: !this.status.isEnrolled && !this.workspace.member.oshaPrerequisiteMet,
            memberVaccineStatus: !this.status.isEnrolled && !this.workspace.member.vaccinePrerequisiteMet,
            //Mentor
            mentorAssignment: _.isNaN(this.workspace.mentor.personId) ||
                this.workspace.mentor.isSamePerson(this.workspace.member) ||
                (this.workspace.mentor.isEnrolled && this.workspace.mentor.isATrainee) ||
                this.workspace.mentor.matchesContact(this.workspace.contractor),
            mentorFirstName: !this.workspace.mentor.disableMentorFields && _.isEmpty(this.workspace.mentor.firstName),
            mentorLastName: !this.workspace.mentor.disableMentorFields && _.isEmpty(this.workspace.mentor.lastName),
            mentorTitle: !this.workspace.mentor.disableMentorFields && _.isEmpty(this.workspace.mentor.title),
            mentorAddress1: !this.workspace.mentor.disableMentorFields && _.isEmpty(this.workspace.mentor.address1),
            mentorCity: !this.workspace.mentor.disableMentorFields && _.isEmpty(this.workspace.mentor.city),
            mentorState: !this.workspace.mentor.disableMentorFields && _.isEmpty(this.workspace.mentor.state),
            mentorZip: !this.workspace.mentor.disableMentorFields && (_.isEmpty(this.workspace.mentor.zip) || this.workspace.mentor.zip.length < 5),
            mentorEmail: !this.workspace.mentor.disableMentorFields && !this.workspace.mentor.disableEmail && !validEmailAddress(this.workspace.mentor.email),
            mentorPhone: !this.workspace.mentor.disableMentorFields && !this.workspace.mentor.disablePhone && !this.workspace.mentor.phone.isValid,
            mentorEmergencyContact: !this.workspace.mentor.disableMentorFields && !this.workspace.mentor.disableEmergencyContact &&  _.isEmpty(this.workspace.mentor.emergencyContact.name),
            mentorEmergencyContactRelationship: !this.workspace.mentor.disableMentorFields && !this.workspace.mentor.disableEmergencyContact && _.isEmpty(this.workspace.mentor.emergencyContact.relationship),
            mentorEmergencyContactDayPhone: !this.workspace.mentor.disableMentorFields && !this.workspace.mentor.emergencyContact.disableDayPhone && !this.workspace.mentor.emergencyContact.dayPhone.isValid,
            mentorEmergencyContactNightPhone: !this.workspace.mentor.disableMentorFields && !this.workspace.mentor.emergencyContact.disableNightPhone && !this.workspace.mentor.emergencyContact.nightPhone.isValid,
            mentorUsername: !this.workspace.mentor.disableUsername && (_.isEmpty(this.workspace.mentor.username) || !this.workspace.mentor.validUsername),
            //Contractor
            contractorAssignment: _.isEqual(this.workspace.contractor, NULL_ORGANIZATION) || _.isEqual(this.workspace.contractor, NEW_ORGANIZATION)
        };
    }

    //Application FINAL validation - use with EnrollmentForm/Decline modal
    get declineErrors() {
        return {
            //Status
            status: _.isEmpty(this.workspace.status) || _.isEqual(this.workspace.status, NULL_STATUS),
            statusReason: this.workspace.status.requiresReason && _.isUndefined(this.workspace.statusReason),
            //Member
            memberRoleStatus: !this.disableTraineeRoleStatus && this.workspace.status.isDeclined && _.isEmpty(this.workspace.member.roleStatus)
        };
    }
}

export const NULL_APPLICATION = Application.create({
    id: NaN,
    status: NULL_STATUS.clone(),
    appliedDate: null,
    statusDate: null,
    reviewer: null,
    editMode: false,
    session: NULL_SESSION_P1.clone(),
    member: NULL_MEMBER.clone(),
    mentor: NULL_MEMBER.clone(),
    contractor: NEW_ORGANIZATION.clone()
});

