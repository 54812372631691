<template>
    <div class="ubc-id">
        <masked-input :id="id"
                      v-model="ubcId"
                      :class="maskedInputClass"
                      mask="U-####-####"
                      placeholder="U-0000-0000"
                      @keyup.native="enterPressed"
                      masked/>
    </div>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import {TheMask as MaskedInput} from 'vue-the-mask';


export const UBCID_REGEX = /^U-?(\d{4})-?(\d{4})$/;

export function formatUbcId(ubcId) {
    return (ubcId || '').replace(UBCID_REGEX, 'U-$1-$2');
}

export function formatUbcIdNoDash(ubcId) {
    return (ubcId || '').replace(UBCID_REGEX, 'U$1$2');
}

@Component({
    components: {
        MaskedInput
    }
})
export class UbcId extends Vue {
    @Prop({type: String, default: null}) id;
    @Prop({type: String, default: null}) value;
    @Prop({type: String, default: 'form-control'}) inputClass;
    @Prop({type: Boolean, default: false}) showStatus;


    get ubcId() {
        return this.value;
    }

    set ubcId(value) {
        this.$emit('input', value);
    }

    get isValid() {
        return UBCID_REGEX.test(this.ubcId);
    }

    get maskedInputClass() {
        if (!this.showStatus) {
            return this.inputClass;
        }

        return this.inputClass + ' ' + (this.isValid ? ' is-valid' : 'is-invalid');
    }

    enterPressed(e) {

        if (13 !== e.keyCode) {
            return;
        }

        this.$emit('submitted');
    }
}

export default UbcId;

</script>
<style scoped>
    div.ubc-id {
        width: 130px;
    }
</style>