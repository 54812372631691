import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {ReleaseNote, UserNotification} from "@/model/notification";

class NotificationDao extends Dao {
    async getSupportUsers() {
        const url = sprintf('notification/support_users?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading IT support users: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getUserNotifications() {
        const url = sprintf('notification/user/all?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            const notifications = _.map(response.data, UserNotification.create);
            // console.log(notifications);
            return notifications;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading user notifications: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getReleaseNotes() {
        const url = sprintf('notification/release/all?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            const notes = _.map(response.data, ReleaseNote.create);
            // console.log(notes);
            return notes;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading release notes: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async processUserNotification(notification) {
        const notificationJson = notification.serialize();
        try {
            const response = await this.api.post('notification/user', notificationJson);
            // console.log(response);
            return UserNotification.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing user notification: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async processReleaseNote(note) {
        const noteJson = note.serialize();
        try {
            const response = await this.api.post('notification/release', noteJson);
            // console.log(response);
            return ReleaseNote.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing release note: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const notificationDao = new NotificationDao();
export default notificationDao;
