import organizationDao from '@/dao/organization_dao';
import commentDao from '@/dao/comment_dao';
import _ from 'underscore';
import {NULL_ORGANIZATION} from "../model/organization";

export const OrganizationStore = {
    namespaced: true,

    state: {
        contractors: [],
        organizations: [],
        contractorTypes: []
    },

    getters: {
        getContractor: (state) => (id) => {
            const contractor = _.findWhere(state.contractors, c => {
                return _.isEqual(c.id, id);
            });
            return contractor || NULL_ORGANIZATION;
        },

        getContractors(state) {
            return state.contractors;
        },

        getOrganizations(state) {
            return state.organizations;
        },

        organizationSelectOptions(state, getters) {
            return _.chain(getters.getOrganizations)
                    .map((o) => {
                        return {
                            value: o,
                            text: o.name
                        };
                    })
                .sortBy((o) => o.text)
                .value();
        },

        getContractorTypes(state) {
            return state.contractorTypes;
        }
    },

    mutations: {
        setContractors: (state, contractors) => {
            state.contractors = contractors;
        },

        setOrganizations: (state, organizations) => {
            state.organizations = organizations;
        },

        setContractorTypes: (state, contractorTypes) => {
            state.contractorTypes = contractorTypes;
        },

        setOrganization: (state, organization) => {
            // If it's not already in the list, add it
            if (0 > _.findIndex(state.organizations, (org) => organization.id === org.id)) {
                state.organizations.push(organization);
            }
            // Otherwise the org.commit() will update accordingly
        }
    },

    actions: {
        loadContractors: async ({commit}) => {
            const contractors = await organizationDao.getContractors();
            commit('setContractors', contractors);
            return contractors;
        },

        loadOrganizations: async ({commit}) => {
            const organizations = await organizationDao.getOrganizations();
            commit('setOrganizations', organizations);
            return organizations;
        },

        loadContractorTypes: async (context) => {
            if (context.state.contractorTypes.length < 1) {
                const contractorTypes = await organizationDao.getContractorTypes();
                context.commit('setContractorTypes', contractorTypes);
            }
        },

        getComments: async({}, orgId) => {
            return await commentDao.getComments(orgId, 'contractor');
        },

        saveComments: async({}, comment) => {
            return await commentDao.saveComment(comment.cloneTemplate(), 'contractor');
        },

        saveOrganization: async({commit, getters}, organization) => {
            // console.log(organization);
            const savedOrganization = await organizationDao.saveOrganization(organization.workspace);
            organization.commit();
            // Update the id fields from the values we got from the database.
            // This really only has meaning when new records (be it organization, address, or phone)
            // are created.
            organization.id = savedOrganization.id;
            organization.phone.id = savedOrganization.phone.id;
            organization.addressId = savedOrganization.addressId;
            organization.contacts = savedOrganization.contacts;
            // Also update the workspace ids as well
            organization.workspace.id = organization.id;
            organization.workspace.phone.id = organization.phone.id;
            organization.workspace.addressId = organization.addressId;
            organization.workspace.contacts = organization.contacts;
            // console.log(organization);
            commit('setOrganization', organization);
        }
    }
};

export default OrganizationStore;