import Dao from './dao';
import {State, District, Council, Local, KeyValue, UnionStatus, ProgramWorkshop, ProgramSession} from '@/model/common';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';

class CommonDao extends Dao {
    async getInstructorSessions() {
        try {
            const response = await this.api.get(sprintf('common/sessions/instructor?rev=%d', this.getRev()));
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading instructor sessions: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getProgramSessions(idx, mentorSession) {
        try {
            const url = sprintf('common/sessions/program/%s/%s?rev=%d', idx, mentorSession, this.getRev());
            const response = await this.api.get(url);
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading %sprogram %s sessions: %s', mentorSession ? 'mentor ' : '',
                idx, error.data.message);
            throw (new Error(message));
        }
    }

    //Produces a map of workshop instance ID, session label
    async getWorkshopSessionsWorkshopInstanceID(workshopIds) {
        try {
            const url = sprintf('common/sessions/workshop/wiid?rev=%d&workshopIds=%s', this.getRev(),
                JSON.stringify(workshopIds));
            const response = await this.api.get(url);
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading sessions for workshop ID(s) %s: %s', JSON.stringify(workshopIds),
                error.data.message);
            throw (new Error(message));
        }
    }

    //Produces a map of session ID, session label
    async getWorkshopSessionsSessionID(workshopIds) {
        try {
            const url = sprintf('common/sessions/workshop/sid?rev=%d&workshopIds=%s', this.getRev(),
                JSON.stringify(workshopIds));
            const response = await this.api.get(url);
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading sessions for workshop ID(s) %s: %s', JSON.stringify(workshopIds),
                error.data.message);
            throw (new Error(message));
        }
    }

    async getStates() {
        try {
            const response = await this.api.get(sprintf('common/states?rev=%d', this.getRev()));
            return _.map(response.data, State.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading states: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getDistricts() {
        try {
            const response = await this.api.get(sprintf('common/districts?rev=%d', this.getRev()));
            return _.map(response.data, District.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading districts: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getCouncils() {
        try {
            const response = await this.api.get(sprintf('common/councils?rev=%d', this.getRev()));
            return _.map(response.data, Council.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading councils: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getLocals() {
        try {
            const response = await this.api.get(sprintf('common/locals?rev=%d', this.getRev()));
            return _.map(response.data, Local.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading locals: %s', error.data.message);
            throw (new Error(message));
        }
    }

    /**
     * From LookupController
     * @returns [{"id": 1, "value": "NV, Las Vegas, 1 - Carpenters International Training Fund" }, { ... }]
     */
    async getTrustFunds() {
        try {
            const response = await this.api.get(sprintf('common/trust-funds?rev=%d', this.getRev()));
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading trust funds: %s', error.data.message);
            throw (new Error(message));
        }
    }

    /**
     * From LookupController
     * @returns [{"id": 216, "value": "NV, Las Vegas, 216 - Carpenters International Training Center" }, { ... }]
     */
    async getTrainingCenters() {
        try {
            const response = await this.api.get(sprintf('common/training-centers?rev=%d', this.getRev()));
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading training centers: %s', error.data.message);
            throw (new Error(message));
        }
    }

    /**
     * From UserController
     * @returns [{id: 1, status: 'Enrolled', ...}]
     */
    async getTraineeStatuses() {
        try {
            const response = await this.api.get(sprintf('trainee/statuses?rev=%d', this.getRev()));
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading trainee statuses: %s', error.data.message);
            console.log(message);
            throw (new Error(message));
        }
    }

    /**
     * From LookupController
     * @returns [{id:1, code:'GOODSTANDG', description:'Good Standing', active:true},...]
     */
    async getUnionStatuses() {
        try {
            const response = await this.api.get(sprintf('common/union-statuses?rev=%d', this.getRev()))
            return _.map(response.data, UnionStatus.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading union statuses: %s', error.data.message);
            throw (new Error(message));
        }
    }

    /**
     * From LookupController
     * @returns [{programWorkshopId:1, workshopId:1, workshopName:'Superintendent Career Training - Program 1'}, ...]
     */
    async getProgramWorkshops() {
        try {
            const response = await this.api.get(sprintf('common/program-workshops?rev=%d', this.getRev()))
            return _.map(response.data, ProgramWorkshop.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading Program Workshops: %s', error.data.message);
            throw (new Error(message));
        }
    }

    /**
     * Gets a list of application versioning info, version, environment variables, database, etc...
     * Handler LookupController
     * Response Body: {
     *     "environment": "test|training|prod",
     *     "version": "xxx"
     * }
     */
    async getAppInfo() {
        try {
            const response = await this.api.get(sprintf('app/info?rev=%d',this.getRev()));
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error app info: %s', error.data.message);
            throw (new Error(message));
        }
    }

    /**
     * Gets a list of workshop names (and their ids) to populate search criteria
     * Handler LookupController
     * Response Body: [{
     *     "id": X,
     *     "value: "..."
     * }, {...}, ...]
     */
    async getWorkshops() {
        try {
            const response = await this.api.get(sprintf('common/workshops?rev=%d', this.getRev()));
            return _.map(response.data, KeyValue.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading workshops: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getVideosManifest() {
        try {
            const url = sprintf('videos/manifest?rev=%d', this.getRev());
            const response = await this.api.get(url);
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading video manifest: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const commonDao = new CommonDao();
export default commonDao;