<template>
    <b-alert class="loading-dialog position-fixed fixed-bottom m-0 rounded-0" variant="secondary" v-model="showLoading">
        <b-spinner label="Loading ..." small/>
        <strong class="ml-3 loading-text">Loading ...</strong>
    </b-alert>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';

@Component
export default class LoadingShade extends Vue {

    get showLoading() {
        return this.$store.getters.loadingRequests > 0;
    }
}
</script>
<style scoped>
    .loading-dialog {
        z-index: 100000;
    }

    .loading-text {
        font-size: 18px;
        font-weight: bold;
    }
</style>