import Dao from './dao';
import {sprintf} from 'sprintf-js';
import {Application, ApplicationStatus} from "@/model/enrollment";
import _ from 'underscore';
import {formatUbcIdNoDash} from "../components/shared/UbcId";

//ApplicationController
class ApplicationDao extends Dao {
    //Step 1: Submit original application
    async submitApplication(application) {
        // console.log(application);
        try {
            const response = await this.api.post('application/submit', application);
            return Application.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing application: %s', error.data.message);
            throw (new Error(message));
        }
    }

    //Step 1a. Optional - edit only non-enrolled, non-declined application
    async saveApplication(application) {
        // console.log(application);
        try {
            const response = await this.api.post(sprintf('application/save'), application);
            const result = Application.create(response.data);
            return result;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing application: %s', error.data.message);
            throw (new Error(message));
        }
    }

    //Step 2. Enroll/decline reviewed application
    async updateApplication(application) {
        // console.log(application);
        try {
            const response = await this.api.post(sprintf('application/update'), application);
            return Application.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing application: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getApplicationStatuses() {
        const url = sprintf('application/statuses?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            const statuses = _.map(response.data, ApplicationStatus.create);
            return statuses;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading application statuses: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getApplication(applicationId) {
        const url = sprintf('application/%d?rev=%d', applicationId, this.getRev());
        try {
            const response = await this.api.get(url);
            const application = Application.create(response.data);
            return application;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading application ID %s: %s', applicationId, error.data.message);
            throw (new Error(message));
        }
    }

    async getApplications() {
        const url = sprintf('applications?rev=%d', this.getRev());
        try {
            const response = await this.api.get(url);
            const applications = _.map(response.data, Application.create);
            return applications;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading applications: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getApplicationHistory(ubcId) {
        const url = sprintf('application/history/%s?rev=%d', formatUbcIdNoDash(ubcId), this.getRev());
        try {
            const response = await this.api.get(url);
            const applications = _.map(response.data, Application.create);
            return applications;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading application history by UBC ID: %s', error.data.message);
            throw (new Error(message));
        }
    }

    //Returns boolean - true for blocking history, false for no blocking history
    async hasTraineeHistory(ubcId) {
        const url = sprintf('application/trainee/%s?rev=%d', formatUbcIdNoDash(ubcId), this.getRev());
        try {
            const response = await this.api.get(url);
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading trainee history by UBC ID: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getApplicationAssociations(applicationId) {
        const url = sprintf('application/association/%d?rev=%d', applicationId, this.getRev());
        try {
            const response = await this.api.get(url);
            const associations = response.data;
            return associations;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading application association: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const applicationDao = new ApplicationDao();
export default applicationDao;