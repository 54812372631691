<template>
    <b-navbar-nav class="ml-auto top-menu">
        <b-nav-item to="/home">Home</b-nav-item>
        <b-nav-item to="/training">Training</b-nav-item>
        <b-nav-item to="/faq">FAQ</b-nav-item>
        <b-nav-item to="/getting-started">Getting Started</b-nav-item>
        <b-nav-item to="/contact">Contact Us</b-nav-item>
    </b-navbar-nav>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class PublicMenu extends Vue {

}
</script>
<style scoped>

</style>