import Dao from '@/dao/dao';
import {getUnixTime} from 'date-fns';
import _ from 'underscore';
import Comment from '@/model/comment';
import {sprintf} from 'sprintf-js';

export class CommentDao extends Dao {

    /**
     * Returns a list of comments for a particular entity
     * @param recordId The id of the entity
     * @param type One of
     *   1) contractor (handler found in OrganizationController)
     *   2) user (handler found in UserController
     * @returns {Promise<unknown[]|Array>}
     */
    async getComments(recordId, type) {
        try {
            const url = sprintf('%s/comments/%d?rev=%d', type, recordId, getUnixTime(new Date()));
            const response = await this.api.get(url);
            return _.map(response.data, Comment.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading comments: %s', err.data.message);
            throw (new Error(message));
        }
    }

    /**
     * Saves a comment to the database
     * @param comment The Comment to be saved
     * @param type One of
     *   1) contractor (handler found in OrganizationController)
     *   2) user (handler found in UserController)
     *   3) activity (handler found in ActivityController)
     * @returns {Promise<void>}
     */
    async saveComment(comment, type) {
        try {
            const url = sprintf('%s/comments/%d', type, comment.recordId);
            const response = await this.api.post(url, comment);
            return Comment.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing comment: %s', err.data.message);
            throw (new Error(message));
        }
    }
}

const commentDao = new CommentDao();
export default commentDao;