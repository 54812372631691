
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {SecurityLevel} from '@/model/security_level';
import {mkDate, date} from '@/util/formatters';
import {isAfter, isBefore} from 'date-fns';
import {trimToEmpty} from '@/util/formatters';
import {Phone, NULL_PHONE} from '@/model/phone';


export class Trip {

    static create(trip, asWorkspace) {
        return new Trip(trip, asWorkspace);
    }

    id = null;
    userId = null;
    traveler = null;
    attendeeId = null;
    registrationId = null;
    workshop = null;
    startDate = null;
    endDate = null;
    declinedTravel = null;
    bookedAirfare = null;

    dormStays = [];
    flightRequests = {};
    airfare = [];

    workspace = null;

    constructor(trip, asWorkspace) {
        this.copyFrom(trip);
        this.workspace = asWorkspace ? null : Trip.create(trip, true);
    }

    get fields() {
        return [
            'id',
            'userId',
            'traveler',
            'attendeeId',
            'registrationId',
            'workshop',
            'startDate',
            'endDate',
            'dormStays',
            'flightRequests',
            'airfare',
            'declinedTravel',
            'bookedAirfare'
        ];
    }

    copyFrom(trip) {
        _.each(this.fields, (f) => {
            switch (f) {
                case 'startDate':
                case 'endDate':
                    this[f] = !trip[f] ? null : mkDate(trip[f]);
                    break;

                case 'traveler':
                    this[f] = _.isObject(trip[f]) ? Traveler.create(trip[f]) : null;
                    break;

                case 'dormStays':

                    if (_.isArray(trip[f])) {
                        this[f] = _.map(trip[f], (ds) => {
                            return ds instanceof DormStay ? ds : DormStay.create(ds);
                        });
                    }

                    break;

                case 'flightRequests':
                    if (_.isObject(trip[f])) {

                        const fr = trip[f];

                        if (_.isObject(fr.to)) {
                            this.flightRequests.to = fr.to instanceof FlightRequest ? fr.to : FlightRequest.create(fr.to);
                        }

                        if (_.isObject(fr.from)) {
                            this.flightRequests.from = fr.from instanceof FlightRequest ? fr.from : FlightRequest.create(fr.from);
                        }
                    }

                    break;

                case 'airfare':
                    if (_.isArray(trip[f])) {
                        this[f] = _.map(trip[f], af => af instanceof Airfare ? af : Airfare.create(af))
                    }
                    break;

                default:
                    this[f] = trip[f];
            }
        });
    }

    commit() {
        if (this.workspace instanceof Trip) {
            this.copyFrom(this.workspace);
        }
    }

    rollback() {
        if (this.workspace instanceof Trip) {
            this.workspace.copyFrom(this);
        }
    }

    cloneTemplate() {
        const t = _.reduce(this.fields, (template, f) => {

            switch (f) {
                case 'startDate':
                case 'endDate':
                    template[f] = !this[f] ? null : date(this[f]);
                    break;

                case 'traveler':
                    template[f] = this[f] instanceof Traveler ? this[f].cloneTemplate() : null;
                    break;

                case 'dormStays':
                    template[f] = _.map(this.dormStays, (ds) => ds.cloneTemplate());
                    break;

                case 'flightRequests':

                    template[f] = {};

                    const frs = this.flightRequests || {};

                    if (_.isEmpty(frs)) {
                        break;
                    }

                    if (frs.to instanceof FlightRequest) {
                        template[f].to = frs.to.cloneTemplate();
                    }

                    if (frs.from instanceof FlightRequest) {
                        template[f].from = frs.from.cloneTemplate();
                    }

                    break;

                default:
                    template[f] = this[f];
            }

            return template;

        }, {});

        t.dormStays = _.map(this.dormStays, (ds) => ds.cloneTemplate());

        t.flightRequests = _.reduce(['to', 'from'], (frs, direction) => {

            if (this.flightRequests[direction] instanceof FlightRequest) {
                frs[direction] = this.flightRequests[direction].cloneTemplate();
            }

            return frs;

        }, {});

        return t;
    }

    clone() {
        return Trip.create(this.cloneTemplate());
    }

    equals(other) {

        if (null === other) {
            return false;
        }

        if (!(other instanceof Trip)) {
            return false;
        }

        if (_.isNaN(this.id)) {
            return _.isNaN(other.id)
        }

        return this.id === other.id;
    }

    //Has the trip started or are the dates in the past?
    get isPastTrip() {
        if (!this.startDate) {
            return true;
        }
        else {
            return _.isDate(this.startDate) && isAfter(new Date(), this.startDate);
        }
    }

    //Is there a to and/or from flight request?
    get hasFlightRequest() {
        if (!this.flightRequests || (!this.flightRequests.to && !this.flightRequests.from)) {
            return false;
        }
        else {
            return true;
        }
    }

    //Is there a SUBMITTED to and/or from flight request?
    get hasSubmittedFlightRequest() {
        return this.hasFlightRequest &&
            ((_.isObject(this.flightRequests.to) && this.flightRequests.to.id > 0) ||
                (_.isObject(this.flightRequests.from) && this.flightRequests.from.id > 0));
    }

    //If any airfare is booked, show booking details and do not allow flight request edit
    get hasBookedAirfare() {
        return this.bookedAirfare;
    }
}



export class Traveler {

    static create(traveler, asWorkspace) {
        return new Traveler(traveler, asWorkspace);
    }

    securityLevel = null;

    id = null;
    personId = null;
    attendeeId = null;
    ubcId = null;
    firstName = null;
    middleName = null;
    lastName = null;
    suffix = null;
    organization = null;
    gender = null;
    dateOfBirth = null;
    email = null;
    dietary = null;
    ada = null;
    phone = null;

    nameConfirmed = false;

    workspace = null;

    constructor(traveler, asWorkspace) {
        this.copyFrom(traveler);
        this.workspace = asWorkspace ? null : Traveler.create(traveler, true);
    }


    get fields() {
        return [
            'securityLevel',
            'id',
            'personId',
            'attendeeId',
            'ubcId',
            'firstName',
            'middleName',
            'lastName',
            'suffix',
            'organization',
            'gender',
            'dateOfBirth',
            'email',
            'dietary',
            'ada',
            'phone'
        ];
    }

    get isValid() {
        const id = parseInt(this.id, 10) || 0;
        return id > 0;
    }

    get travelerName() {
        return this.isValid ? sprintf('%s, %s%s',
            this.lastName,
            this.firstName,
            null === this.ubcId ? '' : ' (' + this.ubcId + ')'
        ) : '';
    }

    get fullName() {
        return this.isValid ? sprintf('%s%s %s%s',
            trimToEmpty(this.firstName),
            !!this.middleName ? ' ' + this.middleName : '',
            trimToEmpty(this.lastName),
            !!this.suffix ? ' ' + this.suffix : ''
        ) : '';
    }

    get showContractorInstead() {
        return SecurityLevel.MENTOR.equals(this.securityLevel) || SecurityLevel.TRAINEE.equals(this.securityLevel);
    }

    get confirmed() {
        return this.nameConfirmed && _.isDate(this.dateOfBirth) && _.contains(['M', 'F'], this.gender);
    }

    rollback() {
        if (this.workspace instanceof Traveler) {
            this.workspace.copyFrom(this);
        }
    }

    commit() {
        this.copyFrom(this.workspace);
    }

    copyFrom(traveler) {
        _.each(this.fields, (f) => {

            switch (f) {

                case 'firstName':
                case 'middleName':
                case 'lastName':
                case 'suffix':
                    this[f] = trimToEmpty(traveler[f]);
                    break;

                case 'phone':
                    this.phone = _.isObject(traveler.phone) ? Phone.create(traveler.phone) : NULL_PHONE;
                    break;

                case 'securityLevel':
                    this.securityLevel = SecurityLevel.from(traveler.securityLevel);
                    break;

                case 'dateOfBirth':
                    this.dateOfBirth = mkDate(traveler.dateOfBirth);
                    break;

                default:
                    this[f] = traveler[f]
            }
        });
    }

    clone() {
        return Traveler.create(this.cloneTemplate());
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, f) => {

            switch (f) {
                case 'dateOfBirth':
                    template[f] = this.dateOfBirth instanceof Date ? date(this.dateOfBirth) : null;
                    break;

                case 'phone':
                    if (this.phone instanceof Phone) {
                        template.phone = this.phone.cloneTemplate();
                    }

                    break;

                default:
                    template[f] = this[f];
            }

            return template;

        }, {});
    }
}

export const NULL_TRAVELER = Traveler.create({
    id: NaN
});

export const NULL_TRIP = Trip.create({
    id: NaN,
    traveler: NULL_TRAVELER
});


export class Airport {

    static create(airport) {
        return new Airport(airport);
    }

    id = null;
    stateId = null;
    city = null;
    name = null;
    iata = null;
    icao = null;
    latitutde = null;
    longitude = null;
    hidden = true;
    priority = 0;

    constructor(airport) {
        this.copyFrom(airport);
    }

    get fields() {
        return [
            'id',
            'stateId',
            'city',
            'name',
            'iata',
            'icao',
            'latitude',
            'longitude',
            'hidden',
            'priority'
        ];
    }

    copyFrom(airport) {
        _.each(this.fields, (f) => this[f] = airport[f]);
    }
}

export const NULL_AIRPORT = Airport.create({});

export class DormStay {
    static create(dormStay) {
        return new DormStay(dormStay);
    }

    id = null;
    checkIn = null;
    checkOut = null;
    additionalRequests = null;
    exception = false;

    constructor(dormStay) {
        this.copyFrom(dormStay);
    }

    get fields() {
        return [
            'id',
            'checkIn',
            'checkOut',
            'additionalRequests',
            'exception'
        ];
    }

    copyFrom(dormStay) {
        _.each(this.fields, (f) => {
            switch (f) {
                case 'checkIn':
                case 'checkOut':
                    this[f] = mkDate(dormStay[f]);
                    break;

                default:
                    this[f] = dormStay[f];
            }
        });
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, f) => {

            switch (f) {
                case 'checkIn':
                case 'checkOut':
                    template[f] = date(this[f], 'yyyy-MM-dd');
                    break;

                default:
                    template[f] = this[f];
            }

            return template;

        }, {});
    }

    clone() {
        return DormStay.create(this.cloneTemplate());
    }
}

export class FlightRequest {
    static create(flight) {
        return new FlightRequest(flight);
    }

    id = null;
    direction = null;
    departCity = null;
    departStateId = null;
    departAirportId = null;
    arriveCity = null;
    arriveStateId = null;
    arriveAirportId = null;
    preferredFlightTime = null;
    dateOfFlight = null;
    additionalRequests = null;
    exception = false;
    cancelable = true;

    constructor(flight) {
        this.copyFrom(flight || {});
    }

    get fields() {
        return [
            'id',
            'direction',
            'departCity',
            'departStateId',
            'departAirportId',
            'arriveCity',
            'arriveStateId',
            'arriveAirportId',
            'preferredFlightTime',
            'dateOfFlight',
            'additionalRequests',
            'cancelable',
            'exception'
        ];
    }

    copyFrom(flight) {
        _.each(this.fields, (f) => {

            switch (f) {
                case 'dateOfFlight':
                    this[f] = mkDate(flight[f]);
                    break;

                default:
                    this[f] = flight[f];
            }
        });
    }

    isBefore(other) {
        return !(other instanceof FlightRequest)  || isBefore(this.dateOfFlight, other.dateOfFlight);
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, f) => {
            template[f] = 'dateOfFlight' === f ? date(this[f], 'yyyy-MM-dd') : this[f];
            return template;
        }, {});
    }

    clone() {
        return FlightRequest.create(this.cloneTemplate());
    }

    get hasAirfare() {
        return !_.isEmpty(this.airline) &&!_.isEmpty(this.ticketNumber) && !_.isEmpty(this.confirmationCode) &&
            !_.isEmpty(this.ticketStatus) && _.isNumber(this.originalTicketCost);
    }
}

export class Airfare {
    id;
    tripId;
    attendeeId;
    ticketStatus;
    ticketNumber;
    confirmationCode;
    issueDate;
    expirationDate;
    transferable;
    originalCost;
    balance;
    canceled;
    cancellationReason;
    billable;
    comments;
    airline;
    changeFee;
    cancellationDate;

    static create(af) {
        return new Airfare(af);
    }

    constructor(af) {
        this.copyFrom(af);
    }

    get fields() {
        return [
            'id',
            'tripId',
            'attendeeId',
            'ticketStatus',
            'ticketNumber',
            'confirmationCode',
            'issueDate',
            'expirationDate',
            'transferable',
            'originalCost',
            'balance',
            'canceled',
            'cancellationReason',
            'billable',
            'comments',
            'airline',
            'changeFee',
            'cancellationDate'
        ];
    }

    copyFrom(af) {
        _.each(this.fields, f => {
            switch (f) {
                case 'issueDate':
                case 'expirationDate':
                case 'cancellationDate':
                    this[f] = mkDate(af[f]);
                    break;

                default:
                    this[f] = af[f];
            }
        });
    }
}

export function validateFlightRequests(flightRequests, dormStays, trip) {

    // Rules:
    // - Neither flight can be between the start/end dates of the session
    // - The 'from' flight must follow the 'to' flight
    // - 'to' must be before the earliest dorm room Check-In
    // - 'from' must be after the latest dorm room Check-Out

    const toDate = ((flightRequests || {}).to || {}).dateOfFlight;
    const fromDate = ((flightRequests || {}).from || {}).dateOfFlight;
    const toSelected = _.isDate(toDate);
    const fromSelected = _.isDate(fromDate);
    const startDate = trip.startDate;
    const endDate = trip.endDate;

    const earliestCheckIn = _.chain(dormStays)
        .sortBy((stay) => stay.checkIn)
        .pluck('checkIn')
        .first()
        .value();

    const latestCheckOut = _.chain(dormStays)
        .sortBy((stay) => stay.checkOut)
        .pluck('checkOut')
        .last()
        .value();

    const errors = [];

    if (toSelected && isAfter(toDate, startDate)) {
        errors.push(sprintf('Flight to the ITC is after the start date of the %s', trip.workshop));
    }

    if (fromSelected && isBefore(fromDate, endDate)) {
        errors.push(sprintf('Flight from the ITC is before the end date of %s', trip.workshop));
    }

    if (toSelected && fromSelected && isAfter(toDate, fromDate)) {
        errors.push('Flight to the ITC comes after the flight from the ITC');
    }

    if (toSelected && isAfter(toDate, earliestCheckIn)) {
        errors.push('Flight to the ITC follows Dorm Check-In date.');
    }

    if (fromSelected && isBefore(fromDate, latestCheckOut)) {
        errors.push('Flight from the ITC is before the Dorm Check-Out date.');
    }

    return errors;
}