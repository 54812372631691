import _ from 'underscore';
import notificationDao from "@/dao/notification_dao";
import store from '@/store/store';

export const NotificationStore = {
    namespaced: true,

    state: {
        supportUsers: [],
        //Note: all filtering and sorting rules are applied in back end via database query
        //Reactivity is enforced by re-querying, no need to accommodate in front end
        releaseNotes: [],
        userNotifications: []
    },

    getters: {
        supportUsers(state) {
            return state.supportUsers;
        },

        releaseNotes(state) {
            return state.releaseNotes;
        },

        userNotifications(state) {
            return state.userNotifications;
        }
    },

    mutations: {
        setSupportUsers: (state, supportUsers) => {
            state.supportUsers = supportUsers;
        },

        setReleaseNotes: (state, releaseNotes) => {
            state.releaseNotes = releaseNotes;
        },

        setUserNotifications: (state, userNotifications) => {
            state.userNotifications = userNotifications;
        }
    },

    actions: {
        loadSupportUsers: async ({commit}) => {
            const supportUsers = await notificationDao.getSupportUsers();
            commit('setSupportUsers', supportUsers);
        },

        loadReleaseNotes: async ({commit}) => {
            const releaseNotes = await notificationDao.getReleaseNotes();
            commit('setReleaseNotes', releaseNotes);
        },

        loadUserNotifications: async ({commit}) => {
            const userNotifications = await notificationDao.getUserNotifications();
            commit('setUserNotifications', userNotifications);
        },

        processReleaseNote: async ({commit}, note) => {
            const savedNote = await notificationDao.processReleaseNote(note);
            // console.log(savedNote);
            //Reload for reactivity
            await store.dispatch('notification/loadReleaseNotes');
        },

        processUserNotification: async ({commit}, notification) => {
            const savedNotification = await notificationDao.processUserNotification(notification);
            // console.log(savedNotification);
            //Reload for reactivity
            await store.dispatch('notification/loadUserNotifications');
        }
    }
};

export default NotificationStore;