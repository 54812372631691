import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import {authenticationCredentials, UserFactory} from '@/model/user';
import _ from 'underscore';

class UserSessionDao extends Dao {
    async getProfile() {
        try {
            const response = await this.api.get(sprintf('user/profile?rev=%d', this.getRev()));
            return UserFactory.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading profile: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async login(username, password) {
        try {
            const salt = await this.getSalt(username);
            const creds = authenticationCredentials(username, password, salt);
            const response = await this.api.post('login', creds);
            return UserFactory.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = _.isEqual(err.status, 401) ? 
                'Invalid Username or Password' : !_.isUndefined(err.message) ? 
                    err.message : !_.isUndefined(err.data.message) ? 
                        err.data.message : 'Unknown';
            throw (new Error(message));
        }
    }

    async logout() {
        try {
            await this.api.post('logout');
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error logging out: %s', err.data.message);
            throw (new Error(message));
        }
    }

    /**
     * Handler in UserSessionController
     */
    async changePassword(oldCreds, newCreds) {
        try {
            const payload = {
                old: oldCreds,
                new: newCreds
            };
            await this.api.post('password', payload);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error changing password: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async resetPassword(username) {
        try {
            const payload = {
                username: username
            };
            await this.api.post('password/reset', payload);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error resetting password: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getSalt(username) {
        try {
            let baseUrl = 'users/salt?rev=%d';
            if (!!username) {
                baseUrl += '&username=%s';
            }
            const args = [baseUrl, this.getRev()];
            if (!!username) {
                args.push(username);
            }
            const url = sprintf.apply(sprintf, args);
            const response = await this.api.get(url);
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading salt: %s', err.data.message);
            throw (new Error(message));
            //TODO - generate 16-bytes worth of salt here or return '0000000000000000';
        }
    }
}

const userSessionDao = new UserSessionDao();
export default userSessionDao;