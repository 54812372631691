import Vue from 'vue';
import store from '@/store/store';
import Router from 'vue-router';
import {errorToastOptions} from "./util/formatters";
import {trackPage} from "@/util/analytics";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        name: 'root',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/public/Home.vue')
    }, {
        path: '/home',
        name: 'home',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/public/Home.vue')
    }, {
        path: '/training',
        name: 'training',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "training" */ '@/views/public/Training.vue')
    }, {
        path: '/enrollment',
        name: 'enrollment',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "enrollment" */ '@/views/public/enrollment/Enrollment.vue')
    },{
        path: '/faq',
        name: 'faq',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "faq" */ '@/views/public/FAQ.vue')
    }, {
        path: '/getting-started',
        name: 'getting-started',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "getting-started" */ '@/views/public/GettingStarted.vue')
    }, {
        path: '/contact',
        name: 'contact',
        meta: {
            public: true
        },
        component: () => import(/* webpackChunkName: "contact" */ '@/views/public/Contact.vue')
    }, {
        path: '/admin',
        meta: {
            public: false
        },
        component: () => import(/* webpackChunkName: "administration" */ '@/views/private/Administration.vue'),
        children: [{
            path: '',
            redirect: {name: 'adminMain'},
            component: () => import(/* webpackChunkName: "main" */ '@/views/private/main/Main.vue'),
            children: [{
                path: 'main',
                name: 'adminMain',
                component: () => import(/* webpackChunkName: "adminMain" */ '@/views/private/main/AdministratorMain.vue')
            }]
        }, {
            path: 'users',
            name: 'adminUsers',
            component: () => import(/* webpackChunkName: "adminUsers" */ '@/views/private/users/Users.vue')
        }, {
            path: 'applications',
            name: 'adminApplications',
            component: () => import(/* webpackChunkName: "adminApplications" */ '@/views/private/applications/Applications.vue')
        }, {
            name: 'profileView',
            path: 'profile/:id',
            component: () => import(/* webpackChunkName: "userProfile" */ '@/views/private/users/Profile.vue')
        }, {
            path: 'instructor_sessions',
            component: () => import(/* webpackChunkName: "instructorSessions" */ '@/views/private/instructor/InstructorSessions.vue')
        }, {
            path: 'tracks',
            component: () => import(/* webpackChunkName: "tracks" */ '@/views/private/tracks/Tracks.vue')
        }, {
            name: 'documents',
            path: 'download-documents',
            component: () => import(/* webpackChunkName: "documents" */ '@/views/private/documents/Documents.vue')
        }, {
            path: 'reports',
            name: 'reports',
            meta: { isDetail: false },
            component: () => import(/* webpackChunkName: "reports" */ '@/views/private/reports/Reports.vue'),
            children: [{
                path: 'mentor-instructor-directory',
                name: 'mentorInstructorDirectory',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "mentorInstructorDirectory" */ '@/views/private/reports/MentorInstructorDirectory.vue')
            }, {
                path: 'audit-usage-report',
                name: 'auditUsageReport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "auditUsageReport" */ '@/views/private/reports/AuditUsageReport.vue')
            }, {
                path: 'audit-contractor-history',
                name: 'auditContractorHistory',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "auditContractorHistory" */ '@/views/private/reports/AuditContractorHistory.vue')
            }, {
                path: 'audit-ojt-ma-history',
                name: 'auditOjtMaHistory',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "auditOjtMaHistory" */ '@/views/private/reports/AuditOjtMaHistory.vue')
            }, {
                path: 'audit-user-history',
                name: 'auditUserHistory',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "auditUserHistory" */ '@/views/private/reports/AuditUserHistory.vue')
            }, {
                path: 'vaccine-registration-exception-history',
                name: 'vaccineRegistrationExceptionHistory',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "auditUserHistory" */ '@/views/private/reports/VaccineRegistrationExceptionHistory.vue')
            }, {
                path: 'contractor-export',
                name: 'contractorExport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "contractorExport" */ '@/views/private/reports/ContractorExport.vue')
            }, {
                path: 'user-export',
                name: 'userExport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "userExport" */ '@/views/private/reports/UserExport.vue')
            }, {
                path: 'application-information',
                name: 'applicationInformation',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "applicationInformation" */ '@/views/private/reports/ApplicationInformation.vue')
            }, {
                path: 'application-statistics',
                name: 'applicationStatistics',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "applicationStatistics" */ '@/views/private/reports/ApplicationStatistics.vue')
            }, {
                path: 'graduation-export',
                name: 'graduationExport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "graduationExport" */ '@/views/private/reports/GraduationExport.vue')
            }, {
                path: 'graduate-roster',
                name: 'graduateRoster',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "graduationRoster" */ '@/views/private/reports/GraduateRoster.vue')
            }, {
                path: 'graduation-certificates',
                name: 'graduationCertificates',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "graduationCertificates" */ '@/views/private/reports/GraduationCertificates.vue')
            }, {
                path: 'ojt-ma-export',
                name: 'ojtMaExport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "ojtMaExport" */ '@/views/private/reports/OjtMaExport.vue')
            },{
                path: 'track-export',
                name: 'trackExport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "TrackExport" */ '@/views/private/reports/TrackExport.vue')
            }, {
                path: 'custom-ojt-ma-export',
                name: 'customOjtMaExport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "customOjtMaExport" */ '@/views/private/reports/CustomOjtMaExport.vue')
            }, {
                path: 'trainee-statistics',
                name: 'traineeStatistics',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "traineeStatistics" */ '@/views/private/reports/TraineeStatistics.vue')
            }, {
                path: 'trainee-status',
                name: 'traineeStatus',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "traineeStatus" */ '@/views/private/reports/TraineeStatus.vue')
            }, {
                path: 'trainee-transcript',
                name: 'traineeTranscript',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "traineeTranscript" */ '@/views/private/reports/TraineeTranscript.vue')
            }, {
                path: 'trainee-planning-calendar',
                name: 'traineePlanningCalendar',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "traineePlanningCalendar" */ '@/views/private/reports/TraineePlanningCalendar.vue')
            }, {
                path: 'missing-travel',
                name: 'missingTravel',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "missingTravel" */ '@/views/private/reports/travel/MissingTravel.vue')
            }, {
                path: 'travel-requests',
                name: 'travelRequests',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "travelRequests" */ '@/views/private/reports/travel/TravelRequests.vue')
            }, {
                path: 'booked-travel',
                name: 'bookedTravel',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "bookedTravel" */ '@/views/private/reports/travel/BookedTravel.vue')
            }, {
                path: 'arrival-departure',
                name: 'arrivalDeparture',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "arrivalDeparture" */ '@/views/private/reports/travel/ArrivalDeparture.vue')
            }, {
                path: 'workshop-capacity',
                name: 'workshopCapacityReport',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "workshopCapacity" */ '@/views/private/reports/WorkshopCapacityReport.vue')
            }, {
                path: 'roll-sheets',
                name: 'rollSheets',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "rollSheets" */ '@/views/private/reports/RollSheets.vue')
            }, {
                path: 'workshop-roster',
                name: 'workshopRoster',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "workshopRoster" */ '@/views/private/reports/WorkshopRoster.vue')
            }, {
                path: 'workshop-setup',
                name: 'workshopSetup',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "workshopSetup" */ '@/views/private/reports/WorkshopSetup.vue')
            }, {
                path: 'workshop-calendar',
                name: 'workshopCalendar',
                meta: { isDetail: true },
                component: () => import(/* webpackChunkName: "workshopCalendar" */ '@/views/private/reports/WorkshopCalendar.vue')
            }]
        }, {
            path: 'email',
            component: () => import(/* webpackChunkName: "email" */ '@/views/private/email/Email.vue')
        }, {
            path: 'activities',
            name: 'activities',
            component: () => import(/* webpackChunkName: "activities" */ '@/views/private/activities/Activities.vue'),
            children: [{
                path: 'ojt',
                name: 'ojts',
                component: () => import(/* webpackChunkName: "ojt" */ '@/views/private/activities/OJTPage.vue')
            }, {
                path: 'ma',
                name: 'mas',
                component: () => import(/* webpackChunkName: "ma" */ '@/views/private/activities/MAPage.vue')
            }, {
                path: 'custom',
                name: 'customActivities',
                component: () => import(/* webpackChunkName: "customActivities" */ '@/views/private/activities/custom/CustomActivities.vue')
            }]
        }, {
            path: 'schedule/:userId',
            name: 'schedule',
            props: true,
            component: () => import(/* webpackChunkName: "schedulePage" */ '@/views/private/schedule/SchedulePage.vue')
        }, {
            path: 'travel',
            name: 'travel',
            component: () => import(/* webpackChunkName: "travel" */ '@/views/private/travel/Travel.vue')
        }, {
            path: 'travel/:attendeeId/:registrationId',
            name: 'selectedTravel',
            props: true,
            component: () => import(/* webpackChunkName: "travel" */ '@/views/private/travel/Travel.vue')
        }, {
            path: 'contractors',
            name: 'contractors',
            component: () => import(/* webpackChunkName: "contractors" */ '@/views/private/contractors/Contractors.vue')
        }, {
            path: 'preferences',
            name: 'preferences',
            component: () => import(/* webpackChunkName: "preferences" */ '@/views/private/preferences/Preferences.vue')
        }, {
            path: 'help',
            name: 'help',
            component: () => import(/* webpackChunkName: "help" */ '@/views/private/help/HelpView'),
            children: [{
                path: 'release',
                name: 'releaseNotes',
                component: () => import(/* webpackChunkName: "releaseNotes" */ '@/views/private/notifications/ReleaseNotes')
            }, {
                path: 'notification',
                name: 'userNotifications',
                component: () => import(/* webpackChunkName: "releaseNotes" */ '@/views/private/notifications/UserNotifications')
            }, {
                path: 'notices',
                name: 'notices',
                component: () => import(/* webpackChunkName: "notices" */ '@/views/private/help/Notices')
            }, {
                path: 'training-videos',
                name: 'trainingVideos',
                component: () => import(/* webpackChunkName: "trainingVideos" */ '@/views/private/help/TrainingVideos')
            }, {
                path: 'feedback',
                name: 'feedback',
                component: () => import(/* webpackChunkName: "feedback" */ '@/views/private/help/SubmitFeedback')
            }]
        }]
    }],

    scrollBehavior: () => {
        return {x: 0, y: 0};
    }
});

router.beforeEach(async (to, from, next) => {
    if (true === to.meta.public) {
        trackPage(to.name, to.fullPath);
        next();
    }
    else {
        if (store.getters['userSession/isLoggedIn']) {
            trackPage(to.name, to.fullPath);
            next();
        }
        else {
            try {
                await store.dispatch('userSession/loadProfile');
            }
            catch (error) {
                const vm = new Vue();
                vm.$bvToast.toast(error.message, errorToastOptions);
            }
            if (store.getters['userSession/isLoggedIn']) {
                trackPage(to.name, to.fullPath);
                next();
            }
            else {
                router.push('/');
            }
        }
    }
});


export default router;
