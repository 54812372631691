import api from '@/dao/api';
import {getUnixTime} from 'date-fns';

export default class Dao {
    api = api();

    getRev() {
        return getUnixTime(new Date());
    }

    //Encode File object as Base64 String
    encodeFile(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = function (event) {
                resolve(fileReader.result);
            };
            fileReader.onerror = function (error) {
                reject(new Error("FileReader failed to encode file"));
            };
            fileReader.readAsDataURL(file);
        });
    }
}
