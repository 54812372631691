<template>
    <div>
        <b-navbar-nav class="top-menu" v-if="isLoggedIn">
            <b-nav-item-dropdown :text="'Logged In As: ' + loggedInAs" right>
                <b-dropdown-item>
                    <b-link @click="$bvModal.show('change-password-modal')">Change Password</b-link>
                </b-dropdown-item>
                <b-dropdown-item>
                    <b-link @click="doLogout">Log Out</b-link>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="top-menu" v-else>
            <b-nav-item v-b-modal.login-modal>Log In</b-nav-item>
        </b-navbar-nav>
        <b-modal id="login-modal" :title="forgotPassword ? 'Reset Password' : 'Log In'" centered size="sm" button-size="sm">
            <div @keyup.enter="doLoginOrReset">
                <b-form-group label="Username:"
                              label-for="login-username">
                    <b-input id="login-username" size="sm" placeholder="Username" v-model="username" :state="forgotUsernameState"/>
                </b-form-group>
                <b-form-group label="Password:"
                              label-for="login-password"
                              v-if="!forgotPassword">
                    <b-input-group>
                        <b-input id="login-password" size="sm" placeholder="Password"
                                 :type="passwordVisible ? 'text' : 'password'"
                                 v-model="password"/>
                        <b-input-group-addon>
                            <b-button size="sm" @click="passwordVisible = !passwordVisible">
                                <font-awesome-icon :icon="['far', passwordVisible ? 'eye-slash' : 'eye']"></font-awesome-icon>
                            </b-button>
                        </b-input-group-addon>
                    </b-input-group>
                </b-form-group>
            </div>
            <template v-slot:modal-footer>
                <div class="w-100 clearfix">
                    <b-link @click="forgotPassword=true" v-if="!forgotPassword">forgot password?</b-link>
                    <b-button-group size="sm" class="float-right" v-if="forgotPassword">
                        <b-button variant="success" @click="resetPassword" :disabled="!forgotUsernameState">Reset Password</b-button>
                        <b-button variant="secondary" @click="forgotPassword=false">Cancel</b-button>
                    </b-button-group>
                    <b-button-group size="sm" class="float-right" v-else>
                        <b-button variant="success" @click="doLogin">Log In</b-button>
                        <b-button variant="secondary" @click="$bvModal.hide('login-modal')">Cancel</b-button>
                    </b-button-group>
                </div>
            </template>
        </b-modal>
        <b-modal id="change-password-modal">
            <template v-slot:modal-title>
                <template v-if="mustChangePassword">You must change your password.</template>
                <template v-else>Change Password</template>
            </template>
            <div @keyup.enter="changePassword">
                <b-row>
                    <b-col>
                        <b-form-group :label="(mustChangePassword ? 'Temporary' : 'Old') + ' Password:'" label-for="old-password">
                            <b-input-group>
                                <b-input size="sm" id="old-password" :placeholder="(mustChangePassword ? 'Temporary' : 'Old') + ' Password'" tabindex="1"
                                         :type="oldPasswordVisible ? 'text' : 'password'"
                                         v-model="oldPassword"/>
                                <b-input-group-addon>
                                    <b-button size="sm" @click="oldPasswordVisible = !oldPasswordVisible">
                                        <font-awesome-icon :icon="['far', oldPasswordVisible ? 'eye-slash' : 'eye']"></font-awesome-icon>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="New Password:"
                                      label-for="new-password"
                                      :state="newPasswordState">
                            <template v-slot:invalid-feedback>
                                <template v-if="!passwordsMatch">
                                    Passwords do not match
                                    <br />
                                </template>
                                <template v-if="!newPasswordRules">
                                    New Password must be at least {{ minPasswordLength }} characters long and meet these
                                    <b-link id="password-rules">rules</b-link>.
                                    <b-popover target="password-rules" triggers="hover" placement="bottom">
                                        <template v-slot:title>
                                            Valid passwords must contain at least 3 of the following 4 characteristics:
                                        </template>
                                        <ol>
                                            <li>
                                                At least one uppercase letter:<br/>
                                                <b-badge variant="info">A-Z</b-badge>
                                            </li>
                                            <li>
                                                At least one lowercase letter:<br/>
                                                <b-badge variant="info">a-z</b-badge>
                                            </li>
                                            <li>
                                                At least one digit:<br/>
                                                <b-badge variant="info">0-9</b-badge>
                                            </li>
                                            <li>
                                                At least one special character:<br/>
                                                <b-badge variant="info">~ ! @ # $ % ^ &amp; * _ - + = ` | \ ( ) { } [ ] : ; " \ ' < > , . ? /</b-badge>
                                            </li>
                                        </ol>
                                    </b-popover>
                                </template>
                            </template>
                            <b-input-group>
                                <b-input size="sm" id="new-password" placeholder="New Password" tabindex="2"
                                         :type="newPasswordVisible ? 'text' : 'password'"
                                         :state="newPasswordState"
                                         v-model="newPassword"/>
                                <b-input-group-addon>
                                    <b-button size="sm" @click="newPasswordVisible = !newPasswordVisible">
                                        <font-awesome-icon :icon="['far', newPasswordVisible ? 'eye-slash' : 'eye']"></font-awesome-icon>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Repeat New Password:"
                                      label-for="repeat-new-password"
                                      :state="repeatNewPasswordState">
                            <template v-slot:invalid-feedback>
                                <template v-if="!passwordsMatch">
                                    Passwords do not match
                                    <br />
                                </template>
                                <template v-if="!repeatPasswordRules">
                                    New Password must be at least {{ minPasswordLength }} characters long and meet these
                                    <b-link id="password-rules-repeat">rules</b-link>.
                                    <b-popover target="password-rules-repeat" triggers="hover" placement="bottom">
                                        <template v-slot:title>
                                            Valid passwords must contain at least 3 of the following 4 characteristics:
                                        </template>
                                        <ol>
                                            <li>
                                                At least one uppercase letter:<br/>
                                                <b-badge variant="info">A-Z</b-badge>
                                            </li>
                                            <li>
                                                At least one lowercase letter:<br/>
                                                <b-badge variant="info">a-z</b-badge>
                                            </li>
                                            <li>
                                                At least one digit:<br/>
                                                <b-badge variant="info">0-9</b-badge>
                                            </li>
                                            <li>
                                                At least one special character:<br/>
                                                <b-badge variant="info">~ ! @ # $ % ^ &amp; * _ - + = ` | \ ( ) { } [ ] : ; " \ ' < > , . ? /</b-badge>
                                            </li>
                                        </ol>
                                    </b-popover>
                                </template>
                            </template>
                            <b-input-group>
                                <b-input size="sm" id="repeat-new-password" placeholder="Repeat New Password" tabindex="3"
                                         :type="newPasswordVisible ? 'text' : 'password'"
                                         :state="repeatNewPasswordState"
                                         v-model="repeatNewPassword"/>
                                <b-input-group-addon>
                                    <b-button size="sm" @click="newPasswordVisible = !newPasswordVisible">
                                        <font-awesome-icon :icon="['far', newPasswordVisible ? 'eye-slash' : 'eye']"></font-awesome-icon>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template v-slot:modal-footer>
                <b-button-group size="sm">
                    <b-button variant="primary" @click="changePassword" :disabled="!newPasswordState || !repeatNewPasswordState" tabindex="4">Change Password</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('change-password-modal')" tabindex="5" v-if="!mustChangePassword">Cancel</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </div>
</template>
<script>
import {Component, Prop, Vue} from 'vue-property-decorator';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {NULL_USER} from '@/model/user';
import {SecurityLevel} from '@/model/security_level';
import userSessionDao from '@/dao/user_session_dao';
import {errorModalOptions, errorToastOptions, trimToEmpty, trimToNull} from '@/util/formatters';
import {authenticationCredentials} from '@/model/user';

// TODO: Load this from config, perhaps?
const MIN_PASSWORD_LENGTH = 8;

@Component
export default class PrivateMenu extends Vue {

    @Prop({type: Boolean, default: false}) isLoggedIn;

    username = null;
    password = null;
    passwordVisible = false;

    oldPassword = null;
    newPassword = null;
    repeatNewPassword = null;
    newSalt = null;
    minPasswordLength = MIN_PASSWORD_LENGTH;
    oldPasswordVisible = false;
    newPasswordVisible = false;

    forgotPassword = false;

    get currentUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get loggedInAs() {
        const user = this.currentUser;
        return null === user ? '' : sprintf('%s, %s (%s - %s)',
            user.lastName || '',
            user.firstName || '',
            user.username || '',
            user.securityLevel.toString()
        );
    }

    get mustChangePassword() {
        return true === (this.currentUser || {}).mustChangePassword;
    }

    get newPasswordState() {
        return this.passwordsMatch && this.newPasswordRules;
    }

    get repeatNewPasswordState() {
        return this.passwordsMatch && this.repeatPasswordRules;
    }

    get passwordsMatch() {
        return !!this.newPassword && !!this.repeatNewPassword && _.isEqual(this.newPassword, this.repeatNewPassword);
    }

    get newPasswordRules() {
        return this.checkPasswordRules(this.newPassword);
    }

    get repeatPasswordRules() {
        return this.checkPasswordRules(this.repeatNewPassword);
    }

    checkPasswordRules(password) {
        const rules = [
            /[a-z]/,
            /[A-Z]/,
            /[0-9]/,
            /[~!@#$%^&*_\\\-+=`|(){}\[\]:;"'<>,.?\/]/
        ];
        const pw = trimToEmpty(password);
        return pw.length >= MIN_PASSWORD_LENGTH && 3 <= _.reduce(rules, (c, rule) => c += rule.test(pw) ? 1 : 0, 0);
    }

    // get changeRepeatNewPasswordState() {
    //     return this.changeNewPasswordState && this.newPassword === this.repeatNewPassword;
    // }

    get forgotUsernameState() {
        return this.forgotPassword ? !_.isEmpty(this.username) : null;
    }

    async getNewSalt() {
        try {
            return await userSessionDao.getSalt();
        }
        catch (err) {
            this.$bvToast.toast(err.message, errorToastOptions);
        }
    }

    async changePassword() {
        if (!this.newPasswordState || !this.repeatNewPasswordState) {
            return this.$bvModal.msgBoxOk('Please correct the highlighted errors.');
        }
        const user = this.currentUser;
        try {
            const oldSalt = await userSessionDao.getSalt(user.username);
            const oldCreds = authenticationCredentials(user.username, this.oldPassword, oldSalt, true);
            const newCreds = authenticationCredentials(user.username, this.newPassword, this.newSalt, true);
            await userSessionDao.changePassword(oldCreds, newCreds);
            this.$bvModal.msgBoxOk('Your password has been changed!', {
                title: 'Success'
            }).then(() => {
                this.currentUser.mustChangePassword = false;
                this.$bvModal.hide('change-password-modal');
            });
        }
        catch (err) {
            await this.$bvModal.msgBoxOk(err.message, errorModalOptions);
        }
    }

    async resetPassword() {
        try {
            const username = trimToNull(this.username);
            if (_.isEmpty(username)) {
                return;
            }
            await userSessionDao.resetPassword(username);
            this.$bvModal.msgBoxOk('An email has been sent with instructions to change your password!  Please check your inbox.')
                .then(() => {
                    this.forgotPassword = false;
                });
        }
        catch (err) {
            await this.$bvModal.msgBoxOk(err.message, errorModalOptions);
        }
    }

    async mounted() {

        const MODAL_ID = 'change-password-modal';

        this.$root.$on('bv::modal::show', async (ev, registrationModalId) => {

            switch (registrationModalId) {
                case MODAL_ID:
                    this.oldPassword = null;
                    this.newPassword = null;
                    this.repeatNewPassword =null;
                    this.newSalt = await this.getNewSalt();
                    break;

                case 'login-modal':
                    this.username = null;
                    this.password = null;
                    this.forgotPassword = false;
                    break;
            }
        });

        this.$root.$on('bv::modal::hide', async (ev, registrationModalId) => {
            if (MODAL_ID === registrationModalId && this.mustChangePassword) {
                ev.preventDefault();
            }
        });
    }

    updated() {
        if (this.mustChangePassword) {
            this.$bvModal.show('change-password-modal');
        }
    }

    async doLoginOrReset() {
        await this[this.forgotPassword ? 'resetPassword' : 'doLogin']();
    }

    async doLogin() {
        try {
            const sessionUser = await userSessionDao.login(this.username, this.password);
            this.$store.commit('userSession/setUser', sessionUser);
            this.$bvModal.hide('login-modal');
            this.clearCredentials();
            this.$router.push('/admin');
        }
        catch (err) {
            await this.$bvModal.msgBoxOk(err.message, errorModalOptions);
        }
    }

    async doLogout() {
        try {
            await userSessionDao.logout();
            this.redirectLogout();
        }
        catch (err) {
            this.$bvToast.toast(err.message, errorToastOptions);
        }
    }

    redirectLogout() {
        this.clearCredentials();
        this.$store.commit('userSession/setUser', NULL_USER);
        this.$router.push({name: 'root'});
        document.location.reload();
    }

    clearCredentials() {
        this.username = null;
        this.password = null;
        this.selectedUserType = SecurityLevel.INVALID;
    }
}
</script>
<style scoped>

    div.login-form label {
        margin-bottom: 0 !important;
    }

</style>
