import {sprintf} from 'sprintf-js';
import {Member, NULL_MEMBER} from "@/model/member";
import _ from 'underscore';
import Dao from './dao';

class MemberDao extends Dao {
    async verifyMember(verificationRequest) {
        try {
            const response = await this.api.post('/member/verify', verificationRequest);
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error verifying member: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getClassifications() {
        try {
            const response = await this.api.get(sprintf('/classifications?rev=%d', this.getRev()));
            return response.data || [];
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading classifications: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async lookupMentor(ubcId) {
        return await this.lookupMember(ubcId, false);  //Mentor not restricted to journeyman
    }

    async lookupMember(ubcId, journeymanOnly = true) {
        try {
            const url = sprintf('/member/%s?journeymanOnly=%s&rev=%d', ubcId, journeymanOnly, this.getRev());
            const response = await this.api.get(url);
            // console.log(response);
            return _.isNull(response.data) ? NULL_MEMBER.clone() : Member.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading member: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async lookupByUsername(username) {
        try {
            const response = await this.api.get(sprintf('/member/username/%s?rev=%d', username, this.getRev()));
            return _.isNull(response.data) ? NULL_MEMBER.clone() : Member.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading member: %s', error.data.message);
            throw (new Error(message));
        }
    }

    //Mentors must be members for enrollment model
    async getMentors() {
        try {
            const response = await this.api.get(sprintf('/member/mentors?rev=%d', this.getRev()));
            const mentors = _.map(response.data, Member.create);
            return mentors;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading mentors: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const memberDao = new MemberDao();
export default memberDao;