import {sprintf} from 'sprintf-js';
import store from '@/store/store';
import _ from 'underscore';
import {NULL_INSTRUCTOR_SESSION} from "@/model/session";
import instructorDao from "@/dao/instructor_dao";
import userDao from "@/dao/user_dao";
import {NULL_USER} from "@/model/user";
import {RegistrationRequest} from "@/model/registration";
import {NEW_INSTRUCTOR_SESSION_REGISTRATION} from "../model/registration";

export const InstructorStore = {
    namespaced: true,

    state: {
        instructor: NULL_USER,
        instructors: [],
        instructorSessionTemplate: NULL_INSTRUCTOR_SESSION,
        instructorSessions: []
    },

    getters: {
        instructor(state) {
            return state.instructor;
        },

        instructors(state) {
            return state.instructors;
        },

        instructorSchedule: (state) => (instructor) => {
            if (_.isEmpty(instructor.registrations)) {
                return [];
            }
            const schedule = _.chain(instructor.registrations)
                .map(registration => {
                    const instructorSession = _.find(state.instructorSessions, session => {
                        return session.workshopInstanceId === registration.workshopInstanceId;
                    });
                    return new RegistrationRequest(instructor, instructorSession, registration);
                })
                .sortBy(request => {
                    return _.isEmpty(request.session) ? new Date() : request.session.dates[0];
                })
                .value();
            return schedule;
        },

        instructorSessionTemplate(state) {
            return state.instructorSessionTemplate;
        },

        instructorSessions(state) {
            return state.instructorSessions;
        },

        registeredInstructors: (state) => (workshopInstanceId) => {
            const instructorSession = _.find(state.instructorSessions, session => {
                return session.workshopInstanceId === workshopInstanceId;
            });
            const registeredInstructors = _.chain(state.instructors)
                .filter(instructor => {
                    const registeredWorkshopIds = _.chain(instructor.registrations)
                        .filter(registration => {
                            return !registration.canceled;
                        })
                        .map(registration => {
                            return registration.workshopInstanceId;
                        })
                        .value();
                    return registeredWorkshopIds.includes(workshopInstanceId);
                })
                .sortBy(instructor => {
                    return instructor.fullname;
                })
                .map(instructor => {
                    const registration = _.find(instructor.registrations, registration => {
                        return registration.workshopInstanceId === workshopInstanceId;
                    });
                    return new RegistrationRequest(instructor, instructorSession, registration);
                })
                .value();
            return registeredInstructors;
        },

        registrableInstructors: (state) => (workshopInstanceId) => {
            const instructorSession = _.find(state.instructorSessions, session => {
                return session.workshopInstanceId === workshopInstanceId;
            });
            const registrableInstructors = _.chain(state.instructors)
                .filter(instructor => {
                    const registeredWorkshopIds = _.chain(instructor.registrations)
                        .filter(registration => {
                            return !registration.canceled;
                        })
                        .map(registration => {
                            return registration.workshopInstanceId;
                        })
                        .value();
                    return !registeredWorkshopIds.includes(workshopInstanceId);
                })
                .sortBy(instructor => {
                    return instructor.fullname;
                })
                .map(instructor => {
                    return new RegistrationRequest(instructor, instructorSession, NEW_INSTRUCTOR_SESSION_REGISTRATION);
                })
                .value();
            return registrableInstructors;
        },

        registrableInstructorSessions: (state) => (instructor) => {
            //Determine which sessions are registrable regardless of user
            const admin = store.getters['userSession/isAnAdmin'];
            const registrableSessions = _.chain(state.instructorSessions)
                .filter(session => {
                    if (admin) {
                        return !session.canceled && !session.hasEnded;
                    }
                    else {
                        //Non-admins can register instructors for admin-only registration or full sessions
                        return !session.canceled && !session.hasEnded && !session.adminRegistration &&
                            session.availableSeats > 0;
                    }
                })
                .filter(session => {
                    return !_.isEmpty(session.dates) && !_.isNull(session.dates[0]) && !_.isNull(session.dates[1]);
                })
                .sortBy(session => {
                    return session.dates[0];
                })
                .map(session => {
                    return new RegistrationRequest(instructor, session, NEW_INSTRUCTOR_SESSION_REGISTRATION);
                })
                .value();
            if (!_.isEmpty(instructor.registrations) && !_.isUndefined(instructor.registrations) &&
                !_.isNull(instructor.registrations)) {
                //Filter out sessions with existing registrations
                const registeredWorkshopIds = _.chain(instructor.registrations)
                    .filter(registration => {
                        return !registration.canceled;
                    })
                    .map(registration => {
                        return registration.workshopInstanceId;
                    })
                    .value();
                const registrableSessionsForUser = _.filter(registrableSessions, session => {
                    return !registeredWorkshopIds.includes(session.session.workshopInstanceId);
                });
                return registrableSessionsForUser;
            }
            else {
                return registrableSessions;
            }
        },
    },

    mutations: {
        setInstructor: (state, instructor) => {
            state.instructor = instructor;
        },

        setInstructors: (state, instructors) => {
            state.instructors = instructors;
        },

        setInstructorSessionTemplate: (state, template) => {
            state.instructorSessionTemplate = template;
        },

        setInstructorSessions: (state, sessions) => {
            state.instructorSessions = sessions;
        },

        addInstructorSession: (state, session) => {
            if (session.workshopInstanceId === 0) {
                console.log('ERROR - processed instructor session did not return with a workshop instance ID!');
                return;
            }
            //Find index
            const index = _.findIndex(state.instructorSessions, (instructorSession) => {
                return instructorSession.workshopInstanceId === session.workshopInstanceId;
            });
            //Remove if session already exists
            if (index >= 0) {
                state.instructorSessions.splice(index, 1);
            }
            //Add session
            state.instructorSessions.push(session);
        },

        removeRegistration: (state, registrationId) => {
            // Holy smokes this is hacky....
            // This is a work around until the vuex store surrounding users and their registrations
            // can be entirely reworked.
            const insIdx = _.findIndex(state.instructors, ins => _.any(ins.registrations, r => r.id === registrationId));

            if (0 > insIdx) {
                return;
            }

            const [instructor] = state.instructors.splice(insIdx, 1);
            const regIdx = _.findIndex(instructor.registrations, r => r.id === registrationId);

            instructor.registrations.splice(regIdx, 1);
            state.instructors.push(instructor);
        },

        removeInstructorSession: (state, workshopInstanceId) => {
            //Find index
            const index = _.findIndex(state.instructorSessions, (instructorSession) => {
                return instructorSession.workshopInstanceId === workshopInstanceId;
            });
            //Remove if session already exists
            if (index >= 0) {
                state.instructorSessions.splice(index, 1);
            }
        }
    },

    actions: {
        loadInstructor: async ({commit}, userProfileId) => {
            const instructor = await userDao.getUserById(userProfileId);
            commit('setInstructor', instructor);
        },

        loadInstructors: async ({commit}) => {
            const instructors = await instructorDao.getInstructors();
            commit('setInstructors', instructors);
        },

        loadInstructorSessionTemplate: async ({commit}) => {
            const template = await instructorDao.getInstructorSessionTemplate();
            commit('setInstructorSessionTemplate', template);
        },

        loadInstructorSessions: async ({commit}) => {
            const sessions = await instructorDao.getInstructorSessions();
            commit('setInstructorSessions', sessions);
        },

        processInstructorSession: async ({commit}, instructorSession) => {
            instructorSession.serialize();
            const processedInstructorSession = await instructorDao.processInstructorSession(instructorSession);
            commit('addInstructorSession', processedInstructorSession);
        },

        deleteInstructorSession: async ({commit}, workshopInstanceId) => {
            await instructorDao.deleteInstructorSession(workshopInstanceId);
            commit('removeInstructorSession', workshopInstanceId);
        },

        processRegistration: async ({commit, state}, registrationRequest) => {
            registrationRequest.registration.serialize();
            registrationRequest.session.serialize();
            const processedInstructorRegistration = await instructorDao.processInstructorRegistration(registrationRequest);
            registrationRequest.registration = processedInstructorRegistration;
            await store.dispatch('instructor/loadInstructorSessions');
        },

        cancelRegistration: async ({commit}, registrationRequest) => {
            await instructorDao.cancelInstructorRegistration(registrationRequest);
            commit('removeRegistration', registrationRequest.registration.id);
        }
    }
};

export default InstructorStore;