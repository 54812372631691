import {sprintf} from 'sprintf-js';


const appId = 'UA-141768264-1';
const dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());

export function trackPage(name, path) {
    const title = sprintf('Carpenters International Training Fund - Superintendent Career Training - %s', name || 'Unknown');
    // console.log(sprintf('Logging Google Analytics for %s (%s)', title, path));
    gtag('config', appId, {
        page_title: title,
        page_path: path
    });
}
