import _ from "underscore";
import formatters, {trimToNull} from '@/util/formatters';
import {isAfter, isBefore, addDays} from 'date-fns';
import {date} from '@/util/formatters';
import {NULL_USER_NOTIFICATION} from "./notification";


export const Type = {
    LOCAL: 'LOCAL',
    COUNCIL: 'COUNCIL',
    INVALID: 'INVALID',

    from(type) {
        if (_.isEmpty(type) || !_.isString(type)) {
            return this.INVALID;
        }
        else if (_.isEqual(type.toUpperCase(), this.LOCAL)) {
            return this.LOCAL;
        }
        else if (_.isEqual(type.toUpperCase(), this.COUNCIL)) {
            return this.COUNCIL;
        }
        else {
            return this.INVALID;
        }
    }
}


export class Allocation {
    allocationId = null;
    type = null;
    typeId = null;
    instanceAllocation = null;
    editMode = false;

    get fields() {
        return ['allocationId', 'type', 'typeId', 'instanceAllocation'];
    }

    constructor(allocation) {
        this.copyFrom(allocation, this.fields);
    }

    copyFrom(allocation, fields) {
        _.each(fields, key => {
            // console.log(key);
            switch (key) {
                case 'type':
                    this.type = Type.from(allocation.type);
                    break;
                default:
                    this[key] = allocation[key];
                    break;
            }
        });
    }

    cloneTemplate(fields) {
        return _.reduce(fields, (memo, key) => {
            const value = _.isString(this[key]) ? trimToNull(this[key]) : this[key];
            memo[key] = value;
            return memo;
        }, {});
    }

    get isNew() {
        return this.id === 0;
    }

    set allocationType(newType) {
        if (!_.isEqual(this.type, newType)) {
            this.typeId = null;
        }
        this.type = newType;
    }

    get allocationType() {
        return this.type;
    }
}


export class WorkshopAllocation extends Allocation {
    workshopId = null;
    year = null;
    initialAllocation = null;
    adjustedAllocation = null;

    get fields() {
        return [...super.fields, 'workshopId', 'year', 'initialAllocation', 'adjustedAllocation'];
    }

    static create(workshopAllocation, asWorkspace) {
        return new WorkshopAllocation(workshopAllocation, true === asWorkspace)
    }

    constructor(workshopAllocation, asWorkspace) {
        super(workshopAllocation);
        this.year = workshopAllocation.year;
        this.initialAllocation = workshopAllocation.initialAllocation;
        this.adjustedAllocation = workshopAllocation.adjustedAllocation;
        this.workspace = asWorkspace ?
            NULL_WORKSHOP_ALLOCATION :
            WorkshopAllocation.create(workshopAllocation, true);
    }

    clone() {
        return WorkshopAllocation.create(this.cloneTemplate(this.fields));
    }

    commit() {
        this.copyFrom(this.workspace);
        this.editMode = false;
    }

    rollback() {
        _.forEach(this.fields, key => {
            this.workspace[key] = this[key];
        });
        this.editMode = false;
    }
}


export class WorkshopInstanceAllocation extends Allocation {
    workshopInstanceId = null;

    get fields() {
        return [...super.fields, 'workshopInstanceId'];
    }

    static create(workshopInstanceAllocation, asWorkspace) {
        return new WorkshopInstanceAllocation(workshopInstanceAllocation, true === asWorkspace);
    }

    constructor(workshopInstanceAllocation, asWorkspace) {
        super(workshopInstanceAllocation);
        this.workshopInstanceId = workshopInstanceAllocation.workshopInstanceId;
        this.workspace = asWorkspace ?
            NULL_WORKSHOP_INSTANCE_ALLOCATION :
            WorkshopInstanceAllocation.create(workshopInstanceAllocation, true);
    }

    clone() {
        return WorkshopInstanceAllocation.create(this.cloneTemplate(this.fields));
    }

    commit() {
        this.copyFrom(this.workspace);
    }

    rollback() {
        _.forEach(this.fields, key => {
            this.workspace[key] = this[key];
        });
    }

    get errors() {
        return {
            type: (!_.isEqual(this.type, Type.COUNCIL) && !_.isEqual(this.type, Type.LOCAL)),
            typeId: _.isEmpty(this.typeId),
            instanceAllocation: (_.isNull(this.instanceAllocation) || _.isUndefined(this.instanceAllocation) || +this.instanceAllocation < 0)
        };
    }
}


export const NULL_WORKSHOP_ALLOCATION = WorkshopAllocation.create({allocationId: 0});
export const NULL_WORKSHOP_INSTANCE_ALLOCATION = WorkshopInstanceAllocation.create({allocationId: 0, typeId: null});
