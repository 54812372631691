import _ from 'underscore';
import {compareAsc} from 'date-fns';
import {sprintf} from 'sprintf-js';
import formatters from '@/util/formatters';
import {
    Session,
    NULL_SESSION_P1, NULL_SESSION_P2, NULL_SESSION_P3, NULL_SESSION_P4,
    NULL_MENTOR_SESSION_P1, NULL_MENTOR_SESSION_P3
} from "@/model/session";


export class Program {
    static create(program, mentorProgram) {
        return new Program(program, mentorProgram);
    }

    name = null;
    description = null;
    mentorProgram = false;

    constructor(program, mentorProgram) {
        this.name = program.name;
        this.description = program.description;
        this.mentorProgram = true === mentorProgram;
    }

    get idx() {
        return null === this.name ? 0 : parseInt(this.name.replace(/^PROGRAM_(\d)$/i, '$1'), 10);
    }

    get title() {
        return sprintf('%sProgram %d', this.mentorProgram ? 'Mentor ' : '', this.idx);
    }

    equals(o) {
        return o instanceof Program && this.name === o.name;
    }

    cloneTemplate() {
        return {
            name: this.name,
            description: this.description,
            mentorProgram: this.mentorProgram
        };
    }
}


export class Track {
    static create(track, asWorkspace = false) {
        return new Track(track, asWorkspace);
    }

    static programKeys() {
        return _.union(_.range(1, 5).map((i) => {
            return 'program' + i;
        }), [1, 3].map((i) => {
            return 'mentorProgram' + i;
        }));
    }

    static traineeProgramKeys() {
        return _.range(1, 5).map((i) => {
            return 'program' + i;
        });
    }

    id = null;
    program1 = null;
    program2 = null;
    program3 = null;
    program4 = null;
    mentorProgram1 = null;
    mentorProgram3 = null;
    canceled = null;
    workspace = null;
    editProgram = null;
    associations = null;

    constructor(track, asWorkspace = false) {
        this.id = track.id;
        this.program1 = Session.create(track.program1);
        this.program2 = Session.create(track.program2);
        this.program3 = Session.create(track.program3);
        this.program4 = Session.create(track.program4);
        this.mentorProgram1 = Session.create(track.mentorProgram1);
        this.mentorProgram3 = Session.create(track.mentorProgram3);
        this.canceled = track.canceled;
        this.associations = track.associations;
        this.workspace = asWorkspace ? NULL_TRACK : Track.create(track, true);
    }

    cloneTemplate() {
        return {
            id: this.id,
            program1: this.program1.cloneTemplate(),
            program2: this.program2.cloneTemplate(),
            program3: this.program3.cloneTemplate(),
            program4: this.program4.cloneTemplate(),
            mentorProgram1: this.mentorProgram1.cloneTemplate(),
            mentorProgram3: this.mentorProgram3.cloneTemplate(),
            canceled: this.canceled,
            associations: this.associations
        };
    }

    clone() {
        return Track.create(this.cloneTemplate());
    }

    commit() {
        this.id = this.workspace.id;
        _.each(Track.programKeys(), (key) => {
            const wsp = this.workspace[key];
            wsp.dirty = false;
            this[key] = wsp.clone();
        });
        this.editProgram = null;
    }

     rollback() {
        this.workspace.id = this.id;
        _.each(Track.programKeys(), (key) => {
            const p = this[key];
            p.dirty = false;
            this.workspace[key] = p.clone();
        });
        this.editProgram = null;
    }

    compare(t) {
        const p = this.getEarliestProgram();
        const tp = t.getEarliestProgram();
        if (!p && !tp) {
            return 0;
        }
        if (!p) {
            return -1;
        }
        if (!tp) {
            return 1;
        }
        return compareAsc(p.dates[0], tp.dates[0]);
    }

    serialize() {
        for (let key of Track.programKeys()) {
            let session = this[key];
            session.serialize();
        }
    }

    get trackErrors() {
        return {
            session1: this.program1.hasErrors,
            session2: this.program2.hasErrors,
            session3: this.program3.hasErrors,
            session4: this.program4.hasErrors,
            mentorSession1: this.mentorProgram1.hasErrors,
            mentorSession3: this.mentorProgram3.hasErrors
        };
    }

    get hasErrors() {
        const errors = _.any(this.trackErrors);
        return errors;
    }

    get hasCanceledSessions() {
        const canceled = _.any(Track.programKeys(), (key) => {
            return this[key].canceled;
        });
        return canceled;
    }

    get hasRegistrations() {
        //Session registration count and roster are now matching as both reference trainee/mentor session tables
        const registrations = _.any(Track.programKeys(), (key) => {
            return this[key].hasRegistrations;
        });
        return registrations;
    }

    get hasAirfare() {
        const airfare = _.any(Track.programKeys(), (key) => {
            return this[key].airfare;
        });
        return airfare;
    }

    //BUSINESS RULE: can't delete a track with any user association (schedule OR registration), regardless of whether
    //association is currently active (FK constraint)
    get disableDelete() {
        return this.associations || this.hasRegistrations;
    }

    //BUSINESS RULE: can't cancel a track if airfare reservations exist and are not canceled (dates do not matter)
    get disableCancel() {
        return this.hasAirfare;
    }

    //RULE: can't save a track unless changes have been made and all data has been validated
    get disableSave() {
        if (this.isNew) {
            return this.workspace.hasErrors;
        }
        else {
            return this.workspace.hasErrors || !this.isDirty;  //isDirty reviews workspace flags
        }
    }

    get editMode() {
        return !!this.workspace.editProgram;
    }

    disableEditMode() {
        this.workspace.editProgram = null;
    }

    get isDirty() {
        return _.any(Track.programKeys(), (key) => {
            return this.workspace[key].dirty;
        });
    }

    get isNew() {
        return _.isNaN(this.id) || this.id === 0;
    }

    get title() {
        if (this.isNew) {
            return 'New Track';
        }
        const p = this.getEarliestProgram();
        return null === p ? 'Invalid Configuration' : formatters.date(p.dates[0], 'MMMM yyyy');
    }

    hasSessionInYear(fullYear) {
        return _.any(Track.programKeys(), (key) => {
            return this[key].inYear(fullYear) || false;
        });
    }

    equals(o) {
        return o instanceof Track && (_.isNaN(this.id) ? _.isNaN(o.id) : this.id === o.id);
    }

    getEarliestProgram() {
        const pKey = _.find(Track.programKeys(), (key) => this[key].isSet);
        return null === pKey ? null : this[pKey];
    }
}


export const NULL_PROGRAM = Program.create({});

export const NULL_TRACK = Track.create({
    id: NaN,
    program1: NULL_SESSION_P1.cloneTemplate(),
    program2: NULL_SESSION_P2.cloneTemplate(),
    program3: NULL_SESSION_P3.cloneTemplate(),
    program4: NULL_SESSION_P4.cloneTemplate(),
    mentorProgram1: NULL_MENTOR_SESSION_P1.cloneTemplate(),
    mentorProgram3: NULL_MENTOR_SESSION_P3.cloneTemplate()
});

export const PROGRAM_SESSIONS = [{
        idx: 1,
        mentorSession: false
    }, {
        idx: 2,
        mentorSession: false
    }, {
        idx: 3,
        mentorSession: false
    }, {
        idx: 4,
        mentorSession: false
    }, {
        idx: 1,
        mentorSession: true
    }, {
        idx: 3,
        mentorSession: true
    }
];
