import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {Traveler, Trip, Airport} from '@/model/travel';

export class TravelDao extends Dao {
    async getTravelPolicy() {
        try {
            const url = sprintf('travel/policy?rev=%d', this.getRev());
            const result = await this.api.get(url);
            return result.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading travel policy: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async saveTravelPolicy(policy) {
        try {
            await this.api.post('travel/policy', [policy]);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing travel policy: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getPendingTravelers() {
        try {
            const url = sprintf('travel/pending?ref=%d', this.getRev());
            const result = await this.api.get(url);
            return _.map(result.data, Traveler.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading pending travelers: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getPendingTrips(userId) {
        try {
            const url = sprintf('travel/last/%d?rev=%d', userId, this.getRev());
            const result = await this.api.get(url);
            return _.map(result.data, Trip.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading pending trips: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getAirports() {
        try {
            const url = sprintf('travel/airports?rev=%d', this.getRev());
            const result = await this.api.get(url);
            return _.map(result.data, Airport.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading airports: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async saveTrip(trip) {
        try {
            const result = await this.api.post('travel/trip', trip);
            return Trip.create(result.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing trip: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async cancelTrip(trip) {
        try {
            const result = await this.api.delete('travel/trip/' + trip.id);
            if (!result.data) {
                throw new Error('Unknown error');
            }
            return result.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error cancelling trip: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getTrips(userId) {
        try {
            const result = await this.api.get(sprintf('travel/trips/%d?rev=%d', userId, this.getRev()));
            return _.map(result.data, Trip.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading trips: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async undeclineTravel(registrationId) {
        try {

            const result = await this.api.post(`/travel/undecline/${registrationId}`);

            return Trip.create(result.data);

        } catch (err) {
            console.error(err);
            const message = sprintf('Error loading trips: %s', err.data.message);
            throw (new Error(message));
        }
    }
}

const travelDao = new TravelDao();
export default travelDao;