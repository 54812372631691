import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {InstructorSession} from "../model/session";
import {UserFactory} from "../model/user";
import {InstructorSessionRegistration} from "../model/registration";

class InstructorDao extends Dao {
    async getInstructors() {
        try {
            const url = sprintf('instructor/all?rev=%d', this.getRev());
            const response = await this.api.get(url);
            const instructors = _.map(response.data, UserFactory.create);
            return instructors;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading instructors: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getInstructorSessionTemplate() {
        try {
            const url = sprintf('instructor/session/template?rev=%d', this.getRev());
            const response = await this.api.get(url);
            const template = InstructorSession.create(response.data);
            return template;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading instructor session template: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getInstructorSessions() {
        try {
            const url = sprintf('instructor/session/all?ref=%d', this.getRev());
            const response = await this.api.get(url);
            const sessions = _.map(response.data, InstructorSession.create);
            return sessions;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading instructor sessions: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async processInstructorSession(instructorSession) {
        try {
            const url = 'instructor/session/process';
            const response = await this.api.post(url, instructorSession);
            const result = InstructorSession.create(response.data);
            return result;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing instructor session: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async deleteInstructorSession(workshopInstanceId) {
        try {
            const url = sprintf('instructor/session/delete/%d', workshopInstanceId);
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error('Unknown error');
            }
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error deleting instructor session: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async processInstructorRegistration(registrationRequest) {
        try {
            const url = 'instructor/session/registration/process';
            const response = await this.api.post(url, registrationRequest);
            const result = InstructorSessionRegistration.create(response.data);
            return result;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing instructor registration: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async cancelInstructorRegistration(registrationRequest) {
        try {
            const url = sprintf('instructor/session/registration/cancel/%d', registrationRequest.registration.id);
            const response = await this.api.post(url);
            const result = InstructorSessionRegistration.create(response.data);
            return result;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error cancelling instructor registration: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const instructorDao = new InstructorDao();
export default instructorDao;
