
import {UBCID_REGEX} from '@/components/shared/UbcId';
import { format as dateFormat, isValid, parseISO, parse } from 'date-fns';
import _ from 'underscore';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const errorToastOptions = {
    title: 'Error',
    solid: true,
    variant: 'danger'
};

export const errorModalOptions = {
    title: 'Error',
    size: 'sm',
    buttonSize: 'sm',
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
    hideHeaderClose: true,
    headerBgVariant: 'dark',
    headerTextVariant: 'white'
};

export function phoneNumber(num) {
    return (num || '').toString().replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
}

//Turn an Object map into a Date(Time) with un-set
export function getDate(map, defaultDate = new Date(1970, 0, 1, 0, 0, 0)) {
    if (_.isDate(map)) {
        return map;
    }
    if (_.isString(map)) {
        console.log('Parsing date {} as a String', map);
        return Date.parse(map);
    }
    console.log('Parsing date as an Object from:');
    console.log(map);
    let date = new Date(defaultDate.valueOf());
    let dateMap = _.has(map, 'date') ? map.date : map;
    let timeMap = _.has(map, 'time') ? map.time : map;
    date.setFullYear(_.has(dateMap, 'year') ? dateMap.year : defaultDate.getFullYear());
    date.setMonth(_.has(dateMap, 'month') ? dateMap.month - 1 : defaultDate.getMonth());
    date.setDate(_.has(dateMap, 'day') ? dateMap.day : defaultDate.getDate());
    date.setHours(_.has(timeMap, 'hour') ? timeMap.hour : defaultDate.getHours);
    date.setMinutes(_.has(timeMap, 'minute') ? timeMap.minute : defaultDate.getMinutes);
    date.setSeconds(_.has(timeMap, 'second') ? timeMap.second : defaultDate.getSeconds);
    console.log('Parsed date:');
    console.log(date);
    return date;
}

export function date(d, fmt) {
    const f = (fmt || '').trim();
    const format = _.isString(f) && !_.isEmpty(fmt) ? f : DEFAULT_DATE_FORMAT;
    if (undefined === d || null === d) {
        return '';
    }
    if (_.isString(d)) {
        if (_.isEmpty(d.trim())) {
            return '';
        }
        const dd = mkDate(d) || new Date();
        return dateFormat(dd, format);
    }
    if (_.isNaN(d.valueOf())) {  //Check for invalid date
        return '';
    }
    return dateFormat(getDate(d), format);
}

export function dateTime(dt, zeroBased=false) {
    if (_.isObject(dt) && !(dt instanceof Date)) {
        dt = mkDate(dt);
    }
    else if (typeof dt === 'string') {
        dt = Date.parse(dt);
    }
    if (dt instanceof Date) {
        var hr = dt.getHours();
        var min = dt.getMinutes();
        var am = hr < 12 ? true : false;
        hr = hr % 12;
        hr = hr ? hr : 12;  //adjust 0 to 12
        min  = min < 10 ? '0' + min : min;  //zero pad
        var time = sprintf('%s:%s %s', hr, min, am ? 'AM' : 'PM');
        const monthOffset = !!zeroBased ? 1 : 0;
        var datetime = sprintf('%d/%d/%d %s', dt.getMonth() + monthOffset, dt.getDate(), dt.getFullYear(), time);
        return datetime;
    }
    else {
        return null;
    }
}

//Take input 'd' and transform it into a Date (incl. time if applicable)
//Bad data is returned as null to avoid InvalidDate
//Refactored to accommodate the 85,763 ways that dates are rendered between BE/FE
export function mkDate(d) {
    //Defensively trim strings
    if (_.isString(d)) {
        d = trimToNull(d);
    }
    //Filter invalid results
    if (_.isNull(d) || _.isUndefined(d) || (_.isDate(d) && !isValid(d))) {
        //Note that _.isEmpty will return true for valid dates
        // console.log('WARNING - cannot parse invalid value!');
        // console.log(d);
        return null;
    }
    //Attempt to parse date
    let parsedDate;
    const formatString = 'EEE MMM dd yyyy HH:mm:ss';
    if (_.isDate(d) && isValid(d)) {
        //Note that instanceof Date and _.isDate will return true for InvalidDate
        parsedDate = new Date(d.getTime());
        // console.log(parsedDate);
    }
    else if (_.isString(d)) {
        //Try to parse ISO format...
        parsedDate = parseISO(d);
        if (_.isUndefined(parsedDate) || !isValid(parsedDate)) {
            //If that doesn't' work, try long format
            const truncDate = d.substring(0, d.indexOf('GMT')-1);
            parsedDate = parse(truncDate, formatString, new Date());
        }
        // console.log(parsedDate);
    }
    else if (_.isObject(d)) {
        //Simple date object map
        if (_.has(d, 'year') && _.has(d, 'month') &&_.has(d, 'day')) {
            parsedDate = new Date(d.year, d.month-1, d.day);
            // console.log(parsedDate);
        }
        //Nested date object map
        else if (_.has(d, 'date') && _.isObject(d.date) &&
            _.has(d.date, 'year') && _.has(d.date, 'month') &&_.has(d.date, 'day')) {
            //Nested time object map
            if (_.has(d, 'time') && _.isObject(d.time) &&
                _.has(d.time, 'hour') && _.has(d.time, 'minute')) {
                parsedDate = new Date(d.date.year, d.date.month-1, d.date.day, d.time.hour, d.time.minute);
                // console.log(parsedDate);
            }
            else {
                parsedDate = new Date(d.date.year, d.date.month-1, d.date.day);
                // console.log(parsedDate);
            }
        }
        //String as object with format: Thu Jan 01 1970 08:00:00 GMT-0800 (Pacific Standard Time)
        else {
            const stringDate = String(d);
            const truncDate = stringDate.substring(0, stringDate.indexOf('GMT')-1);
            parsedDate = parse(truncDate, formatString, new Date());
            // console.log(parsedDate);
        }
    }
    //Evaluate results
    if (_.isUndefined(parsedDate) || !isValid(parsedDate)) {
        console.log('WARNING - could not parse date from original value!');
        console.log(d);
        return null;
    }
    else {
        return parsedDate;
    }
}

export function trimToEmpty(value) {
    return (value || '').trim();
}

export function trimToNull(value) {
    const v = trimToEmpty(value);
    return _.isEmpty(value) ? null : v;
}

export function stripDashes(value) {
    return value.replace(/-/g, "");
}

export function stripSpaces(value) {
    return value.replace(/ /g, "");
}

export function escapeSingleQuotes(value) {
    return value.replace(/'/g, "\'");
}

export function serializeAsUbcId(value) {
    const preformatted = trimToEmpty(value);
    const formatted = _.isEmpty(preformatted) ? '' : stripSpaces(preformatted).toUpperCase();
    const uFormatted = _.isEmpty(formatted) ? '' : formatted.startsWith('U') ? formatted : 'U' + formatted;
    return UBCID_REGEX.test(uFormatted) ? uFormatted.replace(UBCID_REGEX, 'U$1$2') : null;
}

export default {
    phoneNumber,
    date,
    dateTime,
    mkDate,
    trimToEmpty,
    trimToNull,
    stripDashes,
    stripSpaces,
    escapeSingleQuotes,
    serializeAsUbcId
};
