import downloadjs from 'downloadjs';
import {trimToEmpty} from '@/util/formatters';
import store from '@/store/store';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {errorModalOptions} from "./formatters";
import {Vue} from 'vue-property-decorator';


const DISPO_REGEX = /filename="(.*)"/;
const UNKNOWN_FILE = 'unknown';

function getFilename(xhr) {
    const disposition = trimToEmpty(xhr.getResponseHeader('Content-Disposition'));
    const matches = disposition.match(DISPO_REGEX);
    return null == matches ? UNKNOWN_FILE : matches[1];
}

function getMimeType(xhr) {
    return xhr.getResponseHeader('Content-Type') || undefined;
}

//Default EC2 timeout = 600sec = 600000ms
export default function download(method, url, body, timeout = 600000) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.timeout = timeout;
        xhr.open(method, url);
        xhr.onload = () => {
            // console.log(xhr);
            store.dispatch('removeLoadingRequest');
            switch (xhr.status) {
                case 200:
                    const fileName = getFilename(xhr);
                    const mimeType = getMimeType(xhr);
                    const hasResponse = !!xhr.response && !!xhr.response.size;
                    if (_.isEqual(fileName, UNKNOWN_FILE) || _.isUndefined(mimeType) || !hasResponse) {
                        //Whether exception returned by API directly or via ExceptionController, status shows as 200
                        //Exception appears in responseTest, but is irretrievable as response type is set to blob
                        //After lots of trial and error, this implementation works, but could certainly be improved
                        console.error(xhr);
                        const vm = new Vue();
                        vm.$bvModal.msgBoxOk('Error: failed to download', errorModalOptions).then();
                    }
                    else {
                        downloadjs(xhr.response, fileName, mimeType);
                    }
                    break;
                default:
                    //Handle error directly, propagation is unnecessary
                    console.error(xhr);
                    const vm = new Vue();
                    vm.$bvModal.msgBoxOk('Error: failed to download', errorModalOptions).then();
            }
            return resolve();
        };
        xhr.onerror = () => {
            console.error(xhr);
            console.error(sprintf('[%s] request sent to %s returned with status %s', method, url, xhr.status));
            const vm = new Vue();
            vm.$bvModal.msgBoxOk('Error: failed to download', errorModalOptions).then();
        };
        xhr.ontimeout = () => {
            console.error(sprintf('[%s] request sent to %s timed out after %dms', method, url, timeout));
            const vm = new Vue();
            vm.$bvModal.msgBoxOk('Error: download request timed out', errorModalOptions).then();
        };
        store.dispatch('addLoadingRequest');
        xhr.send(body);
    });
}
