import _ from 'underscore';
import registrationDao from "@/dao/registration_dao";
import store from '@/store/store';
import {RegistrationRequest, NEW_SESSION_REGISTRATION} from "@/model/registration";
import {sprintf} from 'sprintf-js';
import {Status} from "../model/registration";

export const RegistrationStore = {
    namespaced: true,

    state: {
        //List of session IDs available for registration
        registrableSessionIds: [],
        //List of user IDs available for registration
        registrableUserIds: [],
        //List of user IDs available for cancellation
        cancelableUserIds: [],
        //Object "map" of sessions and associated registrable users
        //key = String(session.sessionId), value = User[]
        registrableUsersBySession: {},
        //Object "map" of users and associated registrable sessions
        //key = String(user.id), value = Session[]
        registrableSessionsByUser: {},
        //Object "map" of users and whether registrable sessions are loaded for them
        //Key = String(user.id), value = Boolean
        registrableSessionsLoadedByUser: {},
        //Object "map" of sessions and associated registered/scheduled users
        //key = String(session.sessionId), value = User[]
        sessionRoster: {},
        //Object "map" of trainees and associated registered/scheduled sessions
        //key = String(trainee.id), value = RegistrationRequest[]
        traineeSchedule: {},
        //Object "map" of mentors and associated registered/scheduled sessions
        //key = String(mentor.id), value = RegistrationRequest[]
        mentorSchedule: {},
        //Object "map" of mentors, trainees, and associated registered/scheduled sessions
        //key = String(mentor.id), value = Map (key=String(trainee.id), value = RegistrationRequest[])
        mentorScheduleForTrainee: {},
        //Object "map" of workshops and associated workshop allocations
        //key = String(workshopId), value = WorkshopAssociation[]
        workshopAllocations: {},
        //Object "map" of workshop instances and associated workshop instance associations
        //key = String(workshopInstanceId), value = WorkshopInstanceAssociation[]
        workshopInstanceAllocations: {}
    },

    getters: {
        registrableSessionIds: (state) => {
            return state.registrableSessionIds;
        },

        isRegistrableSessionId: (state) => (sessionId) => {
            return !_.isEmpty(state.registrableSessionIds) && state.registrableSessionIds.includes(sessionId);
        },

        registrableUserIds: (state) => {
            return state.registrableUserIds;
        },

        isRegistrableUserId: (state) => (userId) => {
            return !_.isEmpty(state.registrableUserIds) && state.registrableUserIds.includes(userId);
        },

        cancelableUserIds: (state) => {
            return state.cancelableUserIds;
        },

        isCancelableUserId: (state) => (userId) => {
            return !_.isEmpty(state.cancelableUserIds) && state.cancelableUserIds.includes(userId);
        },

        registrableUsersBySession: (state) => (session) => {
            const sessionKey = String(session.sessionId);
            if (_.has(state.registrableUsersBySession, sessionKey)) {
                return state.registrableUsersBySession[sessionKey];
            }
            // console.log('No registrable users loaded for session', sessionKey);
            return [];
        },

        registrableSessionsByUser: (state) => (user) => {
            const userKey = String(user.id);
            if (_.has(state.registrableSessionsByUser, userKey)) {
                return state.registrableSessionsByUser[userKey];
            }
            // console.log('No registrable sessions loaded for user', userKey);
            return [];
        },

        registrableSessionsLoadedByUser: (state) => (user) => {
            const userKey = String(user.id);
            if (_.has(state.registrableSessionsLoadedByUser, userKey)) {
                return state.registrableSessionsLoadedByUser[userKey];
            }
            // console.log('No registrable sessions loaded value available for user', userKey);
            return false;
        },

        registrableSessionsByUserId: (state) => (userId) => {
            const userKey = String(userId);
            if (_.has(state.registrableSessionsByUser, userKey)) {
                return state.registrableSessionsByUser[userKey];
            }
            // console.log('No registrable sessions loaded for user', userKey);
            return [];
        },

        sessionRoster: (state) => (session) => {
            const sessionKey = String(session.sessionId);
            if (_.has(state.sessionRoster, sessionKey)) {
                return state.sessionRoster[sessionKey];
            }
            // console.log('No scheduled/registered users loaded for session', sessionKey);
            return [];
        },

        traineeSchedule: (state) => (traineeId) => {
            const userKey = String(traineeId);
            if (_.has(state.traineeSchedule, userKey)) {
                return state.traineeSchedule[userKey];
            }
            // console.log('No schedule loaded for trainee ID', userKey);
            return [];
        },

        mentorSchedule: (state) => (mentorId) => {
            const userKey = String(mentorId);
            if (_.has(state.mentorSchedule, userKey)) {
                return state.mentorSchedule[userKey];
            }
            // console.log('No schedule loaded for mentor ID', userKey);
            return [];
        },

        mentorScheduleForTrainee: (state) => (mentorObject) => {
            const mentorKey = String(mentorObject.mentorId);
            const traineeKey = String(mentorObject.traineeId);
            if (_.has(state.mentorScheduleForTrainee, mentorKey) &&
                _.has(state.mentorScheduleForTrainee[mentorKey], traineeKey)) {
                    return state.mentorScheduleForTrainee[mentorKey][traineeKey];
            }
            // console.log(sprintf('No schedule loaded for mentor ID %d and trainee ID %d', mentorKey, traineeKey));
            return [];
        },

        workshopAllocations: (state) => (workshopId) => {
            const key = String(workshopId);
            if (_.has(state.workshopAllocations, key)) {
                return state.workshopAllocations[key];
            }
            // console.log('No workshop associations loaded for workshop ID', workshopId);
            return [];
        },

        workshopInstanceAllocations: (state) => (workshopInstanceId) => {
            const key = String(workshopInstanceId);
            if (_.has(state.workshopInstanceAllocations, key)) {
                return state.workshopInstanceAllocations[key];
            }
            // console.log('No workshop instance associations loaded for workshop instance ID', workshopInstanceId);
            return [];
        }
    },

    mutations: {
        setRegistrableSessionIds: (state, sessionIds) => {
            state.registrableSessionIds = sessionIds;
        },

        setRegistrableUserIds: (state, userIds) => {
            state.registrableUserIds = userIds;
        },

        setCancelableUserIds: (state, userIds) => {
            state.cancelableUserIds = userIds;
        },

        setRegistrableUsersBySession: (state, result) => {
            if (_.has(state.registrableUsersBySession, result.session)) {
                delete state.registrableUsersBySession[result.session];
            }
            state.registrableUsersBySession[result.session] = result.users;
        },

        setRegistrableSessionsByUser: (state, result) => {
            if (_.has(state.registrableSessionsByUser, result.user)) {
                delete state.registrableSessionsByUser[result.user];
            }
            state.registrableSessionsByUser[result.user] = result.sessions;
        },

        setRegistrableSessionsLoadedByUser: (state, result) => {
            state.registrableSessionsLoadedByUser[result.user] = result.loaded;
        },

        setSessionRoster: (state, result) => {
            if (_.has(state.sessionRoster, result.session)) {
                delete state.sessionRoster[result.session];
            }
            state.sessionRoster[result.session] = result.users;
        },

        setTraineeSchedule: (state, result) => {
            if (_.has(state.traineeSchedule, result.trainee)) {
                delete state.traineeSchedule[result.trainee];
            }
            state.traineeSchedule[result.trainee] = result.schedule;
        },

        setMentorSchedule: (state, result) => {
            if (_.has(state.mentorSchedule, result.mentor)) {
                delete state.mentorSchedule[result.mentor];
            }
            state.mentorSchedule[result.mentor] = result.schedule;
        },

        setMentorScheduleForTrainee: (state, result) => {
            if (_.has(state.mentorScheduleForTrainee, result.mentor)) {
                //Has mentor map
                if (_.has(state.mentorScheduleForTrainee[result.mentor], result.trainee)) {
                    //Has trainee map
                    delete state.mentorScheduleForTrainee[result.mentor][result.trainee];
                }
                state.mentorScheduleForTrainee[result.mentor][result.trainee] = result.schedule;
            }
            else {
                //Does not have mentor map
                state.mentorScheduleForTrainee[result.mentor] = { [result.trainee]: result.schedule };
            }
        },

        setWorkshopAllocations: (state, result) => {
            if (_.has(state.workshopAllocations, result.workshop)) {
                delete state.workshopAllocations[result.workshop];
            }
            state.workshopAllocations[result.workshop] = result.allocations;
        },

        setWorkshopInstanceAllocations: (state, result) => {
            if (_.has(state.workshopInstanceAllocations, result.workshopInstance)) {
                delete state.workshopInstanceAllocations[result.workshopInstance];
            }
            state.workshopInstanceAllocations[result.workshopInstance] = result.allocations;
        }
    },

    actions: {
        loadRegistrableSessionIds: async ({commit}) => {
            const sessionIds = await registrationDao.getRegistrableSessionIds();
            commit('setRegistrableSessionIds', sessionIds);
        },

        loadRegistrableUserIds: async (context) => {
            //Used for reactivity, do not cache
            const userIds = await registrationDao.getRegistrableUserIds();
            context.commit('setRegistrableUserIds', userIds);
        },

        loadCancelableUserIds: async (context) => {
            //Used for reactivity, do not cache
            const userIds = await registrationDao.getCancelableUserIds();
            context.commit('setCancelableUserIds', userIds);
        },

        loadRegistrableUsersBySession: async ({commit, state}, session) => {
            const sessionKey = String(session.sessionId);
            const users = await registrationDao.getRegistrableUsersForSession(session);
            commit('setRegistrableUsersBySession', {
                session: sessionKey,
                users: users
            });
        },

        loadRegistrableSessionsByUser: async ({commit, state}, user) => {
            const userKey = String(user.id);
            commit('setRegistrableSessionsLoadedByUser', {
                user: userKey,
                loaded: false
            });
            const sessions = await registrationDao.getRegistrableSessionsForUser(user);
            commit('setRegistrableSessionsByUser', {
                user: userKey,
                sessions: sessions
            });
            commit('setRegistrableSessionsLoadedByUser', {
                user: userKey,
                loaded: true
            });
        },

        loadSessionRoster: async ({commit, state, store}, session) => {
            const sessionKey = String(session.sessionId);
            const userRoster = await registrationDao.getSessionRoster(session);
            commit('setSessionRoster', {
                session: sessionKey,
                users: userRoster
            });
        },

        loadTraineeSchedule: async ({commit, state}, trainee) => {
            //Prerequisite
            await store.dispatch('tracks/loadTracks');
            //Build the schedule
            const traineePrograms = [1, 2, 3, 4];
            const schedule = _.chain(traineePrograms)
                .map(idx => {
                    const sessionKey = sprintf('program%d', idx);
                    const registration = trainee.registrations[idx - 1] || NEW_SESSION_REGISTRATION;
                    const session = (!_.isEqual(registration, NEW_SESSION_REGISTRATION) && registration.sessionTrackId !== trainee.trackId) ?
                        //Session out of trainee track
                        store.getters['tracks/getTrackById'](registration.sessionTrackId)[sessionKey] :
                        //Session in trainee track
                        store.getters['tracks/getTrackById'](trainee.trackId)[sessionKey];
                    const result = new RegistrationRequest(trainee, session, registration);
                    return result;
                })
                .value();
            //Persist
            commit('setTraineeSchedule', {
                trainee: String(trainee.id),
                schedule: schedule
            });
        },

        loadMentorSchedule: async ({commit, state}, mentorObject) => {
            //Prerequisites
            const mentor = mentorObject.mentor;
            const trainees = mentorObject.trainees;
            await store.dispatch('tracks/loadTracks');
            //First, get all sessions mentor is registered for
            const mentorRegisteredSessions = _.chain(mentor.registrations)
                .map(registration => {
                    return store.getters['tracks/getSessionById'](registration.sessionId);
                })
                .filter(session => {
                    return !_.isNull(session) && !_.isUndefined(session);
                })
                .value();
            //Next get all sessions mentor is scheduled for, i.e. sessions corresponding to sessions mentor's trainees
            //are registered/scheduled for, less mentor registrations
            const mentorScheduledSessions = _.chain(trainees)
                .reduce((memo, trainee) => {
                    //Check for program 1
                    const traineeP1TrackId = !trainee.registrations[0] ? trainee.trackId : trainee.registrations[0].sessionTrackId;
                    const mentorRegisteredForP1Session = !!_.findWhere(mentorRegisteredSessions, {idx:1, trackId: traineeP1TrackId});

                    if (!mentorRegisteredForP1Session) {
                        const correspondingMentorP1Session = store.getters['tracks/getAssociatedSession']({
                            trackId: traineeP1TrackId,
                            idx: 1,
                            mentorSession: false
                        });
                        if (!_.isUndefined(correspondingMentorP1Session) && !memo.includes(correspondingMentorP1Session)) {
                            memo.push(correspondingMentorP1Session);
                            memo.push(correspondingMentorP1Session);
                        }
                    }

                    //Check for program 3
                    const traineeP3TrackId = !trainee.registrations[2] ? trainee.trackId : trainee.registrations[2].sessionTrackId;
                    const mentorRegisteredForP3Session = !!_.findWhere(mentorRegisteredSessions, {idx: 3, trackId: traineeP3TrackId});

                    if (!mentorRegisteredForP3Session) {
                        const correspondingMentorP3Session = store.getters['tracks/getAssociatedSession']({
                            trackId: traineeP3TrackId,
                            idx: 3,
                            mentorSession: false
                        });
                        if (!_.isUndefined(correspondingMentorP3Session) && !memo.includes(correspondingMentorP3Session)) {
                            memo.push(correspondingMentorP3Session);
                        }
                    }
                    return memo;
                }, [])
                .value();
            //Build into a combined list of custom schedule objects
            const registered = _.map(mentor.registrations, registration => {
                const session = store.getters['tracks/getSessionById'](registration.sessionId);
                return (_.isNull(session) || _.isUndefined(session)) ?
                    null :
                    new RegistrationRequest(mentor, session, registration);
            });
            const scheduled = _.map(mentorScheduledSessions, session => {
                return new RegistrationRequest(mentor, session, NEW_SESSION_REGISTRATION);
            });
            const combinedSchedule = _.chain(registered)
                .filter(schedule => !!schedule)
                .union(scheduled)
                .filter(schedule => !!schedule.session)
                .groupBy(schedule => schedule.session.workshopInstanceId)
                .value();
            // console.log(combinedSchedule);
            //Flatten schedule (distinct session, summary-level registration statuses)
            const flattenedSchedule = _.chain(_.keys(combinedSchedule))
                .map(key => {
                    const sessions = combinedSchedule[key];
                    if (sessions.length > 1) {
                        const statuses = _.chain(sessions)
                            .pluck('status')
                            .unique(i => i)
                            .value();
                        if (statuses.includes(Status.REGISTERED)) {
                            //Only include incomplete is all items are incomplete
                            //Only include schedule if all items are scheduled
                            //Prefer registered (pending) over completed/incomplete (final)
                            return _.first(_.filter(sessions, session => {
                                return _.isEqual(session.status, Status.REGISTERED);
                            }));
                        }
                        else if (statuses.includes(Status.COMPLETED)) {
                            //Prefer completed (any registrations completed) over incomplete
                            return _.first(_.filter(sessions, session => {
                                return _.isEqual(session.status, Status.COMPLETED);
                            }));
                        }
                        else if (statuses.includes(Status.INCOMPLETE)) {
                            //Prefer incomplete (all registrations incomplete) over scheduled
                            return _.first(_.filter(sessions, session => {
                                return _.isEqual(session.status, Status.INCOMPLETE);
                            }));
                        }
                        else {
                            return _.first(sessions);
                        }
                    }
                    else {
                        return _.first(sessions);
                    }})
                .sortBy(schedule => schedule.session.dates[0])
                .value();
            // console.log(flattenedSchedule);
            //Persist
            commit('setMentorSchedule', {
                mentor: String(mentor.id),
                schedule: flattenedSchedule
            });
        },

        loadMentorScheduleForTrainee: async ({commit, state}, mentorObject) => {
            //Prerequisites
            const mentor = mentorObject.mentor;
            const trainees = [mentorObject.trainee];
            await store.dispatch('tracks/loadTracks');
            //First, get all sessions mentor is registered for
            const mentorRegisteredSessions = _.chain(mentor.registrations)
                .map(registration => {
                    return store.getters['tracks/getSessionById'](registration.sessionId);
                })
                .filter(session => {
                    return !_.isNull(session) && !_.isUndefined(session);
                })
                .value();
            //Next get all sessions mentor is scheduled for, i.e. sessions corresponding to sessions mentor's trainees
            //are registered/scheduled for, less mentor registrations
            const mentorScheduledSessions = _.chain(trainees)
                .reduce((memo, trainee) => {
                    //Check for program 1
                    const traineeP1TrackId = _.isNull(trainee.registrations[0]) ?
                        trainee.trackId : trainee.registrations[0].sessionTrackId;
                    const mentorP1Registration = _.findWhere(mentorRegisteredSessions, {idx: 1, trackId: traineeP1TrackId});
                    //To select only trainee-related sessions, build schedule here instead of unioning with all mentor registrations
                    if (_.isUndefined(mentorP1Registration)) {
                        const correspondingMentorP1Session = store.getters['tracks/getAssociatedSession']({
                            trackId: traineeP1TrackId,
                            idx: 1,
                            mentorSession: false
                        });
                        if (!_.isUndefined(correspondingMentorP1Session)) {
                            const p1Registration = new RegistrationRequest(mentor, correspondingMentorP1Session, NEW_SESSION_REGISTRATION);
                            memo.push(p1Registration);
                        }
                    }
                    else {
                        const registration = _.findWhere(mentor.registrations, {sessionId: mentorP1Registration.sessionId});
                        const p1Registration = new RegistrationRequest(mentor, mentorP1Registration, registration);
                        memo.push(p1Registration);
                    }
                    //Check for program 3
                    const traineeP3TrackId = _.isNull(trainee.registrations[2]) ?
                        trainee.trackId : trainee.registrations[2].sessionTrackId;
                    const mentorP3Registration = _.findWhere(mentorRegisteredSessions, {idx: 3, trackId: traineeP3TrackId});
                    //To select only trainee-related sessions, build schedule here instead of unioning with all mentor registrations
                    if (_.isUndefined(mentorP3Registration)) {
                        const correspondingMentorP3Session = store.getters['tracks/getAssociatedSession']({
                            trackId: traineeP3TrackId,
                            idx: 3,
                            mentorSession: false
                        });
                        if (!_.isUndefined(correspondingMentorP3Session)) {
                            const p3Registration = new RegistrationRequest(mentor, correspondingMentorP3Session, NEW_SESSION_REGISTRATION);
                            memo.push(p3Registration);
                        }
                    }
                    else {
                        const registration = _.findWhere(mentor.registrations, {sessionId: mentorP3Registration.sessionId});
                        const p3Registration = new RegistrationRequest(mentor, mentorP3Registration, registration);
                        memo.push(p3Registration);
                    }
                    return memo;
                }, [])
                .value();
            //Finally build into a combined list of custom schedule objects
            const schedule = _.chain(mentorScheduledSessions)
                .unique(schedule => schedule.session.workshopInstanceId)
                .sortBy(schedule => schedule.session.dates[0])
                .value();
            //Persist
            commit('setMentorScheduleForTrainee', {
                mentor: String(mentorObject.mentor.id),
                trainee: String(mentorObject.trainee.id),
                schedule: schedule
            });
        },

        processRegistration: async ({commit}, registrationRequest) => {
            registrationRequest.registration.serialize();
            registrationRequest.session.serialize();
            const processedRegistration = await registrationDao.processRegistration(registrationRequest);
            registrationRequest.registration = processedRegistration;
            // console.log(registrationRequest);
        },

        cancelRegistration: async ({commit}, registrationRequest) => {
            registrationRequest.session.serialize();
            await registrationDao.cancelRegistration(registrationRequest);
        },

        declinedTravel: async ({commit}, request) => {
            await registrationDao.declinedTravel(request.registrationId, request.declinedTravel);
        },

        loadWorkshopAllocations: async ({commit}, workshopId) => {
            try {
                const workshopKey = String(workshopId);
                const allocations = await registrationDao.getWorkshopAllocations(workshopId);
                commit('setWorkshopAllocations', {
                    workshop: workshopKey,
                    allocations: allocations
                });
            }
            catch (err) {
                throw err;
            }
        },

        loadWorkshopInstanceAllocations: async ({commit}, workshopInstanceId) => {
            try {
                const workshopInstanceKey = String(workshopInstanceId);
                const allocations = await registrationDao.getWorkshopInstanceAllocations(workshopInstanceId);
                commit('setWorkshopInstanceAllocations', {
                    workshopInstance: workshopInstanceKey,
                    allocations: allocations
                });
            }
            catch (err) {
                throw err;
            }
        },

        processWorkshopInstanceAllocation: async ({commit}, allocation) => {
            const processedAllocation = await registrationDao.processWorkshopInstanceAllocation(allocation);
            // console.log(processedAllocation);
        },

        deleteWorkshopInstanceAllocation: async ({commit}, allocation) => {
            const success = await registrationDao.deleteWorkshopInstanceAllocation(allocation.allocationId);
            // console.log(success);
        },
    }
};

export default RegistrationStore;
