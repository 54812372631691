import Dao from '@/dao/dao';
import {sprintf} from 'sprintf-js';
import {UserFactory} from '@/model/user';
import _ from "underscore";
import {Session} from "@/model/session";
import {SecurityLevel} from "@/model/security_level";
import {SessionRegistration} from "../model/registration";
import {WorkshopAllocation, WorkshopInstanceAllocation} from "../model/allocation";

class RegistrationDao extends Dao {
    //Tracks - get IDs of registrable sessions
    async getRegistrableSessionIds() {
        try {
            const url = sprintf('registration/sessions/getallow?rev=%d', this.getRev());
            const response = await this.api.get(url);
            const sessionIds = response.data;
            return sessionIds;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading registrable session IDs: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Users/Profile/Schedule - get IDs of registrable users
    async getRegistrableUserIds() {
        try {
            const url = sprintf('registration/users/getallow?rev=%d', this.getRev());
            const response = await this.api.get(url);
            const userIds = response.data;
            return userIds;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading registrable user IDs: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Users/Profile/Schedule - get IDs of registrable users
    async getCancelableUserIds() {
        try {
            const url = sprintf('registration/users/getcancel?rev=%d', this.getRev());
            const response = await this.api.get(url);
            const userIds = response.data;
            return userIds;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading cancellable user IDs: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Tracks - get users eligible to register in given session
    async getRegistrableUsersForSession(session) {
        try {
            const url = sprintf('registration/users/getallow/%s/%s/%s?rev=%s', session.sessionId, session.idx,
                session.mentorSession, this.getRev());
            const response = await this.api.get(url);
            const users = _.map(response.data, UserFactory.create);
            return users;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading registrable users for session: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Users/Profile/Schedule - get sessions eligible to register for given user
    async getRegistrableSessionsForUser(user) {
        try {
            const mentorSession = _.isEqual(user.securityLevel, SecurityLevel.MENTOR);
            const url = sprintf('registration/sessions/getallow/%s/%s?rev=%s', user.id, mentorSession,
                this.getRev());
            const response = await this.api.get(url);
            const sessions = _.map(response.data, Session.create);
            return sessions;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading registrable sessions for user: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Tracks - get roster for registered/scheduled users for the given session
    async getSessionRoster(session) {
        try {
            const url = sprintf('registration/roster/%s/%s/%s?rev=%s', session.sessionId, session.idx, session.mentorSession,
                this.getRev());
            const response = await this.api.get(url);
            const data = response.data;
            //Combine lists by flagging users with properties
            if (_.isObject(data) && _.has(data, "SCHEDULED") && _.has(data, "REGISTERED")) {
                const scheduled = _.map(response.data["SCHEDULED"], UserFactory.create);
                for (let user of scheduled) {
                    user.scheduled = true;
                    user.registered = false;
                }
                const registered = _.map(response.data["REGISTERED"], UserFactory.create);
                for (let user of registered) {
                    user.scheduled = true;
                    user.registered = true;
                }
                const all = scheduled.concat(registered);
                return all;
            }
            else {
                throw new Error('Malformed response');
            }
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading session roster: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getWorkshopAllocations(workshopId) {
        const url = sprintf('allocation/workshop/%s?rev=%s', workshopId, this.getRev());
        try {
            const response = await this.api.get(url);
            const allocations = _.map(response.data, WorkshopAllocation.create);
            // console.log(allocations);
            return allocations;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error getting workshop allocations: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getWorkshopInstanceAllocations(workshopInstanceId) {
        const url = sprintf('allocation/workshop_instance/%s?rev=%s', workshopInstanceId, this.getRev());
        try {
            const response = await this.api.get(url);
            const allocations = _.map(response.data, WorkshopInstanceAllocation.create);
            // console.log(allocations);
            return allocations;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error getting workshop allocations: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async processRegistration(request) {
        try {
            request.session.serialize();
            // console.log(request);
            const response = await this.api.post('registration/process', request);
            const result = SessionRegistration.create(response.data);
            // console.log(result);
            return result;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing registration: %s', err.data.message);
            throw (new Error(message));
        }
    }

    // Process a registration comment
    // json -> { 'registrationId': 123, 'comment': 'this is a sample comment' }
    async processRegistrationComment(json) {
        try {
            const response = await this.api.post('registration/process-comment', json);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing registration comment: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async cancelRegistration(request) {
        try {
            request.session.serialize();
            const response = await this.api.post('registration/cancel', request);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error cancelling reservation: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //Update declinedTravel flag on registration
    async declinedTravel(registrationId, declinedTravel) {
        try {
            const url = sprintf('registration/travel/%d/%s', registrationId, declinedTravel);
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error updating travel request for registration: %s', err.data.message);
            throw (new Error(message));
        }
    }

    //(Re)send registration confirmation for trainee, mentor, or instructor
    async sendConfirmation(registrationId) {
        try {
            const url = sprintf('registration/confirmation/%d', registrationId);
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error sending registration confirmation: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async cancelAllRegistrations(sessionId) {
        try {
            const url = sprintf('registration/session/cancel/%s', sessionId);
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error cancelling session registrations: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async processWorkshopInstanceAllocation(allocation) {
        try {
            const response = await this.api.post('allocation/workshop_instance', allocation);
            return WorkshopInstanceAllocation.create(response.data);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error processing workshop instance allocation: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async deleteWorkshopInstanceAllocation(allocationId) {
        try {
            const url = sprintf('allocation/workshop_instance/delete/%s', allocationId);
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error('Unknown error');
            }
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error deleting workshop instance allocation: %s', err.data.message);
            throw (new Error(message));
        }
    }
}

const registrationDao = new RegistrationDao();
export default registrationDao;
