import Dao from '@/dao/dao';
import {Organization, ContractorType} from '@/model/organization';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';

class OrganizationDao extends Dao {
    //TODO - deprecate, load organizations and filter for contractors via org store
    async getContractors() {
        try {
            const url = sprintf('contractors?rev=%d', this.getRev());
            const response = await this.api.get(url);
            return _.map(response.data, Organization.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading contractors: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getOrganizationById(id) {
        try {
            if (_.isEqual(id, 0) || _.isNull(id) || _.isUndefined(id) || _.isNaN(id)) {
                return null;
            }
            const url = sprintf('organizations/%d?rev=%d', id, this.getRev());
            const response = await this.api.get(url);
            return _.isNull(response.data) ? null : Organization.create(response.data);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading organization: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getOrganizations() {
        try {
            const url = sprintf('organizations?rev=%d', this.getRev());
            const response = await this.api.get(url);
            return _.map(response.data, Organization.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading organizations: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async getContractorTypes() {
        try {
            const url = sprintf('contractor/types?rev=%d', this.getRev());
            const response = await this.api.get(url);
            return _.map(response.data, ContractorType.create);
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error loading contractor types: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async deleteContact(organizationId, organizationContactId) {
        try {
            if (!organizationId || !organizationContactId) {
                //Organization and/or contact have not been committed to database, no API call needed
                return true;
            }
            const url = sprintf('organization/delete_contact/%d/%d', organizationId, organizationContactId);
            const response = await this.api.post(url);
            if (!response.data) {
                throw new Error('Unknown error');
            }
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error deleting contact: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async saveOrganization(organization) {
        try {
            const response = await this.api.post('organization', organization);
            const updatedOrganization = Organization.create(response.data);
            // console.log(updatedOrganization);
            return updatedOrganization;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error processing organization: %s', error.data.message);
            throw (new Error(message));
        }
    }
}

const organizationDao = new OrganizationDao();
export default organizationDao;
