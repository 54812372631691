import {sprintf} from 'sprintf-js';
import formatters, {trimToNull} from '@/util/formatters';
import _ from "underscore";
import {SecurityLevel} from "@/model/security_level";

export class WorkshopInstanceFactory {
    static create(base) {
        if (_.isEmpty(base)) {
            return null;
        }
        //IDX is unique to Session
        else if (_.has(base, "idx")) {
            return new Session(base);
        }
        //Associations is unique to InstructorSession
        else if (_.has(base, "associations")) {
            return new InstructorSession(base);
        }
        else {
            return new WorkshopInstance(base);
        }
    }
}


//"Generic" session implementation as superclass
export class WorkshopInstance {
    sessionId = null;
    workshopId = null;
    workshopInstanceId = null;
    permissionId = null;
    dates = null;
    times = null;
    instructorsNeeded = null;
    minSeats = null;
    maxSeats = null;
    registrations = null;
    equipment = null;
    classroom = null;
    roomSetup = null;
    audioVisual = null;
    foodBeverage = null;
    additionalSetup = null;
    comment = null;
    binders = null;
    allowRegistrations = null;
    adminRegistration = null;
    autoClose = null;
    canceled = null;
    dirty = null;
    airfare = null;
    techCoordinator = null;
    name = null;
    description = null;
    requireVaccine = null;

    //Check validity for retrieving allocations
    static isValid(workshopInstance) {
        return !_.isNull(workshopInstance) && !_.isUndefined(workshopInstance) &&
            !!workshopInstance.workshopId && !!workshopInstance.workshopInstanceId;
    }

    constructor(workshopInstance) {
        this.copyFrom(workshopInstance);
    }

    get fields() {
        return ['sessionId', 'workshopId', 'workshopInstanceId', 'permissionId', 'dates', 'times', 'instructorsNeeded',
            'minSeats', 'maxSeats', 'registrations', 'equipment', 'classroom', 'roomSetup', 'audioVisual',
            'foodBeverage', 'additionalSetup', 'comment', 'binders', 'allowRegistrations', 'adminRegistration',
            'autoClose', 'canceled', 'dirty', 'airfare', 'techCoordinator', 'name', 'description', 'requireVaccine'];
    }

    copyFrom(workshopInstance) {
        _.each(this.fields, (key) => {
            switch (key) {
                case 'dates':
                    this[key] = _.map(workshopInstance.dates || [], formatters.mkDate);
                    break;
                case 'times':
                    this[key] = _.map(workshopInstance.times || [], formatters.mkDate);
                    break;
                default:
                    this[key] = workshopInstance[key];
            }
        });
    }

    get sessionErrors() {
        // console.log(this);
        return {
            dates: (_.isEmpty(this.dates) || this.dates.length !== 2 || _.isNull(this.dates[0]) || _.isNull(this.dates[1])),
            instructorsNeeded: (_.isUndefined(this.instructorsNeeded) || _.isNull(this.instructorsNeeded) || +this.instructorsNeeded < 0),
            minSeats: (_.isUndefined(this.minSeats) || _.isNull(this.minSeats) || +this.minSeats < 0),
            maxSeats: (_.isUndefined(this.maxSeats) || _.isNull(this.maxSeats) || +this.maxSeats <= +this.minSeats)
        };
    }

    get availableSeats() {
        const availableSeats = (Number(this.maxSeats) - Number(this.registrations));
        return availableSeats;
    }

    //Business rule: registrations are accepted as long as either allowRegistrations or adminRegistration is true
    get registrationsAccepted() {
        return !!this.allowRegistrations || !!this.adminRegistration;
    }

    //Business rule: can't cancel all registrations if airfare reservations exist, there are no registrations to cancel, or session is in past
    get disableCancelRegistrations() {
        const disable = !!this.airfare || !this.hasRegistrations || this.hasEnded;
        return disable;
    }

    get hasRegistrations() {
        return Number(this.registrations) > 0;
    }

    get programDays() {
        const d1 = new Date(this.dates[0]).setHours(12,0,0);
        const d2 = new Date(this.dates[1]).setHours(12, 0, 0);
        const diff = new Date(d2 - d1).getUTCDate();  //Inclusive range
        return diff;
    }

    //Determine whether session has ended
    get hasEnded() {
        if (_.isEmpty(this.dates) || _.isNull(this.dates[1])) {
            return false;
        }
        else {
            return new Date() > this.dates[1];
        }
    }

    //Does the session fall within the given year?
    inYear(fullYear) {
        if (this.isSet) {
            return _.isEqual(this.dates[0].getFullYear(), fullYear) || _.isEqual(this.dates[1].getFullYear(), fullYear);
        }
        else {
            return false;
        }
    }

    //Parse times so FE to BE communication doesn't cast and auto-convert timezone from local to UTC
    serialize() {
        if (_.isDate(this.times[0]) && _.isDate(this.times[1])) {
            const startTime = this.times[0].toString();
            const endTime = this.times[1].toString();
            this.times = [startTime, endTime];
        }
    }

    get isSet() {
        return !!this.dates && 2 >= this.dates.length && !!this.dates[0] && !!this.dates[1];
    }

    get programDates() {
        return this.dates;
    }

    set programDates(value) {
        if (!_.isEqual(this.dates, value)) {
            this.dates = value;
            this.dirty = true;
        }
    }

    get programTimes() {
        return this.times;
    }

    set programTimes(value) {
        if (!_.isEqual(this.times, value)) {
            this.times = value;
            this.dirty = true;
        }
    }

    get programInstructorsNeeded() {
        return this.instructorsNeeded;
    }

    set programInstructorsNeeded(value) {
        if (!_.isEqual(this.instructorsNeeded, value)) {
            this.instructorsNeeded = value;
            this.dirty = true;
        }
    }

    get programMinSeats() {
        return this.minSeats;
    }

    set programMinSeats(value) {
        if (!_.isEqual(this.minSeats, value)) {
            this.minSeats = value;
            this.dirty = true;
        }
    }

    get programMaxSeats() {
        return this.maxSeats;
    }

    set programMaxSeats(value) {
        if (!_.isEqual(this.maxSeats, value)) {
            this.maxSeats = value;
            this.dirty = true;
        }
    }

    get programEquipment() {
        return this.equipment;
    }

    set programEquipment(value) {
        if (!_.isEqual(this.equipment, value)) {
            this.equipment = value;
            this.dirty = true;
        }
    }

    get programComment() {
        return this.comment;
    }

    set programComment(value) {
        if (!_.isEqual(this.comment, value)) {
            this.comment = value;
            this.dirty = true;
        }
    }

    get programClassroom() {
        return this.classroom;
    }

    set programClassroom(value) {
        if (!_.isEqual(this.classroom, value)) {
            this.classroom = value;
            this.dirty = true;
        }
    }

    get programRoomSetup() {
        return this.roomSetup;
    }

    set programRoomSetup(value) {
        if (!_.isEqual(this.roomSetup, value)) {
            this.roomSetup = value;
            this.dirty = true;
        }
    }

    get programAudioVisual() {
        return this.audioVisual;
    }

    set programAudioVisual(value) {
        if (!_.isEqual(this.audioVisual, value)) {
            this.audioVisual = value;
            this.dirty = true;
        }
    }

    get programFoodBeverage() {
        return this.foodBeverage;
    }

    set programFoodBeverage(value) {
        if (!_.isEqual(this.foodBeverage, value)) {
            this.foodBeverage = value;
            this.dirty = true;
        }
    }

    get programAdditionalSetup() {
        return this.additionalSetup;
    }

    set programAdditionalSetup(value) {
        if (!_.isEqual(this.additionalSetup, value)) {
            this.additionalSetup = value;
            this.dirty = true;
        }
    }

    get programBinders() {
        return this.binders;
    }

    set programBinders(value) {
        if (!_.isEqual(this.binders, value)) {
            this.binders = value;
            this.dirty = true;
        }
    }

    get programAllowRegistrations() {
        return this.allowRegistrations;
    }

    set programAllowRegistrations(value) {
        if (!_.isEqual(this.allowRegistrations, value)) {
            this.allowRegistrations = value;
            this.dirty = true;
        }
    }

    get programAdminRegistration() {
        return this.adminRegistration;
    }

    set programAdminRegistration(value) {
        if (!_.isEqual(this.adminRegistration, value)) {
            this.adminRegistration = value;
            this.dirty = true;
        }
    }

    get programAutoClose() {
        return this.autoClose;
    }

    set programAutoClose(value) {
        if (!_.isEqual(this.autoClose, value)) {
            this.autoClose = value;
            this.dirty = true;
        }
    }

    get programRequireVaccine() {
        return this.requireVaccine;
    }

    set programRequireVaccine(value) {
        if (!_.isEqual(this.requireVaccine, value)) {
            this.requireVaccine = value;
            this.dirty = true;
        }
    }
}


//Instructor session implementation
export class InstructorSession extends WorkshopInstance {
    static isValidSession(session) {
        return !_.isNull(session) && !_.isUndefined(session) && !_.isEqual(this, NULL_INSTRUCTOR_SESSION) &&
            !_.isNull(session.workshopInstanceId) && !_.isNaN(session.workshopInstanceId) &&
            !_.isUndefined(session.workshopInstanceId) && 0 !== session.workshopInstanceId;
    }

    static create(instructorSession, asWorkspace) {
        return new InstructorSession(instructorSession, true === asWorkspace);
    }

    associations = null;
    workspace = null;

    constructor(instructorSession, asWorkspace) {
        super(instructorSession);
        this.associations = instructorSession.associations;
        this.workspace = asWorkspace ? NULL_INSTRUCTOR_SESSION : InstructorSession.create(instructorSession, true);
        this.sessionId = null;
    }

    get fields() {
        return [...super.fields, 'associations'];
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            if (_.isString(template[key])) {
                template[key] = trimToNull(template[key]);
            }
            return template;
        }, {});
    }

    clone() {
        return InstructorSession.create(this.cloneTemplate());
    }

    commit() {
        this.copyFrom(this.workspace);
        this.dirty = false;
    }

    rollback() {
        _.forEach(this.fields, key => {
            this.workspace[key] = this[key];
        });
        // this.workspace.copyFrom(this);
        this.dirty = false;
    }

    //Update fields to prepare for cancellation request
    cancel() {
        this.dirty = true;
        this.canceled = true;
    }

    get label() {
        if (_.isEqual(this, NULL_INSTRUCTOR_SESSION)) {
            return '';
        }
        const label = sprintf('SCT Instructor Session %s - %s%s',
            _.isNull(this.dates[0]) ? 'UNKNOWN' : formatters.date(this.dates[0], 'M/d/yyyy'),
            _.isNull(this.dates[1]) ? 'UNKNOWN' : formatters.date(this.dates[1], 'M/d/yyyy'),
            this.canceled ? ' (CLASS CANCELED)' : '');
        return label;
    }

    get availableSeatsLabel() {
        const label = sprintf('(%d of %d seats available)', this.availableSeats, this.maxSeats);
        return label;
    }

    allowRegistrationChanges(role) {
        switch (role) {
            case SecurityLevel.ADMINISTRATOR:
                return InstructorSession.isValidSession(this) && !this.canceled && !this.hasEnded &&
                    (!!this.allowRegistrations || !!this.adminRegistration);
            case SecurityLevel.INSTRUCTOR:
                return InstructorSession.isValidSession(this) && !this.canceled && !this.hasEnded &&
                    !!this.allowRegistrations && this.availableSeats > 0;
            default:
                return false;
        }
    }

    get hasErrors() {
        return _.any(this.sessionErrors);
    }

    get isNew() {
        return _.isNaN(this.workshopInstanceId) || this.workshopInstanceId === 0;
    }

    get disableDelete() {
        return this.associations;
    }

    get disableCancel() {
        return this.airfare;
    }

    get disableSave() {
        if (this.isNew) {
            //Check errors only for new session
            return this.workspace.hasErrors;
        }
        else {
            //Existing session needs to have changes...
            return !this.workspace.dirty ||
                //... and a valid ID...
                _.isNaN(this.workspace.workshopInstanceId) || 0 === this.workspace.workshopInstanceId ||
                //... and pass validation
                this.workspace.hasErrors;  //isDirty reviews workspace flags
        }
    }
}


//[Program] session implementation for track member sessions
export class Session extends WorkshopInstance {
    idx = null;
    trackId = null;
    mentorSession = null;

    static isValidSession(session) {
        return !_.isNull(session) && !_.isUndefined(session) && !_.isEqual(this, NULL_SESSION) &&
            !_.isNull(session.sessionId) && !_.isNaN(session.sessionId) &&
            !_.isUndefined(session.sessionId) && 0 !== session.sessionId;
    }

    get fields() {
        return [...super.fields, 'idx', 'trackId', 'mentorSession'];
    }

    static create(session) {
        return new Session(session);
    }

    constructor(session) {
        super(session);
        this.idx = session.idx;
        this.trackId = session.trackId;
        this.mentorSession = session.mentorSession;
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            if (_.isString(template[key])) {
                template[key] = trimToNull(template[key]);
            }
            return template;
        }, {});
    }

    clone() {
        return Session.create(this.cloneTemplate());
    }

    get label() {
        if (_.isEqual(this, NULL_SESSION)) {
            return 'Invalid Session';
        }
        else {
            const label = sprintf('SCT Program %s (%s - %s)%s',
                _.isNull(this.idx) ? 'UNKNOWN' : this.idx,
                _.isNull(this.dates[0]) ? 'UNKNOWN' : formatters.date(this.dates[0], 'M/d/yyyy'),
                _.isNull(this.dates[1]) ? 'UNKNOWN' : formatters.date(this.dates[1], 'M/d/yyyy'),
                this.canceled ? ' (CLASS CANCELED)' : '');
            return label;
        }
    }

    get programLabel() {
        if (_.isEqual(this, NULL_SESSION)) {
            return '';
        }
        else {
            const label = sprintf('SCT %sProgram %s%s',
                !!this.mentorSession ? 'Mentor ' : '',
                _.isNull(this.idx) ? 'UNKNOWN' : this.idx,
                this.canceled ? ' (CLASS CANCELED)' : '');
            return label;
        }
    }

    get availableSeatsOnlyLabel() {
        if (_.isEqual(this, NULL_SESSION)) {
            return '';
        }
        else {
            const label = sprintf('Available seats: %s',
                _.isNaN(this.availableSeats) ? 'UNKNOWN' : this.availableSeats);
            return label;
        }
    }

    get availableSeatsLabel() {
        if (_.isEqual(this, NULL_SESSION)) {
            return 'Invalid Session';
        }
        else {
            const label = sprintf('SCT Program %s (%s - %s) (%s)',
                _.isNull(this.idx) ? 'UNKNOWN' : this.idx,
                _.isNull(this.dates[0]) ? 'UNKNOWN' : formatters.date(this.dates[0], 'M/d/yyyy'),
                _.isNull(this.dates[1]) ? 'UNKNOWN' : formatters.date(this.dates[1], 'M/d/yyyy'),
                this.canceled ? 'CLASS CANCELED' : this.availableSeatsOnlyLabel);
            return label;
        }
    }

    allowRegistrationChanges(role) {
        switch (role) {
            case SecurityLevel.ADMINISTRATOR:
                return Session.isValidSession(this) && !this.hasEnded && (!!this.allowRegistrations || !!this.adminRegistration);
            case SecurityLevel.MENTOR:
                return Session.isValidSession(this) && !this.hasEnded && !!this.allowRegistrations && this.availableSeats > 0;
            default:
                return false;
        }
    }

    get hasErrors() {
        return _.any(this.sessionErrors);
    }

    get isNew() {
        return _.isNaN(this.sessionId) || this.sessionId === 0;
    }
}


export const NULL_SESSION = Session.create({
    sessionId: NaN,
    trackId: NaN,
    mentorSession: false
});

export const NULL_INSTRUCTOR_SESSION = InstructorSession.create({
    workshopInstanceId: NaN
});

export const NULL_SESSION_P1 = Session.create({
    sessionId: NaN,
    trackId: NaN,
    idx: 1,
    mentorSession: false
});

export const NULL_SESSION_P2 = Session.create({
    sessionId: NaN,
    trackId: NaN,
    idx: 2,
    mentorSession: false
});

export const NULL_SESSION_P3 = Session.create({
    sessionId: NaN,
    trackId: NaN,
    idx: 3,
    mentorSession: false
});

export const NULL_SESSION_P4 = Session.create({
    sessionId: NaN,
    trackId: NaN,
    idx: 4,
    mentorSession: false
});

export const NULL_MENTOR_SESSION_P1 = Session.create({
    sessionId: NaN,
    trackId: NaN,
    idx: 1,
    mentorSession: true
});

export const NULL_MENTOR_SESSION_P3 = Session.create({
    sessionId: NaN,
    trackId: NaN,
    idx: 3,
    mentorSession: true
});

export function getNullSession(idx, mentorSession) {
    switch (idx) {
        case 1:
            return mentorSession ? NULL_MENTOR_SESSION_P1 : NULL_SESSION_P1;
        case 2:
            return NULL_SESSION_P2;
        case 3:
            return mentorSession ? NULL_MENTOR_SESSION_P3 : NULL_SESSION_P3;
        case 4:
            return NULL_SESSION_P4;
        default:
            return NULL_SESSION;
    }
}