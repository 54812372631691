import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import router from '@/router';
import store from '@/store/store';
import FontAwesomeIcon from '@/icons';
import Component from 'vue-class-component';

import AsyncComputed from 'vue-async-computed';

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(AsyncComputed);

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate'
]);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
