import {NULL_USER} from '@/model/user';
import commonDao from '@/dao/common_dao';
import _ from 'underscore';
import userDao from "../dao/user_dao";

export const TraineeStore = {
    namespaced: true,

    state: {
        trainees: [],
        trainee: NULL_USER,
        statuses: [],
        //Map - key = String(trainee.id), val = Boolean(isBehind)
        traineeBehind: {}
    },

    getters: {
        trainees(state) {
            return state.trainees;
        },

        trainee(state) {
            return state.trainee;
        },

        statuses(state) {
            return state.statuses;
        },

        statusById: (state) => (id) => {
            return _.find(state.statuses, (s) => s.id === id);
        },

        isTraineeBehind: (state) => (traineeId) => {
            const trainee = String(traineeId);
            return state.traineeBehind[trainee];
        }
    },

    mutations: {
        setTrainees: (state, trainees) => {
            state.trainees = trainees;
        },

        setTrainee: (state, trainee) => {
            state.trainee = trainee;
        },

        setStatuses: (state, statuses) => {
            state.statuses = statuses;
        },

        setTraineeBehind: (state, result) => {
            state.traineeBehind[result.trainee] = result.isBehind;
        }
    },

    actions: {
        loadTraineeStatuses: async (context) => {
            if (context.state.statuses.length < 1) {
                const statuses = await commonDao.getTraineeStatuses();
                //Hack because the db has whack data
                const filteredStatuses = _.filter(statuses, s => {
                    const status = s.status.toUpperCase();
                    return !_.isEqual(status, 'APPLIED') && !_.isEqual(status, 'DECLINED');
                });
                context.commit('setStatuses', filteredStatuses);
            }
        },

        isTraineeBehind: async ({commit}, traineeId) => {
            const behind = await userDao.isTraineeBehind(traineeId);
            commit('setTraineeBehind', {
                trainee: String(traineeId),
                isBehind: behind
            });
        },

        loadTraineesForMentor: async({commit}, mentorId) => {
            const trainees = await userDao.getTraineesForMentor(mentorId);
            commit('setTrainees', trainees);
        }
    }
};

export default TraineeStore;
