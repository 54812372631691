import _ from "underscore";
import formatters, {trimToNull} from '@/util/formatters';
import {isAfter, isBefore, addDays} from 'date-fns';


export class Notification {
    id = null;
    note = null;
    publishDate = null;
    createdDate = null;
    createdBy = null;
    createdByUsername = null;
    updatedDate = null;
    updatedBy = null;
    updatedByUsername = null;
    deleted = false;

    static isValid(notification) {
        //Notification must have a valid, non-zero ID (unless new)
        return !_.isNull(notification) && !_.isUndefined(notification) &&
            !_.isNull(notification.id) && !_.isUndefined(notification.id) &&
            !_.isEqual(notification.id, 0);
    }

    constructor(notification) {
        this.copyFrom(notification, this.fields);
    }

    get fields() {
        return [
            'id', 'note', 'publishDate', 'createdDate', 'createdBy', 'createdByUsername', 'updatedDate', 'updatedBy',
            'updatedByUsername', 'deleted'
        ];
    }

    copyFrom(notification, fields) {
        _.each(fields, key => {
            switch (key) {
                case 'publishDate':
                case 'createdDate':
                case 'updatedDate':
                    this[key] = !!notification[key] ? formatters.mkDate(notification[key]) : null;
                    break;
                default:
                    this[key] = notification[key];
                    break;
            }
        });
    }

    get isNew() {
        return this.id === 0;
    }

    get hasUpdate() {
        return !this.isNew && _.isDate(this.updatedDate) && !_.isEmpty(this.updatedByUsername) && this.updatedBy > 0;
    }

    serialize() {
        return _.reduce(this.fields, (json, f) => {
            switch (f) {
                case 'publishDate':
                case 'expirationDate':
                case 'createdDate':
                case 'updatedDate':
                    json[f] = _.isDate(this[f]) ? this[f].toString() : null;
                    break;
                default:
                    json[f] = this[f];
                    break;
            }
            return json;
        }, {});
    }
}


export class UserNotification extends Notification {
    subject = null;
    expirationDate = null;
    workspace = null;

    static create(userNotification, asWorkspace) {
        return new UserNotification(userNotification, true === asWorkspace);
    }

    constructor(userNotification, asWorkspace) {
        super(userNotification);
        this.subject = userNotification.subject;
        this.expirationDate = !!userNotification.expirationDate ? formatters.mkDate(userNotification.expirationDate) : null;
        this.workspace = asWorkspace ? NULL_USER_NOTIFICATION :
            UserNotification.create(userNotification, true);
    }

    get fields() {
        return [...super.fields, 'subject', 'expirationDate'];
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            if (_.isString(template[key])) {
                template[key] = trimToNull(template[key]);
            }
            return template;
        }, {});
    }

    clone() {
        return UserNotification.create(this.cloneTemplate());
    }

    commit() {
        this.copyFrom(this.workspace);
    }

    rollback() {
        _.forEach(this.fields, key => {
            this.workspace[key] = this[key];
        });
    }

    isPublished() {
        if (_.isNull(this.publishDate)) {
            return false;
        }
        else {
            const now = new Date().getTime();
            const isPublished = isBefore(this.publishDate, now);
            return isPublished;
        }
    }

    isExpired(isAdmin = false) {
        if (_.isNull(this.expirationDate)) {
            return false;
        }
        else {
            //Admin users have additional 7-day grace period in expiration
            const expiration = isAdmin ? addDays(this.expirationDate, 7) : this.expirationDate;
            const now = new Date().getTime();
            const isExpired = isAfter(now, expiration);
            return isExpired;
        }
    }

    get errors() {
        return {
            subject: _.isEmpty(this.subject),
            note: _.isEmpty(this.note),
            publishDate: !_.isDate(this.publishDate),
            expirationDate: !_.isDate(this.expirationDate) || !isAfter(this.expirationDate, this.publishDate)
        };
    }
}


export class ReleaseNote extends Notification {
    releaseVersion = null;
    workspace = null;

    static create(releaseNote, asWorkspace) {
        return new ReleaseNote(releaseNote, true === asWorkspace);
    }

    constructor(releaseNote, asWorkspace) {
        super(releaseNote);
        this.releaseVersion = releaseNote.releaseVersion;
        this.workspace = asWorkspace ? NULL_RELEASE_NOTE : ReleaseNote.create(releaseNote, true);
    }

    get fields() {
        return [...super.fields, 'releaseVersion'];
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            if (_.isString(template[key])) {
                template[key] = trimToNull(template[key]);
            }
            return template;
        }, {});
    }

    clone() {
        return ReleaseNote.create(this.cloneTemplate());
    }

    commit() {
        this.copyFrom(this.workspace);
    }

    rollback() {
        _.forEach(this.fields, key => {
            this.workspace[key] = this[key];
        });
    }

    get errors() {
        return {
            version: _.isEmpty(this.releaseVersion),
            note: _.isEmpty(this.note),
            publishDate: !_.isDate(this.publishDate)
        };
    }
}


export const NULL_USER_NOTIFICATION = UserNotification.create({id: 0});
export const NULL_RELEASE_NOTE = ReleaseNote.create({id: 0});
