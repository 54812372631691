import _ from 'underscore';
import { NULL_TRACK, NULL_PROGRAM, Track } from '@/model/track';
import trackDao from '@/dao/track_dao';
import {add, format} from 'date-fns';

export const TrackStore = {
    namespaced: true,

    state: {
        tracks: [],
        programs: [],
        program1s: [],
        templateTrack: NULL_TRACK
    },

    getters: {
        getTemplateTrack(state) {
            return state.templateTrack;
        },

        getTracks(state) {
            const tracks = state.tracks;
            return tracks;
        },

        getTrackById: (state) => (id) => {
            return _.find(state.tracks, (t) => t.id === id) || null;
        },

        getSessionById: (state) => (sessionId) => {
            //Flatten tracks
            const sessions = _.reduce(state.tracks, (memo, track) => {
                for (let key of Track.programKeys()) {
                    memo.push(track[key]);
                }
                return memo;
            }, []);
            const session = _.findWhere(sessions, {sessionId: sessionId});
            if (_.isUndefined(session)) {
                console.log('No session found for ID', sessionId);
                return null;
            }
            else {
                return session;
            }
        },

        //Get mentor session for trainee session and vice versa
        getAssociatedSession: (state) => (session) => {
            if (!(session || {}).trackId) {
                return null;
            }

            const findIdx = session.idx;
            const findMentorSession = !session.mentorSession;
            if (findMentorSession && findIdx % 2 === 0) {
                console.log('No mentor session available for idx', findIdx);
            }
            const track = _.findWhere(state.tracks, {id: session.trackId});
            const key = sprintf('%srogram%d', findMentorSession ? 'mentorP' : 'p', findIdx);
            return track[key];
        },

        getPrograms(state) {
            return state.programs;
        },

        getProgram1s: (state) => {
            return state.program1s;
        },

        getProgramByIndex: (state) => (idx) => {
            return _.find(state.programs, (p) => p.name.endsWith(idx + '')) || NULL_PROGRAM;
        },

        getProgramsByYear: (state) => (year) => {
            const validTracks = _.filter(state.tracks, track => {
                return !track.canceled;
            });
            return _.chain(_.union(
                _.map(validTracks, (t) => t.program1),
                _.map(validTracks, (t) => t.program2),
                _.map(validTracks, (t) => t.program3),
                _.map(validTracks, (t) => t.program4)
            ))
                .filter((program) => {
                    return _.any(program.dates, (d) => d.getFullYear() === year);
                })
                .sortBy((program) => program.dates[0])
                .value();
        },

        getProgram1sByYear: (state) => (year) => {
            const program1s = _.chain(state.tracks)
                .filter((track) => {
                    return !track.canceled && !track.hasCanceledSessions;
                })
                .map((track) => {
                    return track.program1;
                })
                .filter((program) => {
                    return _.any(program.dates, (d) => d.getFullYear() === year);
                })
                .sortBy((program) => program.dates[0])
                .value();
            return program1s;
        },

        //Includes past dates (for reporting)
        getProgramsNoYear: (state) => {
            const programs = _.chain(_.union(
                _.map(state.tracks, (t) => t.program1),
                _.map(state.tracks, (t) => t.program2),
                _.map(state.tracks, (t) => t.program3),
                _.map(state.tracks, (t) => t.program4)
            ))
                .sortBy((program) => program.dates[0])
                .value();
            return programs;
        },

        getProgram1sNoYear: (state) => {
            //This getter does not include filtering for non-admin users, only used for admin "Applications" route
            return _.chain(state.tracks)
                .filter(track => !track.canceled)
                .pluck('program1')
                .filter(session => !!session.sessionId && 1 === session.idx && !session.canceled)
                .sortBy(program => program.dates[0])
                .value();
        },

        //Includes past dates (for reporting)
        getMentorProgramsNoYear: (state) => {
            return _.chain(_.union(
                _.map(state.tracks, (t) => t.mentorProgram1),
                _.map(state.tracks, (t) => t.mentorProgram3)
            ))
                .sortBy((program) => program.dates[0])
                .value();
        }
    },

    mutations: {
        setTemplateTrack: (state, templateTrack) => {
            state.templateTrack = templateTrack;
        },

        setTracks: (state, tracks) => {
            state.tracks = tracks;
        },

        setPrograms: (state, programs) => {
            state.programs = programs;
        },

        setProgram1s: (state, program1s) => {
            state.program1s = program1s;
        },

        rollback: (state, trackId) => {
            const track = _.find(state.tracks, (t) => {
                return t.id === trackId;
            });
            if (!!track) {
                track.rollback();
                track.disableEditMode();
            }
        },

        addTrack: (state, track) => {
            if (null === track) {
                return;
            }
            const index = _.findIndex(state.tracks, (t) => t.id === track.id);
            //Remove track if it already exists
            if (index >= 0) {
                state.tracks.splice(index, 1);
            }
            state.tracks.push(track);
        },

        removeTrack: (state, track) => {
            if (null === track) {
                return;
            }
            const index = _.findIndex(state.tracks, (t) => {
                return _.isEqual(t.id, track.id);
            });
            //Remove track if it already exists
            if (index >= 0) {
                state.tracks.splice(index, 1);
            }
        }
    },

    actions: {
        saveTrack: async ({commit}, track) => {
            const updatedTrack = await trackDao.saveTrack(track.workspace);
            updatedTrack.commit();
            commit('addTrack', updatedTrack);
        },

        cancelTrack: async ({commit}, track) => {
            await trackDao.cancelTrack(track);
            commit('addTrack', track);  //Re-add cancelled track
        },

        deleteTrack: async ({commit}, track) => {
            await trackDao.deleteTrack(track);
            commit('removeTrack', track);
        },

        loadTracks: async (context) => {
            //Used for reactivity, do not cache
            const tracks = await trackDao.getTracks();
            context.commit('setTracks', tracks);
        },

        //Loads program details (beyond static model)
        loadPrograms: async ({commit}) => {
            const programs = await trackDao.getPrograms();
            commit('setPrograms', programs);
        },

        //Loads program 1 sessions with limited model (ID, label)
        loadProgram1s: async ({commit}) => {
            const now = format(new Date(), 'yyyy-MM-dd');
            const program1s = await trackDao.getProgramOnes('session', 'false', now, 'ASC');
            commit('setProgram1s', program1s);
        },

        loadTemplateTrack: async (context) => {
            //Only need to load once
            if (_.isEqual(context.state.templateTrack, NULL_TRACK)) {
                const program1 = await trackDao.getSessionTemplate(1, false);
                const program2 = await trackDao.getSessionTemplate(2, false);
                const program3 = await trackDao.getSessionTemplate(3, false);
                const program4 = await trackDao.getSessionTemplate(4, false);
                const mentorProgram1 = await trackDao.getSessionTemplate(1, true);
                const mentorProgram3 = await trackDao.getSessionTemplate(3, true);
                const templateTrack = Track.create({
                    id: 0,
                    program1: program1,
                    program2: program2,
                    program3: program3,
                    program4: program4,
                    mentorProgram1: mentorProgram1,
                    mentorProgram3: mentorProgram3,
                    workspace: true
                });
                context.commit('setTemplateTrack', templateTrack);
            }
        },

        loadTrack: async({commit}, trackId) => {
            const track = await trackDao.getTrackById(trackId);
            commit('addTrack', track);
        }
    }
};

export default TrackStore;