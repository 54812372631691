import {NULL_USER, SearchCriteria} from '@/model/user';
import _ from 'underscore';
import userDao from '@/dao/user_dao';
import commentDao from '@/dao/comment_dao';

export const UserStore = {
    namespaced: true,

    state: {
        users: [],
        user: NULL_USER,
        userStatuses: [],
        traineeStatuses: [],
        //Constant based on selection from disabled statuses from db values to describe declining formerly
        traineeDisabledStatuses: [{id: 2, status: 'On Hold'}, {id: 3, status: 'Withdrawn'}, {id: 4, status: 'Dropped'}],
        mentors: [],
        admins: [],
        //Trainees available for modification by current user
        availableTrainees: [],
        searchCriteria: new SearchCriteria(),
        //Object "map" of mentor IDs and past trainee details
        //key = String(mentor.userProfileId), value = Trainee
        pastTraineeDetailsByMentor: {},
    },

    getters: {
        users(state) {
            return state.users;
        },

        user(state) {
            return state.user;
        },

        statuses(state) {
            return state.userStatuses;
        },

        traineeStatuses(state) {
            return state.traineeStatuses;
        },

        traineeDisabledStatuses(state) {
            return state.traineeDisabledStatuses;
        },

        status: (state) => (id) => {
            return _.find(state.userStatuses, (s) => s.id === id);
        },

        trainees: (state) => (mentor) => {
            if (!mentor.isAMentor()) {
                return [];
            }
            return _.filter(state.users, (u) => {
                return u.isATrainee() && _.contains(mentor.traineeIds, u.id);
            });
        },

        mentors: (state) => {
            return state.mentors;
        },

        admins: (state) => {
            return state.admins;
        },

        availableTrainees: (state) => {
            return state.availableTrainees;
        },

        searchCriteria: (state) => {
            return state.searchCriteria;
        },

        pastTraineeDetails: (state) => (mentorId) => {
            const mentorKey = String(mentorId);
            if (_.has(state.pastTraineeDetailsByMentor, mentorKey)) {
                return state.pastTraineeDetailsByMentor[mentorKey];
            }
            return null;
        }
    },

    mutations: {
        setUser: (state, user) => {
            state.user = user;
            if (user.isNewUser()) {
                return;
            }
            const idx = _.findIndex(state.users, (u) => u.id === user.id);
            if (idx < 0) {
                state.users.push(user);
            }
            else {
                state.users[idx] = user;
            }
        },

        setUsers: (state, users) => {
            state.users = users;
        },

        setUserStatuses: (state, userStatuses) => {
            state.userStatuses = userStatuses;
        },

        setTraineeStatuses: (state, traineeStatuses) => {
            state.traineeStatuses = traineeStatuses;
        },

        setMentors: (state, mentors) => {
            state.mentors = mentors;
        },

        setAdmins: (state, admins) => {
            state.admins = admins;
        },

        setAvailableTrainees: (state, trainees) => {
            state.availableTrainees = trainees;
        },

        setPastTraineeDetails: (state, result) => {
            if (_.has(state.pastTraineeDetailsByMentor, result.mentorId)) {
                delete state.pastTraineeDetailsByMentor[result.mentorId];
            }
            state.pastTraineeDetailsByMentor[result.mentorId] = result.trainee;
        }
    },

    actions: {
        search: async ({commit}, criteria) => {
            const results = await userDao.search(criteria);
            commit('setUsers', results.users);
            return {
                limited: results.limited,
                size: results.users.length
            };
        },

        loadUser: async ({commit}, id) => {
            const user = await userDao.getUserById(id);
            commit('setUser', user);
            return user;
        },

        saveUser: async ({commit}, user) => {
            const savedUser = await userDao.save(user);
            commit('setUser', savedUser);
            return savedUser;
        },

        loadUserStatuses: async ({commit}) => {
            const userStatuses = await userDao.getUserStatuses();
            commit('setUserStatuses', userStatuses);
        },

        loadTraineeStatuses: async (context) => {
            //TODO - deprecate, use the version in trainee store instead
            if (context.state.traineeStatuses.length < 1) {
                const traineeStatuses = await userDao.getTraineeStatuses();
                context.commit('setTraineeStatuses', traineeStatuses);
            }
        },

        loadMentors: async ({commit}) => {
            const mentors = await userDao.getMentors();
            commit('setMentors', mentors);
        },

        loadAdmins: async ({commit}) => {
            const admins = await userDao.getAdmins();
            commit('setAdmins', admins);
        },

        loadAvailableTrainees: async ({commit}) => {
            const trainees = await userDao.getAvailableTrainees();
            commit('setAvailableTrainees', trainees);
        },

        getComments: async ({}, userId) => {
            return await commentDao.getComments(userId, 'user');
        },

        saveComments: async({}, comment) => {
            return await commentDao.saveComment(comment.cloneTemplate(), 'user');
        },

        pastTraineeDetails: async ({commit}, mentorId) => {
            const trainee = await userDao.getPastTraineeDetailsForMentor(mentorId);
            const result = {
                mentorId: String(mentorId),
                trainee: trainee
            };
            commit('setPastTraineeDetails', result);
        }
    }
};

export default UserStore;
