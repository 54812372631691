<template>
    <div>
        <loading-shade/>
        <div id="app" class="container-fluid">
            <b-toast id="logout-detected" variant="danger" solid @hidden="redirectLogout">
                <div slot="toast-title"><strong>Not Logged In</strong></div>
                Your session has timed out or you have been logged out from another location.
            </b-toast>
            <Header/>
            <div></div>
            <router-view/>
            <div class="clearfix">
                <div class="version-text float-left">
                    &copy; {{copyrightYear}} Carpenters International Training Fund
                    <template v-if="!appInfo.isProd"> - Branch: {{appBranch}}</template>
                </div>
                <div class="version-text float-right">CITF Superintendent Career Training v{{appVersion}} - {{hostname}}</div>
            </div>
        </div>
    </div>
</template>
<script>

import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/shared/Header.vue';
import store from '@/store/store';
import {NULL_USER} from '@/model/user';
import {api, setApiStore} from '@/dao/api';
import LoadingShade from '@/components/shared/LoadingShade.vue';
import {date, errorToastOptions} from '@/util/formatters';

setApiStore(store);

@Component({
    components: {Header, LoadingShade}
})
export default class App extends Vue {
    get appInfo() {
        return this.$store.getters.appInfo;
    }

    get copyrightYear() {
        return date(new Date(), 'yyyy');
    }

    get appVersion() {
        return this.appInfo.version;
    }

    get appBranch() {
        return this.appInfo.branch;
    }

    get hostname() {
        return this.appInfo.hostname;
    }

    // Some data we need to load before anything can happen
    async created() {
        try {
            await store.dispatch('tracks/loadPrograms');
            await store.dispatch('loadAppInfo');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }

        api().registerLogoutListener(() => {
            this.$bvToast.show('logout-detected');
        });
    }

    redirectLogout() {
        this.$store.commit('userSession/setUser', NULL_USER);
        this.$router.push({name: 'root'});
        document.location.reload();
    }
}
</script>
<style lang="scss">
    #app {
        font-family: sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    h1 {
        margin-top: 40px;
        color: #A28058;
        text-transform: uppercase;
        font-size: 24px;
    }

    .home-content {
        text-align: justify;
        width: 90%;
        margin: 20px auto !important;
    }

    ul.top-menu a, ul.top-menu button {
        color: #A28058 !important;
    }

    ul.top-menu button {
        margin: 0;
        padding: 0;
    }

    ul.top-menu a:hover {
        color: #141928 !important;
    }

    div.about-card {
        border: 0;
        cursor: pointer;
    }

    h1.about-section {
        cursor: pointer;
    }

    div.banner {
        position: relative;
    }

    div.banner img {
        width: 100%;
        margin-bottom: 20px;
    }

    div.banner-text {
        color: #FFF;
        font-size: 36px;
        font-weight: bold;
        position: absolute;
        left: 20px;
        top: 20px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    table.table-primary tr:hover {
        color: #FFF !important;
    }

    tr.table-info:hover {
        color: #FFF !important;
    }

    tr.table-info a {
        color: #FFF !important;
    }

    ul {
        list-style-type: '\00BB';
    }

    ul li {
        padding-left: 5px;
    }

    label.required:before {
        content: '* ';
        font-weight: bold;
        color: #F00 !important;
    }

    div.pic-button {
        text-align: center;
    }

    .table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
        width: 30%;
    }

    div.version-text {
        font-size: 9px;
    }

    /* Sticky Notes */
    .sticky {
        position:               relative;
        background-color:       #F4F39E;
        border-color:           #DEE184;
        text-align:             left;
        margin:                 2.5em 0;
        padding:                25px 25px 25px 30px;
        -webkit-box-shadow:     0 1px 3px rgba(0,0,0,0.25);
        -moz-box-shadow:        0 1px 3px rgba(0,0,0,0.25);
        box-shadow:             0 1px 3px rgba(0,0,0,0.25);
    }


    .sticky.taped:after {
        display:                block;
        content:                "";
        position:               absolute;
        width:                  110px;
        height:                 30px;
        top:                    -21px;
        left:                   30%;
        border:                 1px solid #FFF;
        background:             rgba(254, 254, 254, .6);
        -webkit-box-shadow:     0 0 3px rgba(0,0,0,0.1);
        -moz-box-shadow:        0 0 3px rgba(0,0,0,0.1);
        box-shadow:             0 0 3px rgba(0,0,0,0.1);
    }

    td.fc-day-number-selected {
        background-color: #FF0 !important;
    }

    div.fc-day-number-icon {
        background-image: url('assets/glyphicons-halflings.png');
        display: inline-block;
        background-repeat: no-repeat;
        width:  14px;
        height: 14px;
        margin: 3px;
    }

    div.fc-day-number-check-in {
        background-position: -144px -71px;
    }

    div.fc-day-number-check-out {
        background-position: -120px -71px;
    }

    div.fc-day-number-flight {
        background-position: -168px -119px;
    }

    div.icons {
        text-align: center !important;
    }

    .mx-datepicker {
        width: 100% !important;
    }

</style>
