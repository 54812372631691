import userDao from "@/dao/user_dao";
import {NULL_MEMBER} from "@/model/member";
import memberDao from "../dao/member_dao";

export const MentorStore = {
    namespaced: true,

    state: {
        userMentors: [],
        memberMentors: [],
        mentor: NULL_MEMBER
    },

    getters: {
        mentorsAsUsers(state) {
            return state.userMentors;
        },
        mentorsAsMembers(state) {
            return state.memberMentors;
        },
        mentor(state) {
            return state.mentor;
        }
    },

    mutations: {
        setUserMentors: (state, mentors) => {
            state.userMentors = mentors;
        },
        setMemberMentors: (state, mentors) => {
            state.memberMentors = mentors;
        },
        setMentor: (state, mentor) => {
            state.mentor = mentor;
        }
    },

    actions: {
        loadUserMentors: async ({commit}) => {
            //TODO - this is in use, but deprecate and instead use version in user store
            const mentors = await userDao.getMentors();
            commit('setUserMentors', mentors);
        },
        loadMemberMentors: async ({commit}) => {
            const mentors = await memberDao.getMentors();
            commit('setMemberMentors', mentors);
        },
        loadMentor: async({commit}, mentorId) => {
            const mentor = await userDao.getUserById(mentorId);
            commit('setMentor', mentor);
        }
    }
};

export default MentorStore;
