import {Program} from "@/model/track";
import _ from "underscore";
import {UserFactory} from "@/model/user";
import {mkDate} from '@/util/formatters';
import {sprintf} from "sprintf-js";
import {WorkshopInstanceFactory} from "@/model/session";
import {InstructorSession} from "./session";


//Model for app registration types (for endpoint selection)
export const RegistrationRequestType = {
    SESSION_REGISTRATION: 'SESSION_REGISTRATION',
    INSTRUCTOR_SESSION_REGISTRATION: 'INSTRUCTOR_SESSION_REGISTRATION'
};


//Model for CITFReg registration types
export const Type = {
    DEVELOPER: 'DEVELOPER',
    INSTRUCTOR: 'INSTRUCTOR',
    PARTICIPANT: 'PARTICIPANT'
};

export function getRegistrationType(type) {
    if (_.isEmpty(type)) {
        return Type.PARTICIPANT;
    }
    if (_.has(Type, type.toUpperCase())) {
        return type.toUpperCase();
    }
    //Default
    return Type.PARTICIPANT;
}


//Model for session status
export const Status = {
    SCHEDULED: 'SCHEDULED',
    REGISTERED: 'REGISTERED',
    COMPLETED: 'COMPLETED',
    INCOMPLETE: 'INCOMPLETE',
    CANCELED: 'REG CANCELED',
    DROPPED: 'DROPPED',
    INVALID: 'INVALID'
};

export function getStatus(status) {
    if (_.isEmpty(status)) {
        return Status.INVALID;
    }
    if (_.has(Status, status.toUpperCase())) {
        return status.toUpperCase();
    }
    //Default
    return Status.INVALID;
}

Status.variant = function(status) {
    switch (status) {
        case this.SCHEDULED:
            return 'warning';
        case this.REGISTERED:
            return 'light';
        case this.COMPLETED:
            return 'success';
        case this.INCOMPLETE:
        case this.CANCELED:
        case this.DROPPED:
            return 'danger';
        case this.INVALID:
        default:
            return 'dark';
    }
};


export class RegistrationFactory {
    static create(base) {
        if (_.isEmpty(base)) {
            return null;
        }
        //Trainee user ID is unique to SessionRegistration
        else if (_.has(base, "traineeUserId")) {
            return new SessionRegistration(base);
        }
        //No field is unique to InstructorSessionRegistration
        else {
            return new InstructorSessionRegistration(base);
        }
    }
}


//Model for SCT trainee/mentor session concept
export class Registration {
    static create(registration) {
        return new Registration(registration);
    }

    id = null;
    userProfileId = null;
    attendeeId = null;
    tripId = null;
    sessionId = null;
    workshopInstanceId = null;
    registeringUserId = null;
    complete = null;
    incomplete = null;
    canceled = null;
    cancelable = null;
    declinedTravel = null;
    locked = null;
    confirmationCode = null;
    comments = null;
    type = null;
    vaccineOverride = null;

    constructor(registration) {
        return this.copyFrom(registration, this.fields);
    }

    get fields() {
        return ['id', 'userProfileId', 'attendeeId', 'tripId', 'sessionId', 'workshopInstanceId', 'registeringUserId',
            'complete', 'incomplete', 'canceled', 'cancelable', 'declinedTravel', 'locked', 'confirmationCode',
            'comments', 'type', 'vaccineOverride'];
    }

    copyFrom(registration, fields) {
        _.each(fields, (key) => {
            switch (key) {
                case 'complete':
                case 'incomplete':
                    this[key] = _.isNull(registration[key]) || _.isUndefined(registration[key]) ? null : mkDate(registration[key]);
                    break;
                case 'type':
                    this[key] = getRegistrationType(registration[key]);
                    break;
                default:
                    this[key] = registration[key];
            }
        });
    }

    static isValidRegistration(registration) {
        return !_.isNull(registration) && !_.isUndefined(registration) && !_.isEmpty(registration) &&
            !_.isNull(registration.id) && !_.isUndefined(registration.id) && !_.isNaN(registration.id);
    }

    static status(registration) {
        //Old registrations will have ID = 0
        if (!this.isValidRegistration(registration) || _.isEqual(registration, NEW_SESSION_REGISTRATION) ||
            _.isEqual(registration, NEW_INSTRUCTOR_SESSION_REGISTRATION)) {
            return Status.SCHEDULED;
        }
        if (registration.canceled) {
            return Status.CANCELED;
        }
        const hasComplete = !!registration.complete;
        const hasIncomplete = !!registration.incomplete;
        if (!hasComplete && !hasIncomplete) {
            return Status.REGISTERED;
        }
        if (hasComplete) {
            return Status.COMPLETED;
        }
        if (hasIncomplete) {
            return Status.INCOMPLETE;
        }
        return Status.INVALID;
    }

    //Parse times so FE to BE communication doesn't cast and auto-convert timezone from local to UTC
    serialize() {
        if (_.isDate(this.complete)) {
            this.complete = this.complete.toString();
        }
        if (_.isDate(this.incomplete)) {
            this.incomplete = this.incomplete.toString();
        }
    }
}


export class SessionRegistration extends Registration {
    static create(sessionRegistration) {
        return new SessionRegistration(sessionRegistration);
    }

    traineeUserId = null;
    traineeTrackId = null;
    sessionTrackId = null;
    program = null;
    registered = null;

    constructor(sessionRegistration) {
        super(sessionRegistration);
        this.traineeUserId = sessionRegistration.traineeUserId;
        this.traineeTrackId = sessionRegistration.traineeTrackId;
        this.sessionTrackId = sessionRegistration.sessionTrackId;
        this.program = _.isEmpty(sessionRegistration.program) ? null :
            Program.create(sessionRegistration.program, _.isEqual(this.userProfileId, this.traineeUserId) ? false : true);
    }

    get fields() {
        return [...super.fields, 'traineeUserId', 'traineeTrackId', 'sessionTrackId', 'program', 'registered'];
    }
}


export class InstructorSessionRegistration extends Registration {
    static create(instructorSessionRegistration) {
        return new InstructorSessionRegistration(instructorSessionRegistration);
    }

    constructor(instructorSessionRegistration) {
        super(instructorSessionRegistration);
    }
}


export class RegistrationRequest {
    user = null;
    session = null;
    registration = null;

    constructor(user, session, registration) {
        this.user = _.isNull(user) ? null : UserFactory.create(user);
        this.session = _.isNull(session) ? null : WorkshopInstanceFactory.create(session);
        this.registration = _.isNull(registration) ? null : RegistrationFactory.create(registration);
    }

    serialize() {
        this.session.serialize();
    }

    //For use when cancelling a request that has been assembled by the UI
    reset() {
        //Reset registration for falsy ID; past registrations with ID = 0 are view-only
        if (!this.registration || !this.registration.id) {
            this.registration = this.session instanceof InstructorSession ?
                NEW_INSTRUCTOR_SESSION_REGISTRATION :
                NEW_SESSION_REGISTRATION;
        }
        //Remove override flag (confirm each time)
        this.registration.vaccineOverride = false;
    }

    get needsOverride() {
        return !!this.user && !!this.session && !this.user.vaccineVerified && this.session.requireVaccine;
    }

    get status() {
        return Registration.status(this.registration);
    }

    get hasRegistrationId() {
        return _.isNumber(this.registration.id) && this.registration.id > 0;
    }
}


export const NEW_SESSION_REGISTRATION = new SessionRegistration({id: 0});
export const NEW_INSTRUCTOR_SESSION_REGISTRATION = new InstructorSessionRegistration({id: 0});