import {sprintf} from 'sprintf-js';
import {trimToNull} from '@/util/formatters';
import _ from 'underscore';

export class Phone {

    static create(phone) {
        return new Phone(phone);
    }

    constructor(phone) {
        this.id = phone.id;
        this.number = phone.number;
        this.ext = phone.ext;
    }

    static get REGEX() {
        return /^(\d{3})(\d{3})(\d{4})(\d{0,5})$/
    }

    get formatted() {
        return this.isValid ?
            sprintf('%s%s',
                this.number.replace(Phone.REGEX, '($1) $2-$3'),
                !!this.ext ? ' x' + this.ext : ''
            ) : '';
    }

    set formatted(number) {

        const num = trimToNull(number);

        if (null === num) {
            this.number = null;
            this.ext = null;
            return;
        }

        const matches = num.match(Phone.REGEX);

        if (!matches) {
            return;
        }

        this.number = matches.slice(1, 4).join('');
        this.ext = trimToNull(matches[4]);
    }

    get isValid() {
        return !_.isEmpty(this.number) && Phone.REGEX.test(this.number);
    }

    cloneTemplate() {
        return {
            id: this.id,
            number: this.number,
            ext: this.ext
        };
    }

    clone() {
        return Phone.create(this.cloneTemplate());
    }
}

export const NULL_PHONE = Phone.create({
    id: NaN,
    number: null,
    ext: null
});

export const NEW_PHONE = Phone.create({
    id: 0,
    number: null,
    ext: null
});