import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'underscore';
import UserSessionStore from '@/store/user_session_store';
import UserStore from '@/store/user_store';
import TraineeStore from '@/store/trainee_store';
import MentorStore from '@/store/mentor_store';
import OrganizationStore from '@/store/organization_store';
import ActivityStore from '@/store/activity_store';
import TrackStore from '@/store/track_store';
import IconStore from '@/store/icon_store';
import CommonStore from '@/store/common_store';
import ApplicationStore from '@/store/application_store';
import commonDao from '@/dao/common_dao';
import InstructorStore from "@/store/instructor_store";
import MemberStore from '@/store/member_store';
import RegistrationStore from '@/store/registration_store';
import TravelStore from '@/store/travel_store';
import NotificationStore from "@/store/notification_store";

Vue.use(Vuex);

class AppInfo {
    constructor(meta) {
        this.environment = meta.environment;
        this.hostname = meta.hostname;
        this.version = meta.version;
        this.branch = meta.branch || '';
        this.database = meta.database;
    }

    get isProd() {
        return 'prod' === this.environment;
    }
}

export default new Vuex.Store({
    state: {
        breadcrumbs: [],
        loadingRequests: 0,
        appInfo: new AppInfo({})
    },

    getters: {
        getBreadcrumbs(state) {
            return state.breadcrumbs;
        },

        loadingRequests(state) {
            return state.loadingRequests;
        },

        appInfo(state) {
            return state.appInfo;
        }
    },

    mutations: {

        setLoadingRequest(state, modifier) {
            state.loadingRequests += modifier;
            state.loadingRequests = Math.max(state.loadingRequests, 0);
        },

        setAppInfo(state, meta) {
            state.appInfo = new AppInfo(meta);
        },

        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },

        addBreadcrumb(state, breadcrumb) {

            const idx = _.findIndex(state.breadcrumbs, (bc) => bc.equals(breadcrumb));

            if (idx >= 0) {
                state.breadcrumbs.splice(idx, 1);
            }

            state.breadcrumbs.push(breadcrumb);
        }
    },

    actions: {
        addLoadingRequest: ({commit}) => {
            commit('setLoadingRequest', 1);
        },

        removeLoadingRequest: ({commit}) => {
            commit('setLoadingRequest', -1);
        },

        loadAppInfo: async({commit}) => {
            const appInfo = await commonDao.getAppInfo();
            commit('setAppInfo', appInfo);
        }
    },

    modules: {
        userSession: UserSessionStore,
        users: UserStore,
        trainees: TraineeStore,
        mentors: MentorStore,
        organizations: OrganizationStore,
        activities: ActivityStore,
        tracks: TrackStore,
        common: CommonStore,
        icons: IconStore,
        applications: ApplicationStore,
        member: MemberStore,
        registration: RegistrationStore,
        instructor: InstructorStore,
        travel: TravelStore,
        notification: NotificationStore
    }
});
